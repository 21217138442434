// React
import React, { useState, useMemo } from 'react'

// Components
import { H6Text, Subtitle } from './shared/Typography'

// Material UI
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

// Web3
import PromotedPoolArtifact from '../abi/promoted-pool.json'
import { OnboardConsumer } from '../contexts/OnboardContext'
import { NotificationConsumer } from '../contexts/NotificationContext'

// i18n
import { useTranslation } from 'react-i18next'

// Styles
import styles from './shared/styles'
import { makeStyles } from '@material-ui/core'
import { isValidAddress } from '../helpers'

const useStyles = makeStyles(styles)

const Redeem = ({ web3, notify, address }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const initialTokenId = getParameterByName('token')
  function getParameterByName(name, url) {
    if (!url) url = window.location.href
    name = name.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  const [poolAddress, setPoolAddress] = useState('')
  const [tokenId, setTokenId] = useState(initialTokenId)
  const [open, setOpen] = useState(false)

  const handleTokenIdChange = e => {
    setTokenId(e.target.value)
  }

  const handleAddressChange = e => {
    setPoolAddress(e.target.value)
  }

  const handleSubmit = async () => {
    if (!web3) {
      handleOpen()
      return
    }
    let contract = new web3.eth.Contract(
      PromotedPoolArtifact,
      '0x15b4843d34ce5eea62b3993a6fb761fd04aa9f45',
    )

    const txOptions = {
      from: (await web3.eth.getAccounts())[0],
      gas: 350000, // TODO: Estimate
      gasPrice: await web3.eth.getGasPrice(),
    }

    let response = contract.methods
      .proposePromotedPool(tokenId, poolAddress)
      .send(txOptions)
      .on('transactionHash', hash => {
        notify.hash(hash)
      })
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const validPoolAddress = useMemo(() => {
    return isValidAddress(poolAddress)
  }, [poolAddress])

  const validForm = useMemo(() => {
    if (!address) return false
    return tokenId !== '' && validPoolAddress
  }, [address, validPoolAddress, tokenId])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Connect Wallet'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please connect using the wallet that owns your promoted pool token
              before submitting your pool address.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <ConfirmationNumberIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Redeem Promoted Pool Token
        </Typography>
        {!address && <Subtitle>Connect your Web3 wallet to continue.</Subtitle>}
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="token"
            label="Token ID"
            name="token"
            defaultValue={tokenId}
            value={tokenId}
            onChange={handleTokenIdChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="poolAddress"
            label="Pool Address"
            id="poolAddress"
            value={poolAddress}
            onChange={handleAddressChange}
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!validForm}
          >
            Redeem
          </Button>
        </form>
      </div>
    </Container>
  )
}

const RedeemWrapper = props => (
  <OnboardConsumer>
    {({ web3, address }) => (
      <NotificationConsumer>
        {({ notify }) => (
          <Redeem {...props} web3={web3} notify={notify} address={address} />
        )}
      </NotificationConsumer>
    )}
  </OnboardConsumer>
)

export default RedeemWrapper
