import React, { Component } from 'react'
// import { render } from 'react-dom';

// Highcharts
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'

import { getTradesForExchange } from '../services/blocklytics'

// Styles
import { SELL_COLOR, BUY_COLOR, SWAP_COLOR } from '../pages/shared/styles'
import { getExchangeTradeData } from '../helpers'

HighchartsMore(Highcharts)

// NOTE: Refactor to FC
// const TradesChart = ({
//   chartOptions: propChartOptions,
//   data: propData = [],
//   isLoading: propIsLoading = true,
//   exchange,
//   fromDate,
//   toDate,
// }) => {
//   const chart = useRef(null)

//   const [chartOptions, setChartOptions] = useState(propChartOptions)
//   const [data, setData] = useState(propData || [])
//   const [isLoading, setIsLoading] = useState(propIsLoading)

//   const fetchData = useCallback((exchange, fromDate, toDate) => {
//     setIsLoading(true)
//     getTradesForExchange(exchange, fromDate, toDate)
//       .then(data => setData(data.results))
//       .catch(err => console.log(err))
//   }, [])

//   useEffect(() => {
//     // if (!data || data.length === 0)
//     fetchData(exchange, fromDate, toDate)
//   }, [fetchData, exchange, fromDate, toDate])

//   return (
//     <div>
//       <HighchartsReact
//         highcharts={Highcharts}
//         options={chartOptions}
//         ref={chart}
//       />
//     </div>
//   )
// }

class TradesChart extends Component {
  constructor(props) {
    super(props)
    this.chart = React.createRef()

    this.state = {
      chartOptions: props.chartOptions,
      data: props.data || [],
      isLoading: props.isLoading || true,
    }
  }

  plotData() {
    var { data } = this.state
    const { searchQuery, assetFilter } = this.props

    if (!data || (Array.isArray(data) && data.length === 0)) return

    const trade_data = []
    const price_data = []

    // Filter by asset
    if (assetFilter) {
      data = data.filter(
        row => row.fromToken === assetFilter || row.toToken === assetFilter,
      )
    }

    searchQuery &&
      searchQuery.forEach(searchQuery => {
        if (searchQuery.object && searchQuery.column) {
          // filter data
          data = data.filter(row => {
            if (searchQuery.object.length === 0) return true
            else if (searchQuery.object.length > 0)
              return searchQuery.object.includes(row[searchQuery.column])
            return row[searchQuery.column] === searchQuery.object
          })
        }
      })

    Array.isArray(data) &&
      data.forEach(row => {
        const {
          date,
          action,
          fromSymbol,
          fromAmount,
          toSymbol,
          toAmount,
          price,
          volume,
          trader,
        } = getExchangeTradeData(row, assetFilter)

        let color =
          action === 'SELL'
            ? SELL_COLOR
            : action === 'SWAP'
            ? SWAP_COLOR
            : BUY_COLOR
        trade_data.push({
          x: date.valueOf(),
          y: price,
          z: volume,
          color,
          id: row.id,
          action,
          fromAmount,
          fromSymbol,
          toAmount,
          toSymbol,
          trader,
        })

        price_data.push({
          x: row.id,
          y: row.price_after_trade,
        })
      })

    var series = []

    series.push(
      // 					{
      // 						name: 'Price',
      // 						data: price_data,
      // 						type: 'line',
      // 						id: 'price',
      // 						linkedTo: 'trades',
      // 						step: 'right',
      // 						lineWidth: 1,
      // 						color: 'rgba(0, 0, 0, 0.87)',
      // 						marker: {
      // 							enabled: false,
      // 						},
      // 					},
      {
        name: 'Trades',
        data: trade_data,
        type: 'bubble',
        id: 'trades',
        linkedTo: 'price',
        color: 'rgba(0, 0, 0, 0.12)',
        sizeBy: 'area',
        minSize: 5,
        maxSize: 70,
        marker: {
          lineWidth: 0,
        },
      },
    )

    this.setState({
      chartOptions: {
        series,
      },
      isLoading: false,
    })
  }

  fetchData() {
    const { exchange, fromDate, toDate } = this.props

    this.setState({ isLoading: true })
    getTradesForExchange(exchange, fromDate, toDate)
      .then(data => {
        this.setState({
          data: data.results,
        })
        this.plotData()
      })
      .catch(err => {
        console.log(err)
      })
  }

  componentDidMount() {
    const { data } = this.state

    if (data.length === 0) {
      this.fetchData()
    }
  }

  componentDidUpdate(prevProps) {
    const { exchange, fromDate, toDate, searchQuery, assetFilter } = this.props

    if (
      exchange !== prevProps.exchange ||
      fromDate !== prevProps.fromDate ||
      toDate !== prevProps.toDate
    ) {
      this.fetchData()
    }

    if (
      assetFilter !== prevProps.assetFilter ||
      JSON.stringify(searchQuery) !== JSON.stringify(prevProps.searchQuery)
    ) {
      this.plotData()
    }
  }

  render() {
    const { isLoading, chartOptions } = this.state

    // Loading logic
    if (this.chart && this.chart.current && this.chart.current.chart) {
      const chartObj = this.chart.current.chart
      if (isLoading) {
        chartObj.showLoading()
      } else {
        chartObj.hideLoading()
      }
    }

    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          ref={this.chart}
        />
      </div>
    )
  }
}

export default TradesChart
