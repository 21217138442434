export const options = [
  // Value based on return object from BQ
  // Label for human-readable format
  { value: '1inch', label: '1inch' },
  { value: 'Argent', label: 'Argent' },
  { value: 'Betoken', label: 'Betoken' },
  { value: 'bZx', label: 'bZx' },
  { value: 'DeFi Zap', label: 'DeFiZap' },
  { value: 'dex.ag', label: 'dex.ag' },
  { value: 'dex.blue', label: 'dex.blue' },
  { value: 'Gnosis Safe', label: 'Gnosis Safe' },
  { value: 'InstaDapp', label: 'InstaDapp' },
  { value: 'Kyber', label: 'Kyber' },
  { value: 'Nuo', label: 'Nuo' },
  { value: 'ParaSwap', label: 'ParaSwap' },
  { value: 'Pools', label: 'Pools' },
  { value: 'Synthetix Arb Pool', label: 'Synthetix Arb Pool' },
  { value: 'Totle', label: 'Totle' },
  { value: 'Uniswap', label: 'Uniswap' },
  { value: 'UniswapEX', label: 'UniswapEX' },
  { value: 'WETH Wrapper', label: 'WETH Wrapper' },
  { value: 'unknown contract', label: 'unknown contract' },
]
