// const baseUrl = 'http://127.0.0.1:8081'
const baseUrl = 'https://api.blocklytics.org'
const key = '?key=AIzaSyDn7LYCJuJK6fM37nEpwVTsXdQ5eok0wQk'

export const dataSourceForLiquidity = exchange => {
  const endpoint = `/pools/v1/owners/${exchange}`
  return baseUrl + endpoint + key
}

export const getPrice = symbol => {
  const endpoint = `/uniswap/v1/price/${symbol}`
  const url = baseUrl + endpoint + key
  return simpleFetch(url)
}

export const getExchange = exchange => {
  const endpoint = `/pools/v1/exchange/${exchange}`
  const url = baseUrl + endpoint + key
  return simpleFetch(url)
}

export const getExchanges = includeEmpty => {
  const endpoint = `/pools/v1/exchanges`
  const params = includeEmpty ? `&excludeEmpty=true&platform=uniswap,uniswap-v2,curve,balancer,mooniswap,sushiswap&limit=1500` : '&platform=uniswap,uniswap-v2,curve,balancer,mooniswap,sushiswap&limit=1500'
  const url = baseUrl + endpoint + key + params
  return simpleFetch(url)
}

export const getTradesForExchange = (exchange, fromDate, toDate) => {
  const endpoint = `/pools/v1/trades/${exchange}`
  const params = `&from=${fromDate}&to=${toDate}`
  const url = baseUrl + endpoint + key + params
  return simpleFetch(url)
}

export const getReturnsForExchange = (
  exchange,
  period,
  daysBack,
  options = {},
) => {
  const endpoint = `/pools/v0/returns/${exchange}`
  const params = `&period=${period}`
  const url = baseUrl + endpoint + key + params

  return simpleFetch(url, options)
}

export const getReturnsV1ForExchange = (
  exchange,
  periods,
  freq
) => {
  const endpoint = `/pools/v1/returns/${exchange}`
  const params = `&periods=${periods}&freq=${freq}`
  const url = baseUrl + endpoint + key + params

  return simpleFetch(url)
}

export const getOwnershipForAccount = address => {
  const endpoint = `/pools/v1/shares/${address}`
  const url = baseUrl + endpoint + key
  return simpleFetch(url)
}

const simpleFetch = (url, options = {}) => {
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(response => resolve(response.json()))
      .catch(err => {
        reject(err)
      })
  })
}
