import React, { useMemo } from 'react'
import Select, { components } from 'react-select'
import { LocalizedLink } from '../../../../contexts/LocaleContext'
import { Caption } from '../../Typography'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import {
  usdFormat,
  chooseAnAddressFor,
  isValidAddress,
} from '../../../../helpers'
import { useTranslation } from 'react-i18next'
import PlatformWidget from '../../PlatformWidget'

const Label = (option, location) => {
  const { search: searchParams } = location
  const token = chooseAnAddressFor(option.assets)

  return (
    <LocalizedLink
      to={`/returns/${option.exchange}${searchParams}`}
      style={{ textDecoration: 'none', color: 'rgba(0,0,0,0.87)' }}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}
        wrap="nowrap"
      >
        <Grid item>
          <PlatformWidget
            wrap
            token={token}
            name={option.poolName}
            platform={option.platform}
            assets={option.assets}
            // baseSymbol={option.baseSymbol || 'ETH'}
            // tokenSymbol={option.tokenSymbol}
            // exchange={option.exchange}
          />
        </Grid>

        <Grid item>
          <Caption lowEmphasis>{usdFormat(option.usdLiquidity, 0)}</Caption>
        </Grid>
      </Grid>
    </LocalizedLink>
  )
}

const Value = option => option.exchange

const Control = props => (
  <components.Control {...props}>
    <SearchIcon
      fontSize="large"
      style={{ marginLeft: 12, color: 'rgba(0, 0, 0, 0.55)' }}
    />
    {props.children}
  </components.Control>
)

const Placeholder = props => (
  <components.Placeholder {...props}>
    <Typography>{props.children}</Typography>
  </components.Placeholder>
)

const LoadingMessage = props => {
  const { t } = useTranslation()

  return (
    <components.LoadingMessage {...props}>
      <Typography>{t('common.loading')}...</Typography>
    </components.LoadingMessage>
  )
}

const MenuList = props => {
  // Limit to five results for performance reasons

  var menuOptions = props.children
  if (Array.isArray(menuOptions)) {
    menuOptions = menuOptions.sort(function(a, b) {
      return b.props.data.ethLiquidity - a.props.data.ethLiquidity
    })

    menuOptions = menuOptions.slice(0, 5)
  }

  return <components.MenuList {...props}>{menuOptions}</components.MenuList>
}

const Option = props => (
  <components.Option {...props}>{props.children}</components.Option>
)

//Add your search logic here.
const customFilter = ({ data }, searchQuery) => {
  if (!searchQuery) return true

  const isAddress = isValidAddress(searchQuery)
  return isAddress
    ? data.addresses.includes(searchQuery.toLowerCase())
    : data.searchTokens.includes(searchQuery.toLowerCase())
}

const SearchBar = ({
  exchangeData,
  exchangeDataIsLoading,
  location,
  className,
}) => {
  const { t } = useTranslation()

  const selectOptions = useMemo(() => {
    if (!exchangeData) return []
    return exchangeData.map(exchange => {
      const { platform, poolName, assets, exchange: address } = exchange
      const assetNames = assets
        .map(asset => `${asset.name} ${asset.symbol}`)
        .join(' ')
      const assetAddresses = assets.map(asset => asset.address).join('|')

      return {
        ...exchange,
        searchTokens: `${platform} ${poolName}|${assetNames}`.toLowerCase(),
        addresses: `${address}|${assetAddresses}`,
      }
    })
  }, [exchangeData])

  return (
    <Grid item className={className} xs={12} md>
      <Select
        components={{
          Control: Control,
          Placeholder: Placeholder,
          Option: Option,
          MenuList: MenuList,
          LoadingMessage: LoadingMessage,
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 2,
          colors: {
            ...theme.colors,
            primary25: '#f9f9f9', // Background for option hover
            primary50: '#e9e9e9', // Background for option focus (click)
            primary: '#e9e9e9', // Container outline and background for currently selected option
          },
        })}
        styles={{
          indicatorsContainer: () => ({ display: 'none' }),
          control: (base, state) => ({
            ...base,
            '&:hover': {
              // hover effects manually matched to AccountWidget
              border: '1px solid #e9e9e9',
              boxShadow:
                '0px 2px 1px -1px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.07),0px 1px 3px 0px rgba(0,0,0,0.06)',
            },
            '&:focus': {
              // focus effects match hover effects
              border: '1px solid #e9e9e9',
              boxShadow:
                '0px 2px 1px -1px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.07),0px 1px 3px 0px rgba(0,0,0,0.06)',
            },
            '&:focus-within': {
              // focus-within effects match hover effects
              border: '1px solid #e9e9e9',
              boxShadow:
                '0px 2px 1px -1px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.07),0px 1px 3px 0px rgba(0,0,0,0.06)',
            },
            backgroundColor: '#F9F9F9',
            border: '1px solid #F9F9F9',
            borderRadius: 4,
            overflow: 'hidden',
            cursor: 'pointer',
            height: 60,
            boxShadow: 'none',
            transition: 'all .3s',
          }),
          container: (base, state) => ({
            ...base,
            '&:hover': {
              //       	  	border: '2px solid #e91e63',
            },
            //       	  border: '2px solid white',
          }),
          valueContainer: (base, state) => ({
            ...base,
            //       	  height: 42,
          }),
        }}
        isClearable={false}
        isLoading={exchangeDataIsLoading}
        filterOption={customFilter}
        maxMenuHeight={310}
        placeholder={`${t('common.search_all_pools')}...`}
        options={selectOptions}
        getOptionLabel={option => Label(option, location)}
        getOptionValue={option => Value(option)}
        controlShouldRenderValue={false}
      />
    </Grid>
  )
}

export default SearchBar
