import React, { useState, useCallback } from 'react'

// Material UI
import Tooltip from '@material-ui/core/Tooltip'

const copyContentToClipboard = content => {
  const el = document.createElement('textarea')
  el.value = content
  el.setAttribute('readonly', '')
  el.style = { display: 'none' }
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

const CopyToClipboard = ({ children, TooltipProps }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const onCopy = useCallback(content => {
    copyContentToClipboard(content)
    setShowTooltip(true)
  }, [])

  const handleOnTooltipClose = useCallback(() => {
    setShowTooltip(false)
  }, [])

  return (
    <Tooltip
      open={showTooltip}
      title={'Copied to clipboard!'}
      leaveDelay={1500}
      onClose={handleOnTooltipClose}
      placement="right"
      {...(TooltipProps || {})}
    >
      {children({ copy: onCopy })}
    </Tooltip>
  )
}

export default CopyToClipboard
