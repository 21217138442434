import React from 'react'

// Components
import { SectionHeader, Subtitle } from '../../shared/Typography'

// Material UI
import Grid from '@material-ui/core/Grid'

// i18n
import { useTranslation } from 'react-i18next'

// Styles
import styles from '../../shared/styles'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(styles)

const ThreePanelPrimer = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Grid item xs={12} sm={4} className={classes.subsequentSectionPadding}>
        <SectionHeader>{t('exchanges.earn_fees')}</SectionHeader>
        <Subtitle paragraph>{t('exchanges.earn_fees_desc')}</Subtitle>
        <Subtitle paragraph>
          <a
            href="https://medium.com/@pintail/understanding-uniswap-returns-cc593f3499ef"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkStyle}
          >
            {t('exchanges.earn_fees_link')} ↗︎
          </a>
        </Subtitle>
      </Grid>

      <Grid item xs={12} sm={4} className={classes.subsequentSectionPadding}>
        <SectionHeader>{t('exchanges.own_the_pool')}</SectionHeader>
        <Subtitle paragraph>{t('exchanges.own_the_pool_desc')}</Subtitle>
        <Subtitle paragraph>
          <a
            href="https://settle.finance/blog/uniswap-adding-liquidity/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkStyle}
          >
            {t('exchanges.own_the_pool_link')} ↗︎
          </a>
        </Subtitle>
      </Grid>

      <Grid item xs={12} sm={4} className={classes.subsequentSectionPadding}>
        <SectionHeader>{t('exchanges.stay_liquid')}</SectionHeader>
        <Subtitle paragraph>{t('exchanges.stay_liquid_desc')}</Subtitle>
        <Subtitle paragraph>
          <a
            href="https://uniswap.exchange/remove-liquidity"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkStyle}
          >
            {t('exchanges.stay_liquid_link')} ↗︎
          </a>
        </Subtitle>
      </Grid>
    </React.Fragment>
  )
}

export default ThreePanelPrimer
