import React from 'react'

import { Body2Text, ActionCardTitle } from './Typography'

// Material UI
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'

// Colors
import pink from '@material-ui/core/colors/pink'

// Styles
import { makeStyles } from '@material-ui/styles'

const useAssetCardStyles = makeStyles(theme => ({
  assetCardBase: {
    display: 'inline-block',
    position: 'relative',
    width: 230,
    height: 230,
    overflow: 'hidden',
    marginTop: 4,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    padding: '8px 8px 16px 8px',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: theme.shadows[8],
    },
    transition: 'all .15s',
  },
  avatarForAdd: {
    position: 'absolute',
    left: 7,
    bottom: 7,
    height: 88,
    width: 88,
    backgroundColor: 'rgba(255, 255, 255, 0.30)',
    color: 'rgba(255, 255, 255, 0.75)',
  },
  betaTag: {
    background: pink['500'],
    color: 'rgba(255, 255, 255, 0.87)',
    border: '1px solid rgba(255, 255, 255, 0.87)',
    borderRadius: 2,
    height: 20,
    width: 36,
    marginTop: -28,
    marginLeft: -10,
    fontWeight: 400,
    letterSpacing: '0.01071em',
  },
}))

const ActionCard = ({
  actionName,
  actionSubtitle,
  background,
  IconComponent,
  showBetaTag,
  ...props
}) => {
  const classes = useAssetCardStyles()

  return (
    <Paper
      elevation={4}
      className={classes.assetCardBase}
      style={{
        backgroundImage: background,
        cursor: 'pointer',
        textDecoration: 'none',
      }}
      {...props}
    >
      <ActionCardTitle>
        {actionName}
        {showBetaTag && <Chip label="beta" className={classes.betaTag} />}
      </ActionCardTitle>
      <Body2Text>{actionSubtitle}</Body2Text>
      <Avatar className={classes.avatarForAdd}>{IconComponent}</Avatar>
    </Paper>
  )
}

export default ActionCard
