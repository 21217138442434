import React, { useState } from 'react'

// Components
import { Title, Subtitle } from '../../shared/Typography'

// Material UI
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'

// i18n
import { useTranslation } from 'react-i18next'

// Styles
import styles from '../../shared/styles'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(styles)

const SubscribeBanner = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [subscribed, setSubscribed] = useState(false)

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.subsequentSectionPadding}>
        <Paper className={classes.paperInGrid}>
          <Title>{t('exchanges.subscribe')}</Title>
          <Subtitle gutterBottom>{t('exchanges.subscribe_desc')}</Subtitle>

          <form
            action="https://blocklytics.us19.list-manage.com/subscribe/post?u=e88f92fc087c0abfb94d292eb&amp;id=a12a599126"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            onSubmit={e => {
              setSubscribed(true)
            }}
          >
            <div
              style={{ position: 'absolute', left: -5000 }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_e88f92fc087c0abfb94d292eb_a12a599126"
                tabIndex="-1"
              />
            </div>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              style={subscribed ? { display: 'none' } : null}
            >
              <Grid item xs={12} sm={5} style={{ margin: '10px 10px 10px 0' }}>
                <TextField
                  name="EMAIL"
                  id="mce-EMAIL"
                  margin="dense"
                  label="Email Address"
                  type="email"
                  fullWidth
                  required
                />
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  id="mc-embedded-subscribe"
                  style={{ height: 45 }}
                >
                  {t('exchanges.subscribe_now')}
                </Button>
              </Grid>
            </Grid>

            {subscribed && (
              <Subtitle gutterBottom>
                {t('exchanges.subscribed_message')}
              </Subtitle>
            )}
          </form>
        </Paper>
      </Grid>
    </React.Fragment>
  )
}

export default SubscribeBanner
