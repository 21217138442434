import React from 'react'
import { Route, Redirect } from 'react-router-dom'

// Pages
import Account from './pages/Account'
import Exchanges from './pages/Exchanges'
import Trades from './pages/Trades'
import MoreInfo from './pages/MoreInfo'
import Redeem from './pages/Redeem'

// Components
import ScrollToTop from './pages/shared/ScrollTop'
import {
  LocaleConsumer,
  LocalizedSwitch,
  DefaultLocaleRedirect,
} from './contexts/LocaleContext'

const SubRoutes = props => (
  <LocaleConsumer>
    {({ locale, setLocale }) => (
      <DefaultLocaleRedirect
        appLocale={locale}
        matchedLocale={props.match.params.lang}
        location={props.location}
      >
        <LocalizedSwitch
          locale={props.match.params.lang}
          setLocale={setLocale}
          fallbackRoutes={
            <React.Fragment>
              <Route
                exact
                path="/:id"
                render={({ match }) => {
                  return <RedirectForOldUrls match={match} />
                }}
                component={RedirectForOldUrls}
              />
              <Route component={Exchanges} />
            </React.Fragment>
          }
        >
          <Route exact path="/" component={Exchanges} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/shares/:id" component={SharesForId} />
          <Route exact path="/returns/:id" component={ReturnsForId} />
          <Route exact path="/trades/:id" component={TradesForId} />
          <Route exact path="/liquidity/:id" component={LiquidityForId} />
          <Route exact path="/info/:id" component={InfoForId} />
          <Route exact path="/more-info" component={MoreInfo} />
          <Route exact path="/redeem" component={Redeem} />
        </LocalizedSwitch>
      </DefaultLocaleRedirect>
    )}
  </LocaleConsumer>
)

const IndexRoute = () => (
  <Route path="/:lang([a-z]{2})?" component={SubRoutes} />
)

export default props => (
  <ScrollToTop>
    <IndexRoute />
  </ScrollToTop>
)

const RedirectForOldUrls = ({ match }) => (
  <Redirect to={`/returns/${match.params.id}`} />
)

const SharesForId = ({ match }) => (
  <Trades exchange={match.params.id} navTabValue={0} />
)
const ReturnsForId = ({ match }) => (
  <Trades exchange={match.params.id} navTabValue={1} />
)
const TradesForId = ({ match }) => (
  <Trades exchange={match.params.id} navTabValue={2} />
)
const LiquidityForId = ({ match }) => (
  <Trades exchange={match.params.id} navTabValue={3} />
)
const InfoForId = ({ match }) => (
  <Trades exchange={match.params.id} navTabValue={4} />
)
