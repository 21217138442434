import { Component } from 'react'
import { withRouter } from 'react-router-dom'

const detailRoutes = ['/info/', '/returns/', '/trades/', '/liquidity/']

const isDetailRoute = path => {
  return detailRoutes.find(route => path.includes(route))
}

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const {
      location: { pathname: newPathname },
    } = this.props
    const {
      location: { pathname: oldPathname },
    } = prevProps

    if (newPathname !== oldPathname) {
      if (isDetailRoute(newPathname) && isDetailRoute(oldPathname)) return

      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
