import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react'

import { getPrice, getExchanges, getExchange } from '../services/blocklytics'

const ExchangePriceContext = createContext()

export const ExchangePriceProvider = ({ children }) => {
  const [exchange, setExchange] = useState(null)
  const [exchangeData, setExchangeData] = useState(null)
  const [singleExchangeData, setSingleExchangeData] = useState(null)
  const [includeEmpty, setIncludeEmpty] = useState(false)
  const [exchangeDataLoading, setExchangeDataLoading] = useState(true)
  const [ethPrice, setEthPrice] = useState(null)
  const [ethPriceLoading, setEthPriceLoading] = useState(true)

  // Fetch exchangeData
  const fetchExchangesData = () => {
    getExchanges(includeEmpty).then(data => {
      if (data !== undefined) {
        setExchangeData(data.results)
        setExchangeDataLoading(false)
      }
    })
  }

  const fetchSingleExchangeData = (exchange) => {
    getExchange(exchange).then(data => {
      setSingleExchangeData(data)
    })
  }

  // Fetch ethPrice
  const fetchEthPrice = () => {
    getPrice('ETH').then(data => {
      if (data !== undefined) {
        setEthPrice(data)
        setEthPriceLoading(false)
      }
    })
  }

  const fetchData = useCallback(() => {
    fetchEthPrice()
    fetchExchangesData()
  }, [includeEmpty])

  const fetchSingleExchange = useCallback(() => {
    if (exchange) {
      fetchSingleExchangeData(exchange)
    }
  }, [exchange])

  const timerId = useRef()

  const doPolling = useCallback(() => {
    timerId.current = setTimeout(() => {
      // fetchData();
      fetchEthPrice()
      doPolling()
    }, 60000)
  }, [
    /* fetchData */
  ])

  const stopPolling = () => {
    clearTimeout(timerId.current)
    timerId.current = null
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    doPolling()

    return stopPolling
  }, [doPolling])

  useEffect(() => {
    fetchSingleExchange()
  }, [exchange])

  const getTokenDetails = exchange => {
    if (!exchange) return null;

    if (singleExchangeData && singleExchangeData.exchange === exchange) {
      return singleExchangeData;
    }

    let filteredExchange = exchangeData && exchangeData.find(
      row =>
        String(row.exchange).toLowerCase() === String(exchange).toLowerCase(),
    )

    return filteredExchange
  }

  return (
    <ExchangePriceContext.Provider
      value={{
        setExchange,
        exchangeData,
        exchangeDataLoading,
        ethPrice,
        ethPriceLoading,
        includeEmptyPools: includeEmpty,
        setIncludeEmptyPools: setIncludeEmpty,
        getTokenDetails,
      }}
    >
      {children}
    </ExchangePriceContext.Provider>
  )
}

export const ExchangePriceConsumer = ExchangePriceContext.Consumer
