// React
import React from 'react'

// Icons
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'

export const MESSAGES = {
  '0x255e60c9d597dcaa66006a904ed36424f7b26286': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: 'https://twitter.com/UniswapExchange/status/1072286773554876416',
  },
  '0x291c69fdaebd3cbe953843da243f8605a766a268': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://medium.com/@telcoin/introducing-the-tel-x-uniswap-liquidity-rewards-program-8265bc1b1529'
  },
  '0x02cde6be34a59ff01aa532d56956a3c339c26322': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://lgo.group/blog/announcing-the-lgo-liquidity-reward-program/?utm_source=pools.fyi&utm_medium=banner'
  },
  '0x0b7ee2f06837e16e6a5239a75a5ce83cdcb43fd6': {
    tags: ['note'],
    icon: <InfoIcon />,
    href: 'https://twitter.com/jgarzik/status/1295136068422062083',
  },
  '0xb0877d36eda750e4f43aefd4c314150afd4c4506': {
    tags: ['note'],
    icon: <WarningIcon />
  },
  '0x023f9571cf19689d5b1f7b5108b6e34f9d15eed6': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: 'https://twitter.com/rDAI_dao/status/1276541124841279490'
  },
  '0x508a6bd36d65ede7e665c39b610d72f279110911': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: 'https://twitter.com/rDAI_dao/status/1276541124841279490'
  },
  '0x4ef5bf092a6b4b4e1e4712a52cec99dd32a50da8': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: 'https://twitter.com/BalancerLabs/status/1271807613358006272'
  },
  '0xae51c1381ae5be0d55231b2c24106fcffd9791a6': {
    tags: ['note'],
    icon: <WarningIcon />,
  },
  '0xc5be99a02c6857f9eac67bbce58df5572498f40c': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://www.ampleforth.org/dapps/?utm_source=pools.fyi&utm_medium=banner&utm_campaign=06222020_2week',
  },
  '0xfc74ecdee4e9fff2f2ef4cdf4f1c777b2ef0e905': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://www.suku.world/liquidity?utm_source=pools.fyi',
  },
  '0xfa19de406e8f5b9100e4dd5cad8a503a6d686efe': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://aragon.org/blog/liquidity-rewards?utm_source=pools.fyi',
  },
  '0x7fc77b5c7614e1533320ea6ddc2eb61fa00a9714': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://blog.synthetix.io/btc-yield-farming-pool/?utm_source=pools.fyi',
  },
  '0xa5407eae9ba41422680e2e00537571bcc53efbfd': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://blog.synthetix.io/new-curve-pool-launch/?utm_source=pools.fyi',
  },
  '0x72cd8f4504941bf8c5a21d1fd83a96499fd71d2c': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://medium.com/mstable/introducing-meta-mta-mstables-protocol-token-6e1032291ccf',
  },
  '0xe036cce08cf4e23d33bc6b18e53caf532afa8513': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://medium.com/mstable/mstables-second-mta-reward-pool-is-now-live-af22ad151b47',
  },
  '0x634e27269a029edb2b70b5ef43273f5eed9ebcc2': {
    tags: [],
    icon: <InfoIcon />,
    href:
      'https://medium.com/jarvis-network/staking-jrt-to-increase-uniswap-liquidity-f1fc931a2cff',
  },
  '0x2b6a25f7c54f43c71c743e627f5663232586c39f': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://medium.com/jarvis-network/staking-jrt-to-increase-uniswap-liquidity-f1fc931a2cff',
  },
  '0xe4a356afd925f73f3dd22acbeb2c0c87a05e895d': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://medium.com/jarvis-network/staking-jrt-to-increase-uniswap-liquidity-f1fc931a2cff',
  },
  '0xcd0033b0a30bc0686dcba72d62d4e0d44790a960': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://medium.com/jarvis-network/staking-jrt-to-increase-uniswap-liquidity-f1fc931a2cff',
  },
  '0x66540a3fcd929774a8dab59d56fe7a2d3538450f': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://medium.com/jarvis-network/staking-jrt-and-usdb-on-bancor-f66d90f5ae0e',
  },
  '0x49e4cb1bebc5a060505d4571ec50bd2b65c3ed11': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://medium.com/jarvis-network/liquidity-program-with-balancer-db23aa0ce10',
  },
  '0x68198e4ed6975204b3467dc217166d9ff1cbb57a': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://medium.com/jarvis-network/liquidity-program-with-balancer-db23aa0ce10',
  },
  '0x01d7e6a79f6e6dc6f0884743078f76ac1239520a': {
    tags: [],
    icon: <InfoIcon />,
    href: 'https://medium.com/jarvis-network/liquidity-program-with-balancer-db23aa0ce10',
  },
  '0x7cfab87aac0899c093235b342ac0e5b1acf159eb': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: null,
  },
  '0x81eed7f1ecbd7fa9978fcc7584296fb0c215dc5c': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: null,
  },
  '0x042dbbdc27f75d277c3d99efe327db21bc4fde75': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: null,
  },
  '0x2f5b009d42917452f4f057b0998dfad4d84c7662': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: null,
  },
  '0x7f913e9deef8efe8d09a2e67d18ced9be4ad1dc7': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: null,
  },
  '0x6c3942b383bc3d0efd3f36efa1cbe7c8e12c8a2b': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: null,
  },
  '0x45a2fdfed7f7a2c791fb1bdf6075b83fad821dde': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: null,
  },
  '0x34e89740adf97c3a9d3f63cc2ce4a914382c230b': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: null,
  },
  '0xb791c10824296881f91bdbc16367bbd9743fd99b': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: null,
  },
  '0x03b054ef0cd799848f157e3033fef4c4cc0e39b5': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: null,
  },
  '0x3f3e2cf087b4a818a7c610eb8ef332d894736d7d': {
    tags: ['note'],
    icon: <WarningIcon />,
    href: null,
  },
  '0x474f8f008f07cd42200bc6dabc1db2206827ee6e': {
    tags: ['note'],
    icon: <InfoIcon />,
    href: 'https://realt.co/',
  },
  '0x7671f9c37bfcee829f54d4bdd2a226930c91df7b': {
    tags: ['note'],
    icon: <InfoIcon />,
    href: 'https://realt.co/',
  },
  '0xc215ebfe68c15fcafcb848105ef5f5b1158313cb': {
    tags: ['note'],
    icon: <InfoIcon />,
    href: 'https://realt.co/',
  },
}
