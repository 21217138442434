import React from 'react'

// Material UI
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'

// Components
import { Body2Text } from './Typography'
import CopyToClipboard from './CopyToClipboard'
import EthereumAvatar from './EthereumAvatar'

// Helpers
import { slicedAddress } from '../../helpers'

// Styles
import { makeStyles } from '@material-ui/core'
import styles from './styles'

const useStyles = makeStyles(styles)

const TraderCell = ({ address, subtitle, showCopy }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2} wrap="nowrap">
      <Grid item>
        <EthereumAvatar address={address} />
      </Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <Grid container direction="row" alignItems="center">
            <Body2Text>{slicedAddress(String(address))}</Body2Text>
            {showCopy && (
              <CopyToClipboard>
                {({ copy }) => (
                  <IconButton
                    className={`${classes.filterButton}`}
                    onClick={e => copy(address)}
                    style={{ width: 25, height: 25 }}
                  >
                    <FileCopyOutlinedIcon fontSize="inherit" />
                  </IconButton>
                )}
              </CopyToClipboard>
            )}
            {/* <Button
            className={`${classes.filterButton} show-on-row-hover`}
            onClick={e => handleTraderFilterChange(row.value)}
          >
            <FilterListIcon fontSize="inherit" /> Filter
          </Button> */}
          </Grid>
          {subtitle && (
            <Body2Text lowEmphasis style={{ marginTop: -4 }}>
              {subtitle}
            </Body2Text>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TraderCell
