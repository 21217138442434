import React from 'react'

// Components
import Table, { Header, CellText } from '../../../shared/Table'
import PoolInfoWidget from '../../../shared/PoolInfoWidget'
import { SectionHeader, Subtitle, Caption } from '../../../shared/Typography'

// Material UI
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

// Helpers
import { format, usdFormat } from '../../../../helpers'
import { isNil } from 'ramda'

// i18n
import { useTranslation } from 'react-i18next'

// Styles
import { makeStyles } from '@material-ui/styles'
import styles from '../../../shared/styles'

const useStyles = makeStyles(styles)

const PoolOwnership = ({
  exchangeData,
  returnTypeTabValue,
  ownershipDataSources,
  ethPrice,
  handleReturnTypeTabChange,
  getStringForRowValue,
  // addDefaultSrc,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Grid
        className={classes.subsequentSectionPadding}
        container
        direction="row"
        alignItems="flex-end"
        justify="space-between"
        style={{ marginBottom: 18 }}
      >
        <Grid item>
          <SectionHeader>{t('labels.pool_profit_loss')}</SectionHeader>
          <Subtitle gutterBottom>{t('common.refresh_rate_15m')}</Subtitle>
        </Grid>
        <Grid item>
          <Caption lowEmphasis>{t('labels.return_type')}</Caption>
          <Tabs
            value={returnTypeTabValue}
            onChange={handleReturnTypeTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {/* <Tab label={t('labels.pool_growth')} value={0} disableRipple /> */}
            <Tab label={t('labels.eth_returns')} value={1} disableRipple />
            <Tab label={t('labels.usd_returns')} value={2} disableRipple />
          </Tabs>
        </Grid>
      </Grid>

      <Table
        dataSource={ownershipDataSources}
        columns={[
          {
            Header: <Header>{t('labels.pool')}</Header>,
            accessor: 'exchange',
            minWidth: 200,
            sortable: false,
            Cell: row => {
              const exchange = row.value
              const filteredExchange =
                exchangeData &&
                exchangeData.find(row => row.exchange === exchange)

              return (
                <PoolInfoWidget
                  name={filteredExchange && filteredExchange.poolName}
                  platform={filteredExchange && filteredExchange.platform}
                  assets={filteredExchange && filteredExchange.assets}
                />
              )
            },
          },
          {
            Header: <Header>{t('labels.ownership_shares')}</Header>,
            accessor: 'ownership_tokens',
            Cell: row => {
              return (
                <CellText align="right" style={{ lineHeight: '40px' }}>
                  {`${format(row.value)}`}
                </CellText>
              )
            },
          },
          {
            Header: <Header>{t('labels.value')}</Header>,
            accessor: 'eth_balance',
            Cell: row => {
              const ethBalance = row.value
              const tokenBalance = row.original.tokens_balance
              const exchange = row.original.exchange
              const filteredExchange =
                exchangeData &&
                exchangeData.filter(row => row.exchange === exchange)
              const tokenSymbol =
                filteredExchange &&
                filteredExchange[0] &&
                filteredExchange[0].tokenSymbol

              const rowValue = getStringForRowValue(
                ethBalance,
                tokenBalance,
                tokenSymbol,
              )

              return (
                <CellText align="right" style={{ lineHeight: '40px' }}>
                  {rowValue}
                </CellText>
              )
            },
          },
          {
            Header: <Header>{t('labels.cost_basis')}</Header>,
            accessor: 'purchased_usd_value',
            Cell: row => {
              const usdValue = Math.abs(row.value)
              const ethValue = Math.abs(row.original.purchased_eth_value)
              const ethBalance = Math.abs(row.original.purchased_eth)
              const tokenBalance = Math.abs(row.original.purchased_tokens)
              const exchange = row.original.exchange
              const filteredExchange =
                exchangeData &&
                exchangeData.filter(row => row.exchange === exchange)
              const tokenSymbol =
                filteredExchange &&
                filteredExchange[0] &&
                filteredExchange[0].tokenSymbol

              const rowValue = () => {
                switch (returnTypeTabValue) {
                  case 0: // POOL
                    return getStringForRowValue(
                      ethBalance,
                      tokenBalance,
                      tokenSymbol,
                    )
                  case 1: // ETH
                    return `${format(ethValue, 3)} ETH`
                  case 2: // USD
                    return `${usdFormat(usdValue)}`
                  default:
                    break
                }
              }

              return (
                <CellText align="right" style={{ lineHeight: '40px' }}>
                  {rowValue()}
                </CellText>
              )
            },
          },
          {
            Header: <Header>{t('labels.redemptions')}</Header>,
            accessor: 'redeemed_usd_value',
            Cell: row => {
              const usdValue = row.value
              const ethValue = row.original.redeemed_eth_value
              const ethBalance = row.original.redeemed_eth
              const tokenBalance = row.original.redeemed_tokens
              const exchange = row.original.exchange
              const filteredExchange =
                exchangeData &&
                exchangeData.filter(row => row.exchange === exchange)
              const tokenSymbol =
                filteredExchange &&
                filteredExchange[0] &&
                filteredExchange[0].tokenSymbol

              const rowValue = () => {
                switch (returnTypeTabValue) {
                  case 0: // POOL
                    return getStringForRowValue(
                      ethBalance,
                      tokenBalance,
                      tokenSymbol,
                    )
                  case 1: // ETH
                    return `${format(ethValue, 3)} ETH`
                  case 2: // USD
                    return `${usdFormat(usdValue)}`
                  default:
                    break
                }
              }

              return (
                <CellText align="right" style={{ lineHeight: '40px' }}>
                  {rowValue()}
                </CellText>
              )
            },
          },
          {
            Header: <Header>{t('labels.net_value')}</Header>,
            accessor: 'net_eth_value',
            Cell: row => {
              const ethBasis = 2 * row.original.eth_balance
              const ethBalance = row.original.eth_balance
              const tokenBalance = row.original.tokens_balance

              const rowValue = () => {
                switch (returnTypeTabValue) {
                  case 0: // POOL
                    const exchange = row.original.exchange
                    const filteredExchange =
                      exchangeData &&
                      exchangeData.filter(row => row.exchange === exchange)
                    const tokenSymbol =
                      filteredExchange &&
                      filteredExchange[0] &&
                      filteredExchange[0].tokenSymbol

                    return !isNil(tokenBalance && ethBalance) ? (
                      `${format(tokenBalance)} ${tokenSymbol} • ${format(
                        ethBalance,
                      )} ETH`
                    ) : (
                      <Skeleton
                        height={16}
                        width={100}
                        style={{ margin: 0, display: 'inline-block' }}
                      />
                    )
                  case 1: // ETH
                    const ethPurchased = row.original.purchased_eth_value || 0
                    const ethRedeemed = row.original.redeemed_eth_value || 0
                    return `${format(
                      ethBasis + ethPurchased + ethRedeemed,
                      3,
                    )} ETH`
                  case 2: // USD
                    const usdPurchased = row.original.purchased_usd_value
                    const usdRedeemed = row.original.redeemed_usd_value
                    return !isNil(
                      ethPrice && ethBasis && usdPurchased && usdRedeemed,
                    ) ? (
                      `${usdFormat(
                        ethPrice * ethBasis + usdPurchased + usdRedeemed,
                      )}`
                    ) : (
                      <Skeleton
                        height={16}
                        width={100}
                        style={{ margin: 0, display: 'inline-block' }}
                      />
                    )
                  default:
                    break
                }
              }

              return (
                <CellText align="right" style={{ lineHeight: '40px' }}>
                  {rowValue()}
                </CellText>
              )
            },
          },
        ]}
        defaultSorted={[{ id: 'eth_balance', desc: true }]}
      />
    </React.Fragment>
  )
}

export default PoolOwnership
