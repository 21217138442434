import React, { useState, useEffect } from 'react'

// Material UI
import Avatar from '@material-ui/core/Avatar'

// Helpers
import { colorForAddress, shortLabelForAddress } from '../../helpers'

const baseUrl = 'https://ipfs.infura.io/ipfs/'

function EthereumAvatar({ address, size, profile, border }) {
  const [profileUrl, setProfileUrl] = useState(null)

  const label = shortLabelForAddress(address)
  const backgroundColor = colorForAddress(address)
  const height = size || 40
  const width = size || 40

  useEffect(() => {
    setProfileUrl(null)
    profile &&
      profile.image &&
      profile.image[0] &&
      profile.image[0].contentUrl &&
      profile.image[0].contentUrl['/'] &&
      setProfileUrl(baseUrl + profile.image[0].contentUrl['/'])
  }, [profile])

  return (
    <Avatar
      style={{
        backgroundColor,
        height,
        width,
        ...(border && { border: '4px solid white' }),
      }}
      src={profileUrl}
    >
      {label}
    </Avatar>
  )
}

export default EthereumAvatar
