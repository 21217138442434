// React
import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Context
import { AccountConsumer } from '../../contexts/AccountContext'
import { OnboardConsumer } from '../../contexts/OnboardContext'

// Material UI
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// Style
import styles from './styles'
import { makeStyles } from '@material-ui/core/styles'

// Components
import EthereumAvatar from './EthereumAvatar'
import { slicedAddress } from '../../helpers'
import { Subtitle } from './Typography'

// Helpers
import { LocalizedLink } from '../../contexts/LocaleContext'
import { isNil } from 'ramda'

// Analytics
import { logAnalyticsEvent } from '../../services/analytics'

const useStyles = makeStyles(styles)

const AccountWidget = ({
  selectedAddress,
  profile,
  balance,
  web3,
  className,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const balanceF = useMemo(() => {
    if (isNil(balance)) return null
    return parseFloat(web3.utils.fromWei(balance, 'ether'))
  }, [balance, web3])

  const trimmedBalance = useMemo(
    () => (Math.floor(balanceF * 10000) / 10000).toFixed(4),
    [balanceF],
  )

  return (
    <LocalizedLink
      className={classes.linkContainer}
      to="/account"
      style={{ textDecoration: 'none' }}
    >
      <Grid
        className={`${classes.accountWidgetContainer} ${className}`}
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        wrap="nowrap"
        spacing={1}
      >
        <Hidden xsDown>
          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="flex-end"
              spacing={0}
            >
              <Grid item>
                <Subtitle className={classes.accountLabel} align="right">
                  {profile && profile.name
                    ? profile.name
                    : slicedAddress(selectedAddress)}
                </Subtitle>
              </Grid>
              <Grid item style={{ marginTop: -4 }}>
                <Typography className={classes.accountBalance}>
                  {!isNil(balance)
                    ? `${trimmedBalance} ETH`
                    : `${t('trade_token.getting_balance')}...`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>

        <Grid item>
          <EthereumAvatar
            address={selectedAddress}
            size={58}
            profile={profile}
            border
          />
        </Grid>

        <Grid item>
          <Typography className={classes.accountChevron}>›</Typography>
        </Grid>
      </Grid>
    </LocalizedLink>
  )
}

const ConnectButton = ({ onboard }) => {
  const { t } = useTranslation()

  return (
    <Button
      color="primary"
      variant="outlined"
      size="large"
      onClick={async e => {
        e.preventDefault()

        /* Analytics Event
         * @param {string} eventCategory - 1st level of event heirarchy
         * @param {string} eventAction - 2nd level of event heirarchy
         * @param {string} eventLabel - 3rd level of event heirarchy
         * @param {object} customDimensions - key-value pairs of custom dimensions to send with request
         * @param {bool} [nonInteractive] - optional boolean flag to determine whether this event should impact bounce rate
         * @param {string} [eventValue] - optional $ value of event
         */
        logAnalyticsEvent(
          'connect_wallet',
          'connect_step_0',
          'press_connect_button',
        )

        const walletSelected = await onboard.walletSelect()
        if (walletSelected) {
          const walletReady = await onboard.walletCheck()
          if (walletReady) {
            const walletName = onboard.getState().wallet.name

            /* Analytics Event
             * @param {string} eventCategory - 1st level of event heirarchy
             * @param {string} eventAction - 2nd level of event heirarchy
             * @param {string} eventLabel - 3rd level of event heirarchy
             * @param {string} [eventValue] - optional $ value of event
             */
            logAnalyticsEvent(
              'connect_wallet',
              'connect_button_success',
              walletName,
            )
          }
        }
      }}
    >
      {t('common.connect')}
    </Button>
  )
}

const ConnectLink = ({ onboard }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <LocalizedLink
      to="/account"
      className={classes.linkStyle}
      onClick={async e => {
        e.preventDefault()

        /* Analytics Event
         * @param {string} eventCategory - 1st level of event heirarchy
         * @param {string} eventAction - 2nd level of event heirarchy
         * @param {string} eventLabel - 3rd level of event heirarchy
         * @param {object} customDimensions - key-value pairs of custom dimensions to send with request
         * @param {bool} [nonInteractive] - optional boolean flag to determine whether this event should impact bounce rate
         * @param {string} [eventValue] - optional $ value of event
         */
        logAnalyticsEvent(
          'connect_wallet',
          'connect_step_0',
          'press_connect_link',
        )

        const walletSelected = await onboard.walletSelect()
        if (walletSelected) {
          const walletReady = await onboard.walletCheck()
          if (walletReady) {
            const walletName = onboard.getState().wallet.name

            /* Analytics Event
             * @param {string} eventCategory - 1st level of event heirarchy
             * @param {string} eventAction - 2nd level of event heirarchy
             * @param {string} eventLabel - 3rd level of event heirarchy
             * @param {string} [eventValue] - optional $ value of event
             */
            logAnalyticsEvent(
              'connect_wallet',
              'connect_button_success',
              walletName,
            )
          }
        }
      }}
    >
      {t('exchanges.connect_to_track')} ›
    </LocalizedLink>
  )
}

export const LoginLink = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <OnboardConsumer>
      {({ address, onboard }) => {
        return address ? (
          <LocalizedLink to="/account" className={classes.linkStyle}>
            {t('exchanges.connect_to_track_done')} ›
          </LocalizedLink>
        ) : (
          <ConnectLink onboard={onboard} />
        )
      }}
    </OnboardConsumer>
  )
}

function LoginButton({ staticContext, ...props }) {
  const classes = useStyles()

  return (
    <OnboardConsumer>
      {({ address, onboard, balance, web3 }) => {
        return (
          <Grid item className={classes.buttonDrawer} {...props}>
            {address ? (
              <AccountConsumer>
                {({ profile }) => (
                  <AccountWidget
                    selectedAddress={address}
                    profile={profile}
                    balance={balance}
                    web3={web3}
                  />
                )}
              </AccountConsumer>
            ) : (
              <ConnectButton onboard={onboard} />
            )}
          </Grid>
        )
      }}
    </OnboardConsumer>
  )
}

export default withRouter(LoginButton)
