import Onboard from 'bnc-onboard'

const NETWORK_ID = 1
const API_KEY = 'e4964ac8-ac9f-4d1b-a38a-dcd3dd2e3ddf'
const PORTIS_KEY = '2ee0ce5a-1d70-4167-bf69-80397cc3e656'
const FORTMATIC_KEY = 'pk_live_BFC460A22899B6DD'
const WALLET_CONNECT_KEY = 'd539310c92a1415d8922069e7810a4f5'

export function initOnboard(subscriptions) {
  return Onboard({
    dappId: API_KEY,
    networkId: NETWORK_ID,
    subscriptions: subscriptions,
    walletSelect: {
      wallets: [
        {
          walletName: 'metamask',
        },
        {
          walletName: 'coinbase',
        },
        {
          walletName: 'trust',
        },
        {
          walletName: 'status',
        },
        {
          walletName: 'portis',
          apiKey: PORTIS_KEY,
        },
        {
          walletName: 'fortmatic',
          apiKey: FORTMATIC_KEY,
        },
        {
          walletName: 'authereum',
          disableNotifications: false,
        },
        {
          walletName: 'walletConnect',
          infuraKey: WALLET_CONNECT_KEY,
        },
      ],
    },
  })
}
