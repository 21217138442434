import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { NotificationConsumer } from '../../../../../../contexts/NotificationContext'
import { OnboardConsumer } from '../../../../../../contexts/OnboardContext'
import { AccountConsumer } from '../../../../../../contexts/AccountContext'
// ADD IN GA TRACKING FOR SHARES
// Contexts
import TradeTokenLogicProvider from './components/TradeTokenLogicProvider'

// Components
import CurrencyTextField from '../shared/CurrencyTextField'
import FullScreenDialog, {
  TxDetailsWrapper,
  TxDetailRow,
  StepIndicator,
  Step,
  FullScreenDialogContent,
  dialogStyles,
} from '../../../../../shared/FullScreenDialog'
import { Subtitle, H5Text } from '../../../../../shared/Typography'

// i18n
import { useTranslation } from 'react-i18next'

// Icons
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

// Material UI
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Paper from '@material-ui/core/Paper'

// Styles
import { makeStyles } from '@material-ui/styles'
import styles from '../../../../../shared/styles'

// Helpers
import { etherscanTxUrl, slicedAddress } from '../../../../../../helpers'
import { getExchange } from '../../../../../../services/blocklytics'

// Analytics
import { logAnalyticsEvent } from '../../../../../../services/analytics'

const useStyles = makeStyles(theme => ({
  stepPaper: {
    margin: `${theme.spacing(3)}px 0`,
    padding: `6px ${theme.spacing(2)}px 12px`,
  },
  tradeGroup: {
    position: 'sticky',
    width: 240,
    top: 130,
    // borderRadius: theme.spacing(3),
    padding: 12,
    // backgroundColor: '#f6f6f6',
  },
  tabGroup: {
    margin: '-10px 0 -3px 6px',
    width: '100%',
  },
  tab: {
    maxWidth: 20,
  },
  tabIndicator: {
    visibility: 'hidden',
  },
  caption: {
    marginLeft: theme.spacing(1),
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    // margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  rateText: {
    fontSize: '1.4rem',
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
  },
  secondaryText: {
    fontSize: '.9rem',
    lineHeight: 1.3,
  },
}))

const useDialogStyles = makeStyles(dialogStyles)
const useSharedStyles = makeStyles(styles)

const Steps = ({
  exchange,
  onClose,
  currentStep,
  setCurrentStep,
  selectedAddress,
  tokenDetails,
  depositAmount,
  setDepositAmount,
  web3,
  notify,
  balance,
  tokenBalanceOf,
  tokenDecimalsOf,
  tokenApprovalOf,
  setOwnershipUpdateTimestamp,
  transactionHash,
  setTransactionHash,
}) => {
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const sharedClasses = useSharedStyles()
  const { t } = useTranslation()

  const [tokenBalance, setTokenBalance] = useState(null)
  const [tokenDecimals, setTokenDecimals] = useState(null)
  const [approvedBalance, setApprovedBalance] = useState(null)

  useEffect(() => {
    async function updateTokenBalance() {
      console.log('updateTokenBalance', tokenDetails)
      if (tokenDetails && tokenDetails.token) {
        setTokenBalance(await tokenBalanceOf(tokenDetails.token))
        setTokenDecimals(await tokenDecimalsOf(tokenDetails.token))
        // Token approvals are handled by calling approve for the Handler contract for the Proxy contract
        // From: https://docs.dex.ag/api/using-the-api-with-node.js
        setApprovedBalance(
          await tokenApprovalOf(
            tokenDetails.token,
            '0x42dAFA7F65323b3992f447032aBD17f562A1E14C',
          ),
        )
      }
    }

    updateTokenBalance()
  }, [exchange, tokenDetails, tokenBalanceOf, tokenDecimalsOf, tokenApprovalOf])

  const [exchangeData, setExchangeData] = useState(null)
  useEffect(() => {
    async function updateExchangeData() {
      getExchange(exchange)
        .then(data => setExchangeData(data))
        .catch(err => console.log(err))
    }
    updateExchangeData()
  }, [exchange])

  const handleTransaction = transactionHandler => {
    transactionHandler()
      /*
    // This block could receive the data to handle notify and
    // update the transaction hash. For now, it is included
    // on the trade tx itself in TradeTokenLogicProvider.js
    */
      // .then(r => {
      //   console.log(r)
      // })
      .catch(e => {
        console.log(e)
      })
  }

  const [tradeAction, setTradeAction] = useState('Buy')
  const [token1ActionText, setToken1ActionText] = useState(t('common.send'))
  const [token2ActionText, setToken2ActionText] = useState(t('common.receive'))

  useEffect(() => {
    setToken1ActionText(
      tradeAction === 'Buy' ? t('common.send') : t('common.receive'),
    )
    setToken2ActionText(
      tradeAction === 'Buy' ? t('common.receive') : t('common.send'),
    )
  }, [tradeAction, t])

  const [showInverseRate, setShowInverseRate] = useState(true)

  return (
    <NotificationConsumer>
      {({ notify }) => (
        <TradeTokenLogicProvider
          selectedAddress={selectedAddress}
          tradeAction={tradeAction}
          tokenDetails={tokenDetails}
          token1Balance={balance}
          token2Balance={tokenBalance}
          token2Decimals={tokenDecimals}
          approvedBalance={approvedBalance}
          web3={web3}
          notify={notify}
          setCurrentStep={setCurrentStep}
          setTransactionHash={setTransactionHash}
        >
          {({
            fromToken,
            toToken,
            approveToken,
            sendTrade,
            rate: propRate,
            accountConnected,
            disableTradeButton,
            shouldUnlockFirst,
            routing,
          }) => {
            let rate =
              propRate &&
              (showInverseRate ? propRate : (1 / propRate).toFixed(4))

            return (
              <React.Fragment>
                {/* Step 1 */}
                <Step
                  stepIndex={0}
                  currentStep={currentStep}
                  header={t('common.swap_tokens')}
                  subheader={
                    exchangeData &&
                    t(
                      'trade_token.sending_token_to_receive_token',
                      tradeAction === 'Buy'
                        ? {
                            token1: 'ETH',
                            token2: exchangeData.tokenSymbol,
                          }
                        : {
                            token1: exchangeData.tokenSymbol,
                            token2: 'ETH',
                          },
                    )
                  }
                  actions={
                    <React.Fragment>
                      <Grid item>
                        <Button
                          className={dialogClasses.actionButton}
                          variant="contained"
                          size="large"
                          onClick={() => onClose()}
                        >
                          {t('common.cancel')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          className={dialogClasses.actionButton}
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={() => {
                            setCurrentStep(1)
                          }}
                          disabled={disableTradeButton}
                        >
                          {t('common.continue')}
                        </Button>
                      </Grid>
                    </React.Fragment>
                  }
                  customLayout
                >
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justify="flex-start"
                    alignItems="stretch"
                  >
                    {/* Column 1 */}
                    <Grid item xs={12} sm={7}>
                      <Paper className={classes.stepPaper} elevation={4}>
                        <FormHelperText
                          className={classes.helperText}
                          style={{ marginBottom: 6 }}
                        >
                          {token1ActionText}
                        </FormHelperText>
                        <CurrencyTextField
                          tokenSymbol={fromToken.symbol}
                          balance={fromToken.balance}
                          balanceDecimals={fromToken.decimals}
                          value={fromToken.amount}
                          loading={fromToken.loading}
                          onChange={e => fromToken.setAmount(e.target.value)}
                          setMaxAmount={fromToken.setMaxAmount}
                          showMax={tradeAction === 'Buy'}
                          accountConnected={accountConnected}
                          autoFocus
                        />

                        <Tabs
                          className={classes.tabGroup}
                          value={tradeAction}
                          onChange={(e, newValue) => setTradeAction(newValue)}
                          indicatorColor="primary"
                          textColor="primary"
                          TabIndicatorProps={{
                            className: classes.tabIndicator,
                          }}
                        >
                          <Tab
                            className={classes.tab}
                            value="Buy"
                            icon={<ArrowDownwardIcon />}
                            disableRipple
                          />
                          <Tab
                            className={classes.tab}
                            value="Sell"
                            disableRipple
                            icon={<ArrowUpwardIcon />}
                          />
                        </Tabs>

                        <FormHelperText
                          className={classes.helperText}
                          style={{ marginBottom: 6 }}
                        >
                          {token2ActionText}
                        </FormHelperText>
                        <CurrencyTextField
                          tokenSymbol={toToken.symbol}
                          balance={tokenBalance}
                          balanceDecimals={tokenDecimals}
                          value={toToken.amount}
                          loading={toToken.loading}
                          onChange={e => toToken.setAmount(e.target.value)}
                          setMaxAmount={toToken.setMaxAmount}
                          showMax={tradeAction === 'Sell'}
                          accountConnected={accountConnected}
                        />
                      </Paper>
                    </Grid>

                    {/* Column 2 */}
                    <Grid item xs={12} sm={5}>
                      <Paper
                        className={classes.stepPaper}
                        elevation={4}
                        style={{ height: 'calc(100% - 48px)' }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div>
                            <FormHelperText className={classes.helperText}>
                              {t('labels.best_rate')}
                            </FormHelperText>
                            <H5Text
                              className={classes.rateText}
                              onClick={e =>
                                setShowInverseRate(!showInverseRate)
                              }
                            >
                              {!showInverseRate
                                ? `${rate} ${fromToken.symbol}/${toToken.symbol}`
                                : `${rate} ${toToken.symbol}/${fromToken.symbol}`}
                            </H5Text>
                          </div>

                          <div>
                            <FormHelperText className={classes.helperText}>
                              {t('labels.about')}
                            </FormHelperText>
                            <Subtitle>
                              {t('trade_token.about_dexag_message')}
                            </Subtitle>

                            {routing && (
                              <React.Fragment>
                                <FormHelperText className={classes.helperText}>
                                  {t('labels.trade_routing')}
                                </FormHelperText>
                                {routing.map(({ dex, percentage }, idx) => (
                                  <Subtitle
                                    key={idx}
                                    className={classes.secondaryText}
                                  >
                                    - {percentage}% {dex}
                                  </Subtitle>
                                ))}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                </Step>

                {/* Step 2 */}
                <Step
                  stepIndex={1}
                  currentStep={currentStep}
                  header={t('labels.review_transaction')}
                  subheader={
                    exchangeData &&
                    (shouldUnlockFirst
                      ? t('trade_token.approving_token_message', {
                          token: exchangeData.tokenSymbol,
                        })
                      : t(
                          'trade_token.sending_token_to_receive_token',
                          tradeAction === 'Buy'
                            ? {
                                token1: 'ETH',
                                token2: exchangeData.tokenSymbol,
                              }
                            : {
                                token1: exchangeData.tokenSymbol,
                                token2: 'ETH',
                              },
                        ))
                  }
                  actions={
                    <React.Fragment>
                      <Grid item>
                        <Button
                          className={dialogClasses.actionButton}
                          variant="contained"
                          size="large"
                          onClick={() => setCurrentStep(0)}
                        >
                          {t('common.back')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          className={dialogClasses.actionButton}
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={() =>
                            shouldUnlockFirst
                              ? handleTransaction(approveToken)
                              : handleTransaction(sendTrade)
                          }
                        >
                          {shouldUnlockFirst
                            ? t('common.approve')
                            : t('common.swap')}
                        </Button>
                      </Grid>
                    </React.Fragment>
                  }
                  noPadding
                >
                  <TxDetailsWrapper>
                    {shouldUnlockFirst ? (
                      <TxDetailRow
                        description={t('trade_token.approval_required_message')}
                        value={toToken.symbol}
                      />
                    ) : (
                      <React.Fragment>
                        <TxDetailRow
                          description={t('trade_token.sending_message')}
                          value={
                            tradeAction === 'Buy'
                              ? `${fromToken.amount} ${fromToken.symbol}`
                              : `${toToken.amount} ${toToken.symbol}`
                          }
                          divider
                        />
                        <TxDetailRow
                          description={t('labels.to_contract')}
                          value={
                            <a
                              className={sharedClasses.linkStyle}
                              href="https://etherscan.io/address/0x6DE979A1b2D5608621590620CeCc4e5AE5cE3536"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {slicedAddress(
                                '0x6DE979A1b2D5608621590620CeCc4e5AE5cE3536',
                              )}{' '}
                              ↗︎
                            </a>
                          }
                          divider
                        />
                        <TxDetailRow
                          description={t('labels.exchange_rate')}
                          value={
                            !showInverseRate
                              ? `${rate} ${fromToken.symbol}/${toToken.symbol}`
                              : `${rate} ${toToken.symbol}/${fromToken.symbol}`
                          }
                          divider
                        />
                        <TxDetailRow
                          description={t('labels.convenience_fee')}
                          value="0%"
                          strikeValue="0.3%"
                          divider
                        />
                        <TxDetailRow
                          description={t('trade_token.you_will_receive')}
                          value={
                            tradeAction === 'Buy'
                              ? `${toToken.amount} ${toToken.symbol}`
                              : `${fromToken.amount} ${fromToken.symbol}`
                          }
                        />
                      </React.Fragment>
                    )}
                  </TxDetailsWrapper>
                </Step>

                {/* Step 3 */}
                <Step
                  stepIndex={2}
                  currentStep={currentStep}
                  header={
                    shouldUnlockFirst
                      ? t('labels.approval_being_processed')
                      : t('labels.trade_being_processed')
                  }
                  subheader={t('labels.transaction_estimated_time_message')}
                  actions={
                    <Grid
                      role="tabpanel"
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="stretch"
                      spacing={1}
                      style={{ marginTop: 40, width: 160 }}
                    >
                      <Grid item>
                        <Button
                          className={dialogClasses.actionButton}
                          variant="contained"
                          size="large"
                          fullWidth
                          href={etherscanTxUrl(transactionHash)}
                          target="_blank"
                          disabled={!transactionHash}
                        >
                          {t('common.view_tx')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          className={dialogClasses.actionButton}
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth
                          onClick={() => onClose()}
                        >
                          {t('common.close')}
                        </Button>
                      </Grid>
                    </Grid>
                  }
                />
              </React.Fragment>
            )
          }}
        </TradeTokenLogicProvider>
      )}
    </NotificationConsumer>
  )
}

const TradeTokenDialog = ({
  exchange,
  open,
  onClose: onCloseProp,
  tokenDetails,
  web3,
  notify,
  balance,
  selectedAddress,
  setOwnershipUpdateTimestamp,
  tokenBalanceOf,
  tokenDecimalsOf,
  tokenApprovalOf,
}) => {
  const { t } = useTranslation()

  const [currentStep, setCurrentStep] = useState(0)
  const [depositAmount, setDepositAmount] = useState('')
  const [transactionHash, setTransactionHash] = useState(null)

  const steps = useMemo(() => {
    return [
      t('labels.swap_amount'),
      t('labels.review_transaction'),
      t('labels.confirmation'),
    ]
  }, [t])

  const onClose = useCallback(() => {
    onCloseProp()
    setTimeout(() => {
      setCurrentStep(0)
      setDepositAmount('')
      setTransactionHash(null)
    }, 200)
  }, [onCloseProp])

  return (
    <FullScreenDialog
      open={open}
      onClose={onClose}
      cancellable={currentStep < 2}
    >
      <FullScreenDialogContent>
        {/* Steps Wrapper */}
        <Grid item>
          <Grid container direction="row" justify="space-between" wrap="nowrap">
            {steps.map((step, idx) => {
              return (
                <Grid item key={idx}>
                  <StepIndicator
                    index={idx}
                    name={step}
                    currentStep={currentStep}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>

        {/* Steps Content */}
        <Grid item>
          <Steps
            exchange={exchange}
            onClose={onClose}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            selectedAddress={selectedAddress}
            tokenDetails={tokenDetails}
            depositAmount={depositAmount}
            setDepositAmount={setDepositAmount}
            web3={web3}
            notify={notify}
            balance={balance}
            tokenBalanceOf={tokenBalanceOf}
            tokenDecimalsOf={tokenDecimalsOf}
            tokenApprovalOf={tokenApprovalOf}
            setOwnershipUpdateTimestamp={setOwnershipUpdateTimestamp}
            transactionHash={transactionHash}
            setTransactionHash={setTransactionHash}
          />
        </Grid>
      </FullScreenDialogContent>
    </FullScreenDialog>
  )
}

const TradeTokenDialogWrapper = props => (
  <AccountConsumer>
    {({ setOwnershipUpdateTimestamp }) => (
      <OnboardConsumer>
        {({
          web3,
          address,
          balance,
          tokenBalanceOf,
          tokenDecimalsOf,
          tokenApprovalOf,
        }) => (
          <NotificationConsumer>
            {({ notify }) => (
              <TradeTokenDialog
                {...props}
                notify={notify}
                web3={web3}
                selectedAddress={address}
                balance={balance}
                setOwnershipUpdateTimestamp={setOwnershipUpdateTimestamp}
                tokenBalanceOf={tokenBalanceOf}
                tokenDecimalsOf={tokenDecimalsOf}
                tokenApprovalOf={tokenApprovalOf}
              />
            )}
          </NotificationConsumer>
        )}
      </OnboardConsumer>
    )}
  </AccountConsumer>
)

export default TradeTokenDialogWrapper
