// React
import React, { useEffect } from 'react'

// Components
import { H6Text, Subtitle } from './shared/Typography'

// Material UI
import Grid from '@material-ui/core/Grid'

// i18n
import { useTranslation } from 'react-i18next'

// Styles
import styles from './shared/styles'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(styles)

// const InfoEntry = ({ id, title, children }) => (
//   <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
//     <div id={id} style={{ position: 'absolute', top: -100 }} />
//     <SectionHeader>{title}</SectionHeader>
//     {children}
//   </Grid>
// )

const MoreInfo = ({ location }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    const elemntId = location.hash && location.hash.slice(1)

    if (elemntId) {
      const elemnt = document.getElementById(elemntId)
      if (elemnt) elemnt.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }, [location])

  return (
    <Grid
      container
      spacing={4}
      style={{ marginTop: 24 }}
      className={classes.root}
    >
      <Grid item>
        <H6Text>What are liquidity pools?</H6Text>
        <Subtitle paragraph>
          Smart contracts that hold assets in order to facilitate some
          functionality. The most popular use cases today allow decentralized
          exchanges to facilitate token trades.
        </Subtitle>
      </Grid>

      <Grid item>
        <H6Text>How do decentralized exchanges use liquidity pools?</H6Text>
        <Subtitle paragraph>
          Liquidity pools allow traders to trade directly against smart
          contracts instead of finding another trader through an orderbook. The
          practical benefits are that orders fill quickly and transactions costs
          are cheaper.
        </Subtitle>
      </Grid>

      <Grid item>
        <H6Text>What are the benefits of joining liquidity pools?</H6Text>
        <Subtitle paragraph>
          Liquidity providers earn a share of trading fees that each pool
          generates. Ownership is accounted in tokens. Ownership tokens can be
          redeemed against each pool's underlying assets.
        </Subtitle>
      </Grid>

      <Grid item>
        <H6Text>How do liquidity pools make money?</H6Text>
        <Subtitle paragraph>
          The pool collects a trading fee after every trade. Fees are paid back
          into the pool and are collected by liquidity providers who redeem
          their ownership tokens.
        </Subtitle>
      </Grid>

      <Grid item>
        <H6Text>What are the risks of joining liquidity pools?</H6Text>
        <Subtitle paragraph>
          You could lose all your money. Smart contract bugs are relatively
          common. Liquidity pools tend to incorporate not only the platform's
          contracts but also third-party contracts.
        </Subtitle>
        <Subtitle paragraph>
          You could lose value compared to holding. As a liquidity provider, you
          must add a specific ratio of assets. You generate any future trading
          fees, but those assets are continually rebalanced outside of your
          control. In case one asset falls in value against the other, it may
          cause loss compared to not participating.
        </Subtitle>
        <Subtitle paragraph>
          <a
            href="https://medium.com/@pintail/understanding-uniswap-returns-cc593f3499ef"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkStyle}
          >
            {t('exchanges.earn_fees_link')} ↗︎
          </a>
        </Subtitle>
      </Grid>

      <Grid item>
        <H6Text>How do I remove liquidity on Uniswap?</H6Text>
        <Subtitle paragraph>
          Visit the link below to open Uniswap and select the pool you want to
          exit.
        </Subtitle>
        <Subtitle paragraph>
          You could lose value compared to holding. As a liquidity provider, you
          must add a specific ratio of assets. You generate any future trading
          fees, but those assets are continually rebalanced outside of your
          control. In case one asset falls in value against the other, it may
          cause loss compared to not participating.
        </Subtitle>
        <Subtitle paragraph>
          <a
            href="https://uniswap.exchange/remove-liquidity"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkStyle}
          >
            {t('exchanges.stay_liquid_link')} ↗︎
          </a>
        </Subtitle>
      </Grid>

      {/* <InfoEntry id="earnFees" title={t('exchanges.earn_fees')}>
          <Subtitle paragraph>
            {t('exchanges.earn_fees_desc')}
          </Subtitle>
        </InfoEntry> */}
    </Grid>
  )
}

export default MoreInfo
