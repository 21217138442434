import React, { createContext, useState, useEffect } from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { Switch, Link, Redirect } from 'react-router-dom'

const LocaleContext = createContext()

const localePrefix = locale => (!locale || locale === 'en' ? '' : `/${locale}`)

export const LocaleProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState(i18next.languages[0].slice(0, 2))

  const setLocale = matchedLang => {
    const newLang = matchedLang || 'en'

    if (lang !== newLang) {
      i18n.changeLanguage(newLang)
      setLang(newLang)
    }
  }

  return (
    <LocaleContext.Provider
      value={{
        locale: lang,
        localePrefix: localePrefix(lang),
        setLocale,
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export const LocaleConsumer = LocaleContext.Consumer

export class DefaultLocaleRedirect extends React.Component {
  pendingRedirectCheck = true

  render() {
    const { children, appLocale, matchedLocale, location } = this.props

    if (this.pendingRedirectCheck) {
      this.pendingRedirectCheck = false

      if (
        appLocale !== matchedLocale &&
        !(appLocale === 'en' && !matchedLocale)
      ) {
        const newLocation =
          appLocale === 'en'
            ? location.pathname.slice(3)
            : matchedLocale
            ? location.pathname.replace(matchedLocale, appLocale)
            : localePrefix(appLocale) + location.pathname

        return <Redirect to={newLocation} />
      }
    }

    return children
  }
}

export const LocalizedSwitch = ({
  children,
  locale,
  setLocale,
  fallbackRoutes,
}) => {
  useEffect(() => {
    setLocale(locale)
  }, [locale, setLocale])

  return (
    <Switch>
      {children.map((child, idx) => {
        return React.cloneElement(child, {
          ...child.props,
          key: `localized-route-${idx}`,
          path: localePrefix(locale) + child.props.path,
        })
      })}
      {fallbackRoutes}
    </Switch>
  )
}

export const LocalizedLink = ({ to, className, ...props }) => (
  <LocaleConsumer>
    {({ localePrefix }) => (
      <Link
        className={className}
        to={localePrefix + to}
        style={
          className
            ? null
            : {
                display: 'contents',
                textDecoration: 'none',
                color: 'inherit',
                '&:hover': {
                  color: 'inherit',
                },
              }
        }
        {...props}
      />
    )}
  </LocaleConsumer>
)
