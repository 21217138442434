import React from 'react'

// Context
import { AccountConsumer } from '../../../../contexts/AccountContext'

import BetaFeatures from './components/BetaFeatures'
import WatchAddresses from './components/WatchAddresses'

// Feature flags
// import { hide3Box } from '../../../../FeatureFlags'

const Settings = () => {
  return (
    <AccountConsumer>
      {({
        betaFeaturesEnabled,
        setBetaFeaturesEnabled,
        selectedAddress,
        watchedAddresses,
        setWatchedAddresses,
      }) => (
        <React.Fragment>
          {/* Watched Accounts */}
          <WatchAddresses
            selectedAddress={selectedAddress}
            watchedAddresses={watchedAddresses}
            setWatchedAddresses={setWatchedAddresses}
          />

          {/* Beta Features */}
          <BetaFeatures
            betaFeaturesEnabled={betaFeaturesEnabled}
            setBetaFeaturesEnabled={setBetaFeaturesEnabled}
          />
        </React.Fragment>
      )}
    </AccountConsumer>
  )
}

export default Settings
