// React
import React from 'react'
import { HashRouter as Router } from 'react-router-dom'

// Contexts
import { ExchangePriceProvider } from './contexts/ExchangePriceContext'
import { LocaleProvider } from './contexts/LocaleContext'
import { AccountProvider } from './contexts/AccountContext'
import { NotificationProvider } from './contexts/NotificationContext'
import { OnboardProvider } from './contexts/OnboardContext'

// Components
import MainLayout from './pages/layouts/MainLayout'

// Material UI
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import pink from '@material-ui/core/colors/pink'

// Date library
import MomentUtils from '@date-io/moment'

const defaultTheme = createMuiTheme()

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
    primary: {
      main: pink['500'],
      light: '#ffffff',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.07),0px 1px 3px 0px rgba(0,0,0,0.06)',
    '0px 3px 1px -2px rgba(0,0,0,0.1),0px 2px 2px 0px rgba(0,0,0,0.07),0px 1px 5px 0px rgba(0,0,0,0.06)',
    '0px 3px 3px -2px rgba(0,0,0,0.1),0px 3px 4px 0px rgba(0,0,0,0.07),0px 1px 8px 0px rgba(0,0,0,0.06)',
    '0px 2px 4px -1px rgba(0,0,0,0.1),0px 4px 5px 0px rgba(0,0,0,0.07),0px 1px 10px 0px rgba(0,0,0,0.06)',
    '0px 3px 5px -1px rgba(0,0,0,0.1),0px 5px 8px 0px rgba(0,0,0,0.07),0px 1px 14px 0px rgba(0,0,0,0.06)',
    '0px 3px 5px -1px rgba(0,0,0,0.1),0px 6px 10px 0px rgba(0,0,0,0.07),0px 1px 18px 0px rgba(0,0,0,0.06)',
    '0px 4px 5px -2px rgba(0,0,0,0.1),0px 7px 10px 1px rgba(0,0,0,0.07),0px 2px 16px 1px rgba(0,0,0,0.06)',
    '0px 5px 5px -3px rgba(0,0,0,0.1),0px 8px 10px 1px rgba(0,0,0,0.07),0px 3px 14px 2px rgba(0,0,0,0.06)',
    '0px 5px 6px -3px rgba(0,0,0,0.1),0px 9px 12px 1px rgba(0,0,0,0.07),0px 3px 16px 2px rgba(0,0,0,0.06)',
    '0px 6px 6px -3px rgba(0,0,0,0.1),0px 10px 14px 1px rgba(0,0,0,0.07),0px 4px 18px 3px rgba(0,0,0,0.06)',
    '0px 6px 7px -4px rgba(0,0,0,0.1),0px 11px 15px 1px rgba(0,0,0,0.07),0px 4px 20px 3px rgba(0,0,0,0.06)',
    '0px 7px 8px -4px rgba(0,0,0,0.1),0px 12px 17px 2px rgba(0,0,0,0.07),0px 5px 22px 4px rgba(0,0,0,0.06)',
    '0px 7px 8px -4px rgba(0,0,0,0.1),0px 13px 19px 2px rgba(0,0,0,0.07),0px 5px 24px 4px rgba(0,0,0,0.06)',
    '0px 7px 9px -4px rgba(0,0,0,0.1),0px 14px 21px 2px rgba(0,0,0,0.07),0px 5px 26px 4px rgba(0,0,0,0.06)',
    '0px 8px 9px -5px rgba(0,0,0,0.1),0px 15px 22px 2px rgba(0,0,0,0.07),0px 6px 28px 5px rgba(0,0,0,0.06)',
    '0px 8px 10px -5px rgba(0,0,0,0.1),0px 16px 24px 2px rgba(0,0,0,0.07),0px 6px 30px 5px rgba(0,0,0,0.06)',
    '0px 8px 11px -5px rgba(0,0,0,0.1),0px 17px 26px 2px rgba(0,0,0,0.07),0px 6px 32px 5px rgba(0,0,0,0.06)',
    '0px 9px 11px -5px rgba(0,0,0,0.1),0px 18px 28px 2px rgba(0,0,0,0.07),0px 7px 34px 6px rgba(0,0,0,0.06)',
    '0px 9px 12px -6px rgba(0,0,0,0.1),0px 19px 29px 2px rgba(0,0,0,0.07),0px 7px 36px 6px rgba(0,0,0,0.06)',
    '0px 10px 13px -6px rgba(0,0,0,0.1),0px 20px 31px 3px rgba(0,0,0,0.07),0px 8px 38px 7px rgba(0,0,0,0.06)',
    '0px 10px 13px -6px rgba(0,0,0,0.1),0px 21px 33px 3px rgba(0,0,0,0.07),0px 8px 40px 7px rgba(0,0,0,0.06)',
    '0px 10px 14px -6px rgba(0,0,0,0.1),0px 22px 35px 3px rgba(0,0,0,0.07),0px 8px 42px 7px rgba(0,0,0,0.06)',
    '0px 11px 14px -7px rgba(0,0,0,0.1),0px 23px 36px 3px rgba(0,0,0,0.07),0px 9px 44px 8px rgba(0,0,0,0.06)',
    '0px 11px 15px -7px rgba(0,0,0,0.1),0px 24px 38px 3px rgba(0,0,0,0.07),0px 9px 46px 8px rgba(0,0,0,0.06)',
  ],
  overrides: {
    MuiTypography: {
      h1: {
        fontWeight: 900,
        fontSize: '2.75rem',
      },
      h2: {
        fontWeight: 900,
        fontSize: '2.25rem',
      },
      h3: {
        fontWeight: 700,
        fontSize: '2.25rem',
      },
      subtitle1: {
        fontWeight: 500,
        fontSize: '2.75rem',
        lineHeight: 1,
        letterSpacing: -1.2,
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderWidth: 0,
      },
    },
    MuiAvatar: {
      root: {
        backgroundColor: '#FFF',
        fontSize: '1rem',
        fontWeight: 500,
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#FFF',
        color: defaultTheme.palette.text.primary,
      },
    },
    MuiInput: {
      underline: {
        // width: 150,
        '&:before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.38)',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottomWidth: 3,
          borderBottomStyle: 'solid',
          borderBottomColor: pink[500],
        },
        '&:after': {
          borderBottom: 'none',
        },
      },
    },
    MuiTabs: {
      indicator: {
        height: 3,
      },
    },
    MuiTab: {
      root: {
        minWidth: 0,
        [defaultTheme.breakpoints.up('md')]: {
          minWidth: 0,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      outlined: {
        padding: '12px 25px 12px 12px',
      },
    },
    MuiIconButton: {
      label: {
        color: 'rgba(0, 0, 0, 1.0)',
      },
      sizeSmall: {
        width: 24,
        height: 24,
      },
    },
    MuiChip: {
      label: {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 2,
        lineHeight: 1.75,
        '&:hover': {
          // borderWidth: 2,
          //         	backgroundColor: '#FFF',
          //       		boxShadow: defaultTheme.shadows[8],
        },
      },
      containedSizeLarge: {
        padding: '16px 24px',
      },
      containedPrimary: {
        boxShadow: 'none',
        borderWidth: 2,
        borderColor: pink['500'],
        '&:hover': {
          boxShadow: defaultTheme.shadows[8],
          backgroundColor: pink['500'],
        },
        '&:active': {
          boxShadow: defaultTheme.shadows[2],
        },
      },
      outlinedSizeLarge: {
        padding: '16px 24px',
      },
      outlinedPrimary: {
        backgroundColor: '#FFFFFF',
        borderWidth: 2,
        borderColor: pink['500'],
        '&:hover': {
          borderWidth: 2,
          backgroundColor: '#FFF',
          boxShadow: defaultTheme.shadows[8],
        },
        '&:active': {
          boxShadow: defaultTheme.shadows[2],
        },
      },
    },
  },
})

function App() {
  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <Router>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ExchangePriceProvider>
              <OnboardProvider>
                <NotificationProvider>
                  <LocaleProvider>
                    <AccountProvider>
                      <MainLayout />
                    </AccountProvider>
                  </LocaleProvider>
                </NotificationProvider>
              </OnboardProvider>
            </ExchangePriceProvider>
          </MuiPickersUtilsProvider>
        </Router>
      </MuiThemeProvider>
    </div>
  )
}

export default App
