import React from 'react'

// Material UI
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

// Styles
import { makeStyles } from '@material-ui/core/styles'

// i18n
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    [theme.breakpoints.down('md')]: {
      marginTop: -12,
    },
    [theme.breakpoints.down('430')]: {
      marginTop: 12,
    },
  },
}))

const PlatformSelect = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel
        className="MuiInputLabel-shrink"
        id="demoplatform-outlined-label"
        style={{ top: -3, left: -6, backgroundColor: 'white', padding: 6 }}
      >
        {t('labels.protocol')}
      </InputLabel>
      <Select
        native
        labelId="demoplatform-outlined-label"
        inputProps={{
          id: 'outlined-age-native-simple',
        }}
        style={{ height: 56 }}
        {...props}
      >
        <option value="">Any</option>
        <option value="balancer">Balancer</option>
        <option value="bancor" disabled>Bancor</option>
        <option value="curve">Curve</option>
        <option value="mooniswap">Mooniswap</option>
        <option value="sushiswap">Sushiswap</option>
        <option value="uniswap">Uniswap</option>
        <option value="uniswap-v2">Uniswap v2</option>
      </Select>
    </FormControl>
  )
}

export default PlatformSelect
