import React, { useMemo, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isNil } from 'ramda'

// Components
import { Caption } from '../../../../../shared/Typography'

// Material UI
import Fade from '@material-ui/core/Fade'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'

// Web3
import Web3 from 'web3'

// i18n
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f9f9f9',
  },
  textField: {
    margin: theme.spacing(1),
    width: '100%',
  },
  helperText: {
    marginTop: 2,
  },
  inputRoot: {
    width: '100%',
  },
  input: {
    padding: '6px 10px',
    // paddingLeft: '10px',
    '&[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  adornment: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    backgroundColor: '#eaeaea',
    maxHeight: 'none',
    width: '60px',
    minWidth: '60px',
    height: '40px',
    borderRadius: '4px 0px 0px 4px',
  },
  currency: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 400,
  },
  grid: {
    width: '100%',
    // border: '2px solid #dedede',
    display: 'grid',
    gridTemplateColumns: '60px auto',
  },
  gridWithMax: {
    width: '100%',
    // border: '2px solid #dedede',
    display: 'grid',
    gridTemplateColumns: '60px auto 35px',
    alignItems: 'center',
  },
  inputError: {
    border: '2px solid #f44336',
    boxSizing: 'border-box',
    margin: '-2px',
  },
}))

const isValidInput = input => /^([0-9]*(?:[.][0-9]{0,18})?)$/.test(input) // TODO - update for balanceDecimals

const CurrencyTextField = ({
  accountConnected,
  tokenSymbol,
  balance,
  balanceDecimals,
  value: propValue,
  loading,
  setMaxAmount,
  showMax,
  inTransition,
  onChange: onChangeProp,
  ...props
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const onChangeHandler = useCallback(
    event => {
      const input = event.target.value
      if (isValidInput(input) && onChangeProp) onChangeProp(event)
    },
    [onChangeProp],
  )

  var BN = Web3.utils.BN

  const trimmedBalance = useMemo(() => {
    // Handle missing details
    if (!balance || !balanceDecimals) return null

    let numerator = balance
    let denominator = new BN('10').pow(balanceDecimals)
    return (Math.floor((numerator / denominator) * 10 ** 4) / 10 ** 4).toFixed(
      4,
    )
  }, [balance, balanceDecimals, BN])

  const exceedsBalance = useMemo(() => {
    // Handle missing details
    if (!balance || !balanceDecimals) return false

    const cmpValue = parseFloat(propValue)

    let numerator = balance
    let denominator = new BN('10').pow(balanceDecimals)
    const cmpBalance = parseFloat(numerator / denominator)

    return showMax && cmpValue > cmpBalance
  }, [showMax, propValue, balance, balanceDecimals, BN])

  return (
    <React.Fragment>
      <Paper
        component="div"
        className={`${classes.root} ${
          exceedsBalance ? classes.inputError : null
        }`}
        elevation={0}
      >
        <form autoComplete="off">
          <div className={showMax ? classes.gridWithMax : classes.grid}>
            <div className={classes.adornment}>
              <Typography className={classes.currency}>
                {tokenSymbol}
              </Typography>
            </div>
            <Fade in={!inTransition}>
              <InputBase
                className="outlined-input-currency"
                classes={{ root: classes.inputRoot, input: classes.input }}
                // type="number"
                placeholder="0.0"
                value={!loading && propValue ? propValue : ''}
                disabled={loading}
                onChange={onChangeHandler}
                {...props}
              />
            </Fade>
            {showMax && accountConnected && (
              <Fade in={!inTransition}>
                <Caption
                  lowEmphasis
                  style={{ cursor: 'pointer' }}
                  onClick={e => {
                    e.preventDefault()
                    setMaxAmount()
                  }}
                >
                  {t('common.max')}
                </Caption>
              </Fade>
            )}
          </div>
        </form>
      </Paper>

      <div
        className={classes.grid}
        style={{ marginTop: '2px', gridGap: '10px' }}
      >
        <div />
        <FormHelperText
          className={`${classes.helperText} ${
            !loading && exceedsBalance ? 'Mui-error' : null
          }`}
        >
          {loading
            ? `${t('trade_token.finding_best_price')}...`
            : accountConnected &&
              (isNil(balance) || isNil(balanceDecimals)
                ? `${t('trade_token.getting_balance')}...`
                : `${t('common.balance')}: ${trimmedBalance} ${tokenSymbol ||
                    ''}`)}
        </FormHelperText>
      </div>
    </React.Fragment>
  )
}

export default CurrencyTextField
