import React, { createContext, useState, useEffect } from 'react'
import { initNotify } from '../services/notify.js'

const NotificationContext = createContext()

export const NotificationProvider = ({ children }) => {
  const [notify, setNotify] = useState(null)

  useEffect(() => {
    setNotify(initNotify())
  }, [])

  return (
    <NotificationContext.Provider
      value={{
        notify,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export const NotificationConsumer = NotificationContext.Consumer
