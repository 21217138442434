// TODO - remove this hardcoded exception. See note in ExchangePriceContext.js
export const pooltogether = {
  '0xb7896fce748396ecfc240f5a0d3cc92ca42d7d84': {
    exchange: '0xb7896fce748396ecfc240f5a0d3cc92ca42d7d84',
    platform: 'PoolTogether',
    base: '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359',
    baseName: 'Dai Stablecoin v1.0',
    baseSymbol: 'SAI',
    basePrice: 1,
  },
  '0x29fe7d60ddf151e5b52e5fab4f1325da6b2bd958': {
    exchange: '0x29fe7d60ddf151e5b52e5fab4f1325da6b2bd958',
    platform: 'PoolTogether',
    base: '0x6b175474e89094c44da98b954eedeac495271d0f',
    baseName: 'Dai Stablecoin',
    baseSymbol: 'DAI',
    basePrice: 1,
  },
  '0x0034ea9808e620a0ef79261c51af20614b742b24': {
    exchange: '0x0034ea9808e620a0ef79261c51af20614b742b24',
    platform: 'PoolTogether',
    base: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    baseName: 'USD//C',
    baseSymbol: 'USDC',
    basePrice: 1,
  },
}

export const exchanges = [
  {
    exchange: '0xe9cf7887b93150d4f2da7dfc6d502b216438f244',
    platform: 'uniswap',
    token: '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb',
    tokenName: 'Synth sETH',
    tokenSymbol: 'sETH',
  },
  {
    exchange: '0x2c4bd064b998838076fa341a83d007fc2fa50957',
    platform: 'uniswap',
    token: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
    tokenName: 'Maker',
    tokenSymbol: 'MKR',
  },
  {
    exchange: '0x09cabec1ead1c0ba254b09efb3ee13841712be14',
    platform: 'uniswap',
    token: '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359',
    tokenName: 'Dai Stablecoin v1.0',
    tokenSymbol: 'SAI',
  },
  {
    exchange: '0xa2881a90bf33f03e7a3f803765cd2ed5c8928dfb',
    platform: 'uniswap',
    token: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    tokenName: 'Wrapped Ether',
    tokenSymbol: 'WETH',
  },
  {
    exchange: '0x3958b4ec427f8fa24eb60f42821760e88d485f7f',
    platform: 'uniswap',
    token: '0xc011a72400e58ecd99ee497cf89e3775d4bd732f',
    tokenName: 'Synthetix Network Token',
    tokenSymbol: 'SNX',
  },
  {
    exchange: '0x2a1530c4c41db0b0b2bb646cb5eb1a67b7158667',
    platform: 'uniswap',
    token: '0x6b175474e89094c44da98b954eedeac495271d0f',
    tokenName: 'Dai Stablecoin',
    tokenSymbol: 'DAI',
  },
  {
    exchange: '0x97dec872013f6b5fb443861090ad931542878126',
    platform: 'uniswap',
    token: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    tokenName: 'USD//C',
    tokenSymbol: 'USDC',
  },
  {
    exchange: '0x4d2f5cfba55ae412221182d8475bc85799a5644b',
    platform: 'uniswap',
    token: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    tokenName: 'Wrapped BTC',
    tokenSymbol: 'WBTC',
  },
  {
    exchange: '0x2e642b8d59b45a1d8c5aef716a84ff44ea665914',
    platform: 'uniswap',
    token: '0x0d8775f648430679a709e98d2b0cb6250d2887ef',
    tokenName: 'Basic Attention Token',
    tokenSymbol: 'BAT',
  },
  {
    exchange: '0xb944d13b2f4047fc7bd3f7013bcf01b115fb260d',
    platform: 'uniswap',
    token: '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
    tokenName: 'Synth sUSD',
    tokenSymbol: 'sUSD',
  },
]
