import React from 'react'

// Material UI
import Typography from '@material-ui/core/Typography'

// Styles
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '2.75rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  sectionHeader: {
    fontSize: '2.25rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.75rem',
    },
    marginRight: '0.75rem',
  },
  H5Text: {
    fontSize: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
    },
  },
}))

export const Title = ({ children, align, ...props }) => {
  const classes = useStyles()

  return (
    <Typography className={classes.title} variant="h1" align={align} {...props}>
      {children}
    </Typography>
  )
}

export const ActionCardTitle = ({ children, ...props }) => (
  <Typography variant="h1" {...props}>
    {children}
  </Typography>
)

export const SectionHeader = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <Typography
      className={classes.sectionHeader}
      variant="h2"
      {...props}
      gutterBottom
    >
      {children}
    </Typography>
  )
}

export const H5Text = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <Typography className={classes.H5Text} variant="h5" {...props}>
      {children}
    </Typography>
  )
}

export const H6Text = ({ children, ...props }) => {
  return (
    <Typography variant="h6" {...props}>
      {children}
    </Typography>
  )
}

export const Subtitle = ({ children, align, ...props }) => {
  return (
    <Typography variant="body1" align={align} {...props}>
      {children}
    </Typography>
  )
}

export const Subtitle1 = ({ children, ...props }) => {
  return (
    <Typography variant="subtitle1" {...props}>
      {children}
    </Typography>
  )
}

export const Subtitle2 = ({ children, ...props }) => {
  return (
    <Typography variant="subtitle2" {...props}>
      {children}
    </Typography>
  )
}

export const Body2Text = ({ children, lowEmphasis, ...props }) => {
  return (
    <Typography
      variant="body2"
      {...props}
      {...(lowEmphasis && { className: 'lowEmphasis' })}
    >
      {children}
    </Typography>
  )
}

export const Caption = ({ children, lowEmphasis, ...props }) => {
  return (
    <Typography
      variant="caption"
      {...(lowEmphasis && { className: 'lowEmphasis' })}
      {...props}
    >
      {children}
    </Typography>
  )
}

export const FooterHeader = ({ children, ...props }) => {
  return (
    <Typography
      variant="caption"
      style={{
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '0.625rem',
        color: 'rgba(0,0,0,0.37)',
        letterSpacing: '0.02rem',
      }}
      {...props}
    >
      {children}
    </Typography>
  )
}

export const FooterBody = ({ children, ...props }) => {
  return (
    <Typography
      variant="caption"
      style={{
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '0.625rem',
        color: 'rgba(0,0,0,0.37)',
        letterSpacing: '0.0125rem',
      }}
      {...props}
    >
      {children}
    </Typography>
  )
}
