const dexAgUri = 'https://api.dex.ag'

export const getDexAgTrade = (
  fromTokenSymbol,
  toTokenSymbol,
  amount,
  isFrom = true,
  abortSignal = null,
) => {
  return new Promise((resolve, reject) => {
    if (!fromTokenSymbol || !toTokenSymbol)
      reject(new Error('No token symbols were provided'))

    let dataSource = `${dexAgUri}/trade?dex=best&from=${fromTokenSymbol}&to=${toTokenSymbol}&proxy=0x6DE979A1b2D5608621590620CeCc4e5AE5cE3536`
    dataSource = isFrom
      ? `${dataSource}&fromAmount=${amount}`
      : `${dataSource}&toAmount=${amount}`

    fetch(dataSource, { signal: abortSignal })
      .then(r => r.json())
      .then(tx => resolve(tx))
      .catch(err => {
        if (err.name !== 'AbortError')
          console.log('Fetching failed `getTrade`:', err)
        reject(err)
      })
  })
}
