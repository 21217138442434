import React, { useCallback, useMemo } from 'react'

// Material UI
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

// Components
import PoolAvatar from './PoolAvatar'
import { Subtitle, Title } from './Typography'

// Helpers
import { format, usdFormat } from '../../helpers'
import { pooltogether } from '../../shared/exchanges'

// i18n
import { useTranslation } from 'react-i18next'

// Styles
import { makeStyles } from '@material-ui/styles'
import { LocalizedLink } from '../../contexts/LocaleContext'
import PoolInfoWidget from './PoolInfoWidget'

export const useAssetCardStyles = makeStyles(theme => ({
  assetCardBase: {
    display: 'inline-block',
    marginTop: 4,
    overflow: 'hidden',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    padding: '8px 8px 16px 8px',
    width: 230,
    minHeight: 230,
  },
  assetCardBaseWithHover: {
    display: 'inline-block',
    width: 230,
    minHeight: 230,
    overflow: 'hidden',
    marginTop: 4,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    padding: '8px 8px 16px 8px',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: theme.shadows[8],
    },
    transition: 'all .15s',
  },
  helperText: {
    fontSize: '0.75rem',
    marginTop: 8,
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
    lineHeight: '1em',
    letterSpacing: '0.03333em',
  },
}))

const CardContent = ({
  showExchangeDetails,
  backed_by,
  base,
  baseName,
  baseSymbol,
  exchangeData,
  exchange,
  loading,
  ethPrice,
  ownership,
  ownership_tokens,
  platform,
  token,
  tokenName,
  tokenSymbol,
  underlying_base,
  underlying_token,
  usd_share_value,
  empty,
}) => {
  const classes = useAssetCardStyles()
  const { t } = useTranslation()

  // TODO - replace with better solution
  // This is a temporary solution because exchange details
  // are not more easiliy available to the endpoint.
  const detailsForExchange = useCallback(
    exchange => {
      if (pooltogether[exchange]) return pooltogether[exchange] // See note in ExchangePriceContext.js

      let filtered_exchange =
        exchangeData && exchangeData.filter(row => row.exchange === exchange)
      return filtered_exchange ? filtered_exchange[0] : null
    },
    [exchangeData],
  )

  const exchangeDetails = useMemo(() => {
    return detailsForExchange(exchange)
  }, [detailsForExchange, exchange])

  return !empty ? (
    <Grid container direction="column" alignItems="flex-start">
      {showExchangeDetails && (
        <Grid item>
          <PoolInfoWidget
            name={exchangeDetails && exchangeDetails.poolName}
            platform={exchangeDetails && exchangeDetails.platform}
            assets={exchangeDetails && exchangeDetails.assets}
            loading={loading}
          />
        </Grid>
      )}

      <Grid item>
        <Typography className={classes.helperText} gutterBottom>
          {t('labels.share_value')}
        </Typography>
        {!ethPrice || !exchangeData || loading ? (
          <Skeleton
            height={38}
            width={120}
            style={{ margin: 0, display: 'inline-block' }}
          />
        ) : (
          <Title>
            {usdFormat(usd_share_value)}
          </Title>
        )}
      </Grid>

      <Grid item>
        <Typography className={classes.helperText}>
          {t('labels.your_shares')}
        </Typography>
        {loading ? (
          <Skeleton
            height={20}
            width={70}
            style={{ margin: 0, marginTop: 4, display: 'inline-block' }}
          />
        ) : (
          <Subtitle>{`${ownership_tokens}`}</Subtitle>
        )}
      </Grid>

      {/* Backed by */}
      {backed_by ? (
        <Grid item>
          <Typography className={classes.helperText}>
            {t('labels.backed_by')}
          </Typography>
          {backed_by.map((row, index) => (
            <Grid
              key={index}
              container
              direction="row"
              alignItems="center"
              spacing={1}
              wrap="nowrap"
            >
              <Grid item>
                <PoolAvatar token={row.token} size={18} />
              </Grid>
              <Grid item>
                <Subtitle>{format(row.balance)}</Subtitle>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid item>
          <Typography className={classes.helperText}>
            {t('labels.backed_by')}
          </Typography>
          {!exchangeData || loading ? (
            <Grid container direction="column" alignItems="flex-start">
              <Skeleton
                height={21}
                width={100}
                style={{ margin: 0, marginTop: 4, display: 'inline-block' }}
              />
              <Skeleton
                height={21}
                width={100}
                style={{ margin: 0, marginTop: 4, display: 'inline-block' }}
              />
            </Grid>
          ) : (
            <React.Fragment>
              {((exchangeDetails && exchangeDetails.token) || token) && (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <PoolAvatar
                      token={exchangeDetails ? exchangeDetails.token : token}
                      size={18}
                    />
                  </Grid>
                  <Grid item>
                    <Subtitle>
                      {underlying_token
                        ? `${format(underlying_token)}`
                        : `${exchangeDetails &&
                            exchangeDetails.tokenLiquidity &&
                            format(
                              (ownership / 100) *
                                exchangeDetails.tokenLiquidity,
                            )}`}
                    </Subtitle>
                  </Grid>
                </Grid>
              )}

              {((exchangeDetails && exchangeDetails.base) || base) && (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <PoolAvatar
                      token={exchangeDetails ? exchangeDetails.base : base}
                      size={18}
                    />
                  </Grid>
                  <Grid item>
                    <Subtitle>
                      {underlying_base
                        ? `${format(underlying_base)}`
                        : `${exchangeDetails &&
                            exchangeDetails.baseLiquidity &&
                            format(
                              (ownership / 100) * exchangeDetails.baseLiquidity,
                            )}`}
                    </Subtitle>
                  </Grid>
                </Grid>
              )}
            </React.Fragment>
          )}
        </Grid>
      )}
    </Grid>
  ) : showExchangeDetails ? (
    <Subtitle gutterBottom>{t('account.empty_asset_card_message')}</Subtitle>
  ) : (
    <Grid container direction="column" alignItems="flex-start">
      <Grid item>
        <Typography className={classes.helperText} gutterBottom>
          {t('labels.share_value')}
        </Typography>
        <Title>{usdFormat(0)}</Title>
      </Grid>

      <Grid item>
        <Typography className={classes.helperText}>
          {t('trades.dont_own_shares')}
        </Typography>
      </Grid>

      <Grid item>
        <Typography className={classes.helperText}>
          {t('trades.ownership_shares_will_appear_here')}
        </Typography>
      </Grid>
    </Grid>
  )
}

const AssetCard = ({
  isClickable = true,
  showExchangeDetails = true,
  exchange,
  ...props
}) => {
  const classes = useAssetCardStyles()

  return isClickable && showExchangeDetails && exchange ? (
    <Paper elevation={4} className={classes.assetCardBaseWithHover}>
      <LocalizedLink
        to={`/shares/${exchange}`}
        style={{
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <CardContent
          {...props}
          showExchangeDetails={showExchangeDetails}
          exchange={exchange}
        />
      </LocalizedLink>
    </Paper>
  ) : (
    <Paper elevation={4} className={classes.assetCardBase}>
      <CardContent
        {...props}
        showExchangeDetails={showExchangeDetails}
        exchange={exchange}
      />
    </Paper>
  )
}

export default AssetCard
