import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Contexts
import { AccountConsumer } from '../../contexts/AccountContext'
import { ExchangePriceConsumer } from '../../contexts/ExchangePriceContext'
import { OnboardConsumer } from '../../contexts/OnboardContext'

// Components
import NavBar, { NavBarItem } from '../shared/NavBar'
import { SectionHeader, Subtitle } from '../shared/Typography'
import HistoryTabContent from './components/HistoryTab'
import SettingsTabContent from './components/SettingsTab'
import SharesTabContent from './components/SharesTab'
import ReturnsTabContent from './components/ReturnsTab'

// Helpers
import { format, usdFormat, slicedAddress } from '../../helpers'
import { isNil } from 'ramda'

// Material UI
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import withStyles from '@material-ui/core/styles/withStyles'

// i18n
import { withTranslation, Trans } from 'react-i18next'

// Styles
import styles from '../shared/styles'

// Analytics
import { logAnalyticsPageview } from '../../services/analytics'

// 3Box
// const { getProfile } = require('3box/lib/api')

class AccountBase extends Component {
  constructor(props) {
    // Google Analytics pageview
    logAnalyticsPageview('/account', 'Account')

    super(props)
    this.state = {
      selectedAddress: props.selectedAddress,
      // ownershipData: [],
      // ownershipDataIsLoading: false,
      ownershipHistoryData: [],
      ownershipHistoryDataIsLoading: false,
      profile: null,
      navTabValue: 0,
      returnTypeTabValue: 1,
    }
  }

  componentDidMount() {
    this.setState({
      ownershipDataSources: this.ownershipDataSources(),
      historyDataSources: this.historyDataSources(),
      // profile: this.getProfile(),
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedAddress !== this.props.selectedAddress) {
      this.setState({ selectedAddress: this.props.selectedAddress }, () => {
        this.setState({
          ownershipDataSources: this.ownershipDataSources(),
          historyDataSources: this.historyDataSources(),
          // profile: this.getProfile(),
        })
      })
    }

    if (prevProps.watchedAddresses !== this.props.watchedAddresses) {
      this.setState({
        ownershipDataSources: this.ownershipDataSources(),
        historyDataSources: this.historyDataSources(),
      })
    }
  }

  handleReturnTypeTabChange = (event, newValue) => {
    this.setState({
      returnTypeTabValue: newValue,
    })
  }

  // getProfile() {
  //   const { selectedAddress } = this.state
  //   if (!selectedAddress) return null

  //   getProfile(selectedAddress).then(profile => {
  //     profile && this.setState({ profile: profile })
  //   })
  // }

  ownershipDataSources = () => {
    const { watchedAddresses } = this.props

    return watchedAddresses
      .map(address => {
        return address
          ? `https://api.blocklytics.org/uniswap/v1/ownership/${address}?key=AIzaSyDn7LYCJuJK6fM37nEpwVTsXdQ5eok0wQk`
          : null
      })
      .filter(source => source !== null)
  }

  historyDataSources = () => {
    const { watchedAddresses } = this.props

    return watchedAddresses
      .map(address => {
        return address
          ? `https://api.blocklytics.org/uniswap/v1/ownership/${address}/history?key=AIzaSyDn7LYCJuJK6fM37nEpwVTsXdQ5eok0wQk`
          : null
      })
      .filter(source => source !== null)
  }

  getStringForRowValue = (ethBalance, tokenBalance, tokenSymbol) => {
    const { ethPrice } = this.props
    const { returnTypeTabValue } = this.state

    switch (returnTypeTabValue) {
      case 0: // POOL
        return !isNil(tokenBalance && ethBalance) ? (
          `${format(tokenBalance)} ${tokenSymbol} • ${format(ethBalance)} ETH`
        ) : (
          <Skeleton
            height={16}
            width={100}
            style={{ margin: 0, display: 'inline-block' }}
          />
        )
      case 1: // ETH
        return !isNil(ethBalance) ? (
          `${format(2 * ethBalance, 3)} ETH`
        ) : (
          <Skeleton
            height={16}
            width={100}
            style={{ margin: 0, display: 'inline-block' }}
          />
        )
      case 2: // USD
        return !isNil(ethPrice && ethBalance) ? (
          `${usdFormat(2 * ethPrice * ethBalance)}`
        ) : (
          <Skeleton
            height={16}
            width={100}
            style={{ margin: 0, display: 'inline-block' }}
          />
        )
      default:
        break
    }
  }

  render() {
    const {
      classes,
      exchangeData,
      ethPrice,
      watchedAddresses,
      ownershipData,
      ownershipDataIsLoading,
      t,
    } = this.props
    const {
      selectedAddress,
      profile,
      navTabValue,
      returnTypeTabValue,
      ownershipDataSources,
      historyDataSources,
      // ownershipData,
      // ownershipDataIsLoading,
    } = this.state

    return (
      <React.Fragment>
        <Helmet>
          <title>{t('labels.account')} | Pools.fyi</title>
          <meta
            property="og:title"
            content={`${t('labels.account')} | Pools.fyi`}
          />
          <meta
            name="twitter:title"
            content={`${t('labels.account')} | Pools.fyi`}
          />
        </Helmet>

        <Grid
          container
          spacing={4}
          style={{ marginTop: 24 }}
          className={classes.root}
        >
          {selectedAddress ? (
            <Grid item xs={12}>
              <SectionHeader>
                <span role="img" aria-label="emoji-wave">
                  👋
                </span>
              </SectionHeader>
              <SectionHeader>
                {t('account.welcome', {
                  address:
                    profile && profile.name
                      ? profile.name
                      : slicedAddress(selectedAddress),
                })}
              </SectionHeader>
              <Subtitle>
                <Trans
                  i18nKey="account.welcome_subtitle"
                  values={{ email: 'hello@blocklytics.org' }}
                >
                  <a
                    href="mailto:hello@blocklytics.org"
                    className={classes.linkStyle}
                  >
                    email
                  </a>
                </Trans>
              </Subtitle>
            </Grid>
          ) : (
            <React.Fragment>
              <Grid item xs={12}>
                <SectionHeader>{t('common.your_account')}</SectionHeader>
                <Subtitle>{t('account.connect_features')}</Subtitle>
              </Grid>
              <Grid item xs={12}>
                <SectionHeader>
                  {t('account.connect_account_header_v2')}
                </SectionHeader>
                <Subtitle>{t('account.connect_account_desc_v2')}</Subtitle>
              </Grid>
            </React.Fragment>
          )}

          {selectedAddress && (
            <>
              <Grid item xs={12}>
                <NavBar
                  value={navTabValue}
                  onChange={(event, value) => {
                    this.setState({ navTabValue: value })
                  }}
                >
                  <NavBarItem label={t('labels.shares')} value={0} />
                  <NavBarItem label={t('labels.returns')} value={1} />
                  <NavBarItem label={t('labels.history')} value={2} />
                  <NavBarItem label={t('labels.settings')} value={3} />
                </NavBar>
              </Grid>

              <Grid item xs={12} hidden={navTabValue !== 0}>
                <SharesTabContent
                  exchangeData={exchangeData}
                  selectedAddress={selectedAddress}
                  ethPrice={ethPrice}
                  watchedAddresses={watchedAddresses}
                  ownershipData={ownershipData}
                  ownershipDataIsLoading={ownershipDataIsLoading}
                />
              </Grid>

              <Grid item xs={12} hidden={navTabValue !== 1}>
                <ReturnsTabContent
                  exchangeData={exchangeData}
                  ethPrice={ethPrice}
                  ownershipDataSources={ownershipDataSources}
                  returnTypeTabValue={returnTypeTabValue}
                  handleReturnTypeTabChange={this.handleReturnTypeTabChange}
                  addDefaultSrc={this.addDefaultSrc}
                  getStringForRowValue={this.getStringForRowValue}
                />
              </Grid>

              <Grid item xs={12} hidden={navTabValue !== 2}>
                <HistoryTabContent
                  historyDataSources={historyDataSources}
                  exchangeData={exchangeData}
                />
              </Grid>

              <Grid item xs={12} hidden={navTabValue !== 3}>
                <SettingsTabContent />
              </Grid>
            </>
          )}
        </Grid>
      </React.Fragment>
    )
  }
}

const Account = props => (
  <OnboardConsumer>
    {({ address }) => (
      <ExchangePriceConsumer>
        {({ exchangeData, exchangeDataLoading, ethPrice }) => (
          <AccountConsumer>
            {({ watchedAddresses, ownershipDataIsLoading, ownershipData }) => (
              <AccountBase
                {...props}
                selectedAddress={address}
                exchangeData={exchangeData}
                exchangeDataIsLoading={exchangeDataLoading}
                ethPrice={ethPrice}
                watchedAddresses={watchedAddresses}
                ownershipDataIsLoading={ownershipDataIsLoading}
                ownershipData={ownershipData}
              />
            )}
          </AccountConsumer>
        )}
      </ExchangePriceConsumer>
    )}
  </OnboardConsumer>
)

export default withTranslation()(withRouter(withStyles(styles)(Account)))
