import React, { Component, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'

// React Table
import ReactTable, { ReactTableDefaults } from 'react-table'
import 'react-table/react-table.css'

// Components
import { Caption } from './Typography'
import { Typography } from '@material-ui/core'

// Style
import styles from '../shared/styles'
import { makeStyles } from '@material-ui/core/styles'

// i18n
import { withTranslation } from 'react-i18next'
import { LocalizedLink } from '../../contexts/LocaleContext'

// Helpers
import { isNil } from 'ramda'

const pageSize = 10
const useStyles = makeStyles(styles)

const OriginalTrComponent = ReactTableDefaults.TrComponent

ReactTableDefaults.TrComponent = ({ rowHref, ...props }) => {
  const href = rowHref && rowHref()

  return href ? (
    <LocalizedLink to={href}>
      <OriginalTrComponent {...props} />
    </LocalizedLink>
  ) : (
    <OriginalTrComponent {...props} />
  )
}
class Table extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data || [],
      columns: props.columns || [],
      loading: isNil(props.loading) ? true : props.loading,
      totalRows: (props.data && props.data.length) || 0,
      pageIndex: 0,
      pages: -1,
      sorted: props.defaultSorted,
    }
  }

  componentDidMount() {
    const { data } = this.state
    const { dataSource } = this.props

    if (data.length === 0 && dataSource !== null) {
      this.fetchData()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.dataSource !== prevProps.dataSource) {
      this.fetchData()
    }

    if (this.props.data !== prevProps.data) {
      this.setState({ data: this.props.data })
    }

    if (this.props.columns !== prevProps.columns) {
      this.setState({ columns: this.props.columns })
    }

    if (this.props.loading !== prevProps.loading) {
      this.setState({ loading: this.props.loading })
    }
  }

  fetchData() {
    const { dataSource, controlled } = this.props
    if (!dataSource) return

    this.setState({ loading: true })

    // Server side pagination and sorting
    if (controlled) {
      const { pageIndex, sorted } = this.state
      const { id: sortedColumnId, desc: sortedColumnDesc } = sorted[0]
      let controlledDataSource = dataSource

      controlledDataSource += `&limit=${pageSize}&offset=${pageIndex *
        pageSize}&orderBy=${sortedColumnId}&direction=${
        sortedColumnDesc ? 'desc' : 'asc'
      }`

      fetch(controlledDataSource)
        .then(r => r.json())
        .then(data => {
          if (!data || !data.query) return

          const {
            query: { totalRows, limit },
            results,
          } = data

          this.setState({
            data: results,
            loading: false,
            pages: Math.ceil(totalRows / limit),
          })
        })
    } else {
      // When given an array of data sources
      if (Array.isArray(dataSource)) {
        Promise.all(dataSource.map(source => fetch(source)))
          .then(responses => Promise.all(responses.map(res => res.json())))
          .then(dataArray => {
            this.setState({
              data: dataArray.flat(),
              loading: false,
            })
          })
      } else {
        // Default behavior
        fetch(dataSource)
          .then(r => r.json())
          .then(data => {
            if (!data || !data.results) return

            this.setState({
              data: data.results,
              loading: false,
            })
          })
      }
    }
  }

  onPageChange = pageIndex => {
    this.setState({ pageIndex }, () => {
      this.fetchData()
    })
  }

  onSortedChange = (newSorted, column, shiftKey) => {
    this.setState({ sorted: newSorted }, () => {
      this.fetchData()
    })
  }

  render() {
    const {
      controlled,
      defaultSorted,
      searchQuery,
      t,
      className,
      rowHref,
      dataFilter,
      ...props
    } = this.props
    const { columns, loading, pageIndex, pages, sorted } = this.state

    let data = this.state.data

    // Apply custom filter
    if (dataFilter) {
      data = data.filter(row => dataFilter(row))
    }

    // Search query filter
    searchQuery &&
      searchQuery.forEach(searchQuery => {
        if (searchQuery.object && searchQuery.column) {
          // filter data
          data = data.filter(row => {
            if (searchQuery.object.length === 0) return true
            else if (searchQuery.object.length > 0)
              return searchQuery.object.includes(row[searchQuery.column])
            return row[searchQuery.column] === searchQuery.object
          })
        }
      })

    return (
      <ReactTable
        data={data}
        columns={columns}
        manual={controlled}
        showPageSizeOptions={false}
        defaultPageSize={10}
        defaultSorted={controlled ? null : defaultSorted}
        defaultSortDesc={true}
        resizable={false}
        className={`-highlight ${className}`}
        loading={loading}
        previousText={t('common.previous')}
        nextText={t('common.next')}
        loadingText={`${t('common.loading')}...`}
        noDataText={t('common.no_rows_found')}
        pageText={t('common.page')}
        ofText={t('common.of')}
        rowsText={t('common.rows')}
        multiSort={false}
        getTrProps={(_, rowInfo) => ({
          ...(rowHref && {
            rowHref: () => rowHref(rowInfo.original),
          }),
        })}
        {...(controlled && {
          page: pageIndex,
          pages: pages,
          sorted: sorted,
          onPageChange: this.onPageChange,
          onSortedChange: this.onSortedChange,
        })}
        {...props}
      />
    )
  }
}

// **************** Table Components **************** //
export const StickyRow = ({ tableBodyRef, children }) => {
  // Sticky Row
  const stickyRowRef = useRef()
  useEffect(() => {
    stickyRowRef.current = document.createElement('div')
    stickyRowRef.current.className = 'rt-tr-group '
  }, [])

  // Mount Sticky Row inside table
  useEffect(() => {
    if (!tableBodyRef) return
    tableBodyRef.insertBefore(stickyRowRef.current, tableBodyRef.firstChild)

    // Unmount Sticky Row
    return () => {
      stickyRowRef.current.remove()
    }
  }, [tableBodyRef])

  return stickyRowRef.current
    ? createPortal(
        <div className="rt-tr sticky" role="row">
          {children}
        </div>,
        stickyRowRef.current,
      )
    : null
}

// **************** Table Typography **************** //

export const Header = ({ children }) => {
  const classes = useStyles()
  return <Caption className={classes.tableHeader}>{children}</Caption>
}

export const CellText = ({ children, align, style }) => {
  const classes = useStyles()

  return (
    <Typography
      className={classes.cellText}
      variant="body1"
      align={align}
      style={style}
    >
      {children}
    </Typography>
  )
}

export default withTranslation()(Table)
