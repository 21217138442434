import Notify from 'bnc-notify'

const NETWORK_ID = 1
const API_KEY = 'e4964ac8-ac9f-4d1b-a38a-dcd3dd2e3ddf'

export function initNotify() {
  return Notify({
    dappId: API_KEY,
    networkId: NETWORK_ID,
    mobilePosition: 'bottom',
  })
}
