import React from 'react'

// Components
import Table, { Header, CellText } from '../../shared/Table'
import {
  Subtitle,
  SectionHeader,
  Caption,
  Body2Text,
} from '../../shared/Typography'
import PoolInfoWidget from '../../shared/PoolInfoWidget'

// Material UI
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

// Icons
import Etherscan from '../../../images/etherscan-logo-circle.png'

// Helpers
import {
  etherscanTxUrl,
  mapAccountActions,
  format,
  usdFormat,
} from '../../../helpers'
import { isNil } from 'ramda'

// i18n
import { useTranslation } from 'react-i18next'

// Styles
import styles from '../../shared/styles'
import { makeStyles } from '@material-ui/core'

// Moment
var moment = require('moment')

const useStyles = makeStyles(styles)

const History = ({ historyDataSources, exchangeData }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Grid item className={classes.subsequentSectionPadding}>
        <SectionHeader>{t('account.transactions')}</SectionHeader>
        <Subtitle gutterBottom>{t('common.refresh_rate_15m')}</Subtitle>
      </Grid>

      <Table
        dataSource={historyDataSources}
        columns={[
          {
            Header: <Header>{t('labels.pool')}</Header>,
            accessor: 'exchange',
            minWidth: 200,
            sortable: false,
            Cell: row => {
              const exchange = row.value
              const filteredExchange =
                exchangeData &&
                exchangeData.find(row => row.exchange === exchange)

              return (
                <PoolInfoWidget
                  name={filteredExchange && filteredExchange.poolName}
                  platform={filteredExchange && filteredExchange.platform}
                  assets={filteredExchange && filteredExchange.assets}
                />
              )
            },
          },
          {
            Header: <Header>{t('labels.action')}</Header>,
            accessor: 'timestamp',
            Cell: row => {
              const formattedTime = moment
                .utc(row.value)
                .format('YYYY-MM-DD HH:mm:ss')

              const formattedAction = mapAccountActions(row.original.action, t)
              return (
                <>
                  <Body2Text>{formattedAction}</Body2Text>
                  <Caption lowEmphasis align="right">
                    {formattedTime}
                  </Caption>
                </>
              )
            },
          },
          {
            Header: <Header>{t('labels.ownership_shares')}</Header>,
            accessor: 'amount',
            Cell: row => {
              return (
                <CellText align="right" style={{ lineHeight: '40px' }}>
                  {`${format(row.value)}`}
                </CellText>
              )
            },
          },
          {
            Header: <Header>{t('labels.value')}</Header>,
            accessor: 'usd_value',
            sortMethod: (a, b) => {
              return Math.abs(a) > Math.abs(b) ? 1 : -1
            },
            Cell: row => {
              const usdValue = Math.abs(row.value)
              const ethAmount = Math.abs(row.original.eth_amount)
              const tokenAmount = Math.abs(row.original.token_amount)
              const exchange = row.original.exchange
              const filteredExchange =
                exchangeData &&
                exchangeData.filter(row => row.exchange === exchange)
              const tokenSymbol =
                filteredExchange &&
                filteredExchange[0] &&
                filteredExchange[0].tokenSymbol

              return (
                <>
                  <Body2Text align="right">
                    {!isNil(usdValue) ? (
                      `${usdFormat(usdValue, 2)}`
                    ) : (
                      <Skeleton
                        height={16}
                        width={100}
                        style={{ margin: 0, display: 'inline-block' }}
                      />
                    )}
                  </Body2Text>
                  <Caption lowEmphasis component="p" align="right">
                    {ethAmount === 0
                      ? '\u00a0'
                      : `${format(tokenAmount)} ${tokenSymbol} • ${format(
                          ethAmount,
                        )} ETH`}
                  </Caption>
                </>
              )
            },
          },
          {
            Header: <Header>↗︎</Header>,
            accessor: 'transaction',
            sortable: false,
            width: 28,
            Cell: row => {
              return (
                <a
                  href={etherscanTxUrl(row.value)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Etherscan}
                    width={18}
                    alt="Etherscan"
                    style={{ filter: 'grayscale(1)' }}
                  />
                </a>
              )
            },
          },
        ]}
        defaultSorted={[{ id: 'timestamp', desc: true }]}
      />
    </React.Fragment>
  )
}

export default History
