import React from 'react'

// Material UI
import MUIAvatar from '@material-ui/core/Avatar'
import Skeleton from '@material-ui/lab/Skeleton'

// Helpers
import { addDefaultSrc, srcForToken, srcForPlatform } from '../../helpers'

// Styles
import withStyles from '@material-ui/core/styles/withStyles'
import { H5Text } from './Typography.js'

const styles = theme => ({
  platformAvatar: {
    position: 'absolute',
    boxShadow: '0px 0px 8px rgba(0,0,0,0.08)',
    bottom: 0,
    zIndex: 1,
  },
})

const Avatar = ({
  src,
  size = 40,
  style,
  children,
  backgroundColor,
  ...props
}) => {
  return (
    <div
      style={{
        height: size,
        width: size,
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '50%',
        ...style,
      }}
    >
      <div
        {...props}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          position: 'absolute',
          zIndex: 1,
          backgroundImage: `url(${src})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }}
      />
      {!src && children && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 0,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: backgroundColor || '#bdbdbd',
            color: 'white',
            textTransform: 'uppercase',
          }}
        >
          {typeof children === 'string' ? (
            <H5Text>{children.slice(0, 1)}</H5Text>
          ) : (
            children
          )}
        </div>
      )}
    </div>
  )
}

const PoolAvatar = ({
  classes,
  src,
  loading,
  token,
  platform,
  size,
  styles,
  children,
  ...props
}) => {
  const height = size || 40
  const width = size || 40

  const avatarSrc = src || srcForToken(token)
  const platformUrl = srcForPlatform(platform)

  const borderWidth = height / 20
  const platformHeight = height / 2.5
  const platformWidth = width / 2.5
  const platformLeft = -platformWidth / 4 - borderWidth

  return (
    <div
      style={{
        height,
        overflow: 'visible',
        marginLeft: -platformLeft,
        ...(styles && { margin: styles.margin, marginLeft: styles.marginLeft }),
      }}
    >
      <div style={{ height, width, position: 'relative' }}>
        {loading ? (
          <Skeleton variant="circle" height={height} width={width} />
        ) : (
          <React.Fragment>
            <Avatar
              style={{
                height,
                width,
                border: `${borderWidth}px solid white`,
                boxShadow: '0px 0px 8px rgba(0,0,0,0.08)',
                backgroundColor: 'white',
              }}
              src={avatarSrc}
              onError={addDefaultSrc}
              children={children}
              {...props}
            />
            {platformUrl && (
              <MUIAvatar
                variant="rounded"
                style={{
                  border: `${borderWidth}px solid white`,
                  height: platformHeight,
                  width: platformWidth,
                  left: platformLeft,
                }}
                src={platformUrl}
                onError={addDefaultSrc}
                className={classes.platformAvatar}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(PoolAvatar)
