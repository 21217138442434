import React, { createContext, useState, useEffect, useCallback } from 'react'

// Contexts
import { OnboardConsumer } from './OnboardContext'

import { getOwnershipForAccount } from '../services/blocklytics'

// 3Box
// const Box = require('3box')

const localStorageBetaFeaturesKey = 'enable_beta_features'
const watchedAddressesKey = address => `${address}_wa_array`

const AccountContext = createContext()

export const AccountProviderBase = ({ children, selectedAddress }) => {
  const [betaFeaturesEnabled, setBetaFeaturesEnabledState] = useState(
    !!localStorage.getItem(localStorageBetaFeaturesKey),
  )

  // Enable/Disable beta features
  const setBetaFeaturesEnabled = useCallback(
    enabled => {
      localStorage.setItem(localStorageBetaFeaturesKey, enabled)
      setBetaFeaturesEnabledState(enabled)
    },
    [setBetaFeaturesEnabledState],
  )

  const [profile, setProfile] = useState(null)

  // 3Box Profile
  // useEffect(() => {
  //   Box.getProfile(selectedAddress).then(profile => {
  //     if (profile && profile.status !== 'error') {
  //       setProfile(profile)
  //     }
  //   })
  // }, [selectedAddress])

  // Retrieve watched addresses from localStorage
  const retrieveWatchedAddresses = useCallback(address => {
    const watchedAddresses = localStorage.getItem(watchedAddressesKey(address))

    return watchedAddresses ? watchedAddresses.split(',') : [address]
  }, [])

  const [watchedAddresses, setWatchedAddressesState] = useState(
    retrieveWatchedAddresses(selectedAddress),
  )

  // Set new watched addresses and sync with 3Box
  const setWatchedAddresses = useCallback(
    newWatchedAddresses => {
      setWatchedAddressesState(newWatchedAddresses)
      localStorage.setItem(
        watchedAddressesKey(selectedAddress),
        newWatchedAddresses,
      )
    },
    [selectedAddress],
  )

  // If address was changed update watchedAddresses array
  useEffect(() => {
    setWatchedAddressesState(retrieveWatchedAddresses(selectedAddress))
  }, [selectedAddress, retrieveWatchedAddresses])

  const [ownershipDataIsLoading, setOwnershipDataIsLoading] = useState(false)
  const [ownershipData, setOwnershipData] = useState([])
  const [ownershipUpdateTimestamp, setOwnershipUpdateTimestamp] = useState(
    +new Date(),
  )

  // Ownership shares
  const updateOwnershipShares = useCallback(async () => {
    // Clear data if user logged out
    if (!selectedAddress) {
      setOwnershipData([])
      return
    }

    if (watchedAddresses[0] === undefined) {
      // Currently, the watchedAddresses may return [undefined]
      // This is a simple fix for underlying problem in retrieveWatchedAddresses
      setOwnershipData([])
      return
    }

    setOwnershipDataIsLoading(true)
    Promise.all(
      watchedAddresses.map(address => {
        return address && getOwnershipForAccount(address)
      }),
    )
      .then(dataArray => {
        setOwnershipData(dataArray.flat())
        setOwnershipDataIsLoading(false)
      })
      .catch(e => {
        console.log('There was an error while retrieving ownership data:', e)
        // setOwnershipDataIsLoading(false)
      })
  }, [selectedAddress, watchedAddresses])

  useEffect(() => {
    updateOwnershipShares()
  }, [watchedAddresses, ownershipUpdateTimestamp, updateOwnershipShares])

  return (
    <AccountContext.Provider
      value={{
        betaFeaturesEnabled,
        setBetaFeaturesEnabled,
        selectedAddress,
        profile,
        watchedAddresses,
        setWatchedAddresses,
        ownershipDataIsLoading,
        ownershipData,
        setOwnershipUpdateTimestamp,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export const AccountProvider = props => {
  return (
    <OnboardConsumer>
      {({ address }) => (
        <AccountProviderBase
          {...props}
          selectedAddress={address}
        />
      )}
    </OnboardConsumer>
  )
}

export const AccountConsumer = AccountContext.Consumer
