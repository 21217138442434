import React, { useMemo, useEffect, useState, useCallback } from 'react'

// Components
import PoolAvatar from '../../shared/PoolAvatar'
import Table from '../../shared/Table'
import TradesChart from '../../../charts/TradesChart'

// i18n
import { useTranslation } from 'react-i18next'

// Material UI
import Button from '@material-ui/core/Button'
import { DatePicker } from '@material-ui/pickers'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'

// Select
import Select, { components } from 'react-select'

// Helpers
import { options } from '../../../shared/origination-filters'
import { getColumnsForPlatform } from '../../../shared/table-columns'

// Styles
import { makeStyles } from '@material-ui/styles'
import styles from '../../shared/styles'

// Moment
import { SectionHeader, Caption, Subtitle } from '../../shared/Typography.js'
import { format, chooseAnAddressFor, slicedAddress } from '../../../helpers'
import moment from 'moment'

const useStyles = makeStyles(styles)

const Placeholder = props => (
  <components.Placeholder {...props}>
    <Typography>{props.children}</Typography>
  </components.Placeholder>
)

const LoadingMessage = props => {
  const { t } = useTranslation()

  return (
    <components.LoadingMessage {...props}>
      <Typography>{t('common.loading')}...</Typography>
    </components.LoadingMessage>
  )
}

const Option = props => (
  <components.Option {...props}>
    <Typography>{props.children}</Typography>
  </components.Option>
)

const AssetSelector = ({ assets: propAssets, onChange = () => {} }) => {
  const [selectedAsset, setSelectedAsset] = useState(null)
  const [assets, setAssets] = useState(propAssets || [])

  useEffect(() => {
    setAssets(propAssets || [])
  }, [propAssets])

  useEffect(() => {
    const address = assets[0] && assets[0].address
    setSelectedAsset(address)
    onChange(address)
  }, [assets, onChange])

  const onClick = useCallback(
    event => {
      const address = event.target.value
      setSelectedAsset(address)
      onChange(address)
    },
    [onChange],
  )

  return (
    <Grid container justify="space-evenly">
      {assets.map((asset, idx) => (
        <Grid item key={idx}>
          <FormControlLabel
            onClick={onClick}
            checked={selectedAsset === asset.address}
            value={asset.address}
            control={<Radio />}
            label={asset.symbol}
          />
        </Grid>
      ))}
    </Grid>
  )
}

const MultiSelectOriginationFilter = props => {
  const { handleOriginationFilterChange, originationFilter } = props
  const { t } = useTranslation()

  const value = useMemo(() => {
    return options.filter(
      option =>
        originationFilter &&
        originationFilter.find(filter => filter === option.value),
    )
  }, [originationFilter])

  return (
    <Select
      components={{
        Placeholder: Placeholder,
        LoadingMessage: LoadingMessage,
        Option: Option,
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary25: '#f9f9f9', // Background for option hover
          primary50: '#e9e9e9', // Background for option focus (click)
          primary: '#e9e9e9', // Container outline and background for currently selected option
        },
      })}
      styles={{
        indicatorsContainer: () => ({ display: 'none' }),
        control: (base, state) => ({
          ...base,
          '&:hover': {
            border: '1px solid rgba(0, 0, 0, 0.87)',
          },
          '&:focus': {
            border: '2px solid #e91e63',
          },
          border: '1px solid rgba(0, 0, 0, 0.23)',
          cursor: 'pointer',
          height: 56,
          boxShadow: 'none',
          transition: 'background-color 250ms, border 250ms',
        }),
        multiValue: (base, state) => ({
          ...base,
          '&:focus': {
            border: '2px solid #e91e63',
          },
        }),
        multiValueContainer: (base, state) => ({
          ...base,
          '&:focus': {
            border: '2px solid #e91e63',
          },
        }),
        valueContainer: (base, state) => ({
          ...base,
          height: 44,
          padding: '5px 15px',
          '&:focus': {
            border: '2px solid #e91e63',
          },
        }),
      }}
      placeholder={`${t('common.select')}...`}
      onChange={option => handleOriginationFilterChange(option)}
      options={options}
      value={value}
      isMulti
      name="originationPlatformFilter"
      className="multi-select-filter"
    />
  )
}

const Trades = ({
  exchange,
  tokenDetails,
  selectedFromDate,
  selectedToDate,
  tradesDataSource,
  originationFilter,
  accountFilterQuery,
  hoverDataId,
  filtersApplied,
  showClearButton,
  handleDateFromChange,
  handleDateToChange,
  handleTraderFilterChange,
  clearAccountIdFilterData,
  handleOriginationFilterChange,
  setHoverData,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [assetFilterAddress, setAssetFilterAddress] = useState(null)

  const platform = tokenDetails && tokenDetails.platform.toLowerCase()
  const columns = getColumnsForPlatform(
    platform,
    tokenDetails,
    assetFilterAddress,
    handleTraderFilterChange,
    classes,
    t,
  )

  const isFilterDisplayed = platform === 'uniswap'

  const tableFilter = useMemo(() => {
    return assetFilterAddress
      ? row =>
          row.fromToken === assetFilterAddress ||
          row.toToken === assetFilterAddress
      : null
  }, [assetFilterAddress])

  return (
    <Grid
      container
      spacing={4}
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      className={classes.subsequentSectionPadding}
    >
      <Grid item xs={12} style={{ width: '100%' }}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="space-between"
          alignItems="flex-end"
        >
          <Grid item>
            <Grid container direction="row">
              <SectionHeader>{t('labels.trades')}</SectionHeader>
              <PoolAvatar
                size={32}
                styles={{
                  display: 'inline-block',
                }}
                token={tokenDetails && chooseAnAddressFor(tokenDetails.assets)}
                platform={tokenDetails && tokenDetails.platform}
              />
            </Grid>
          </Grid>

          <Grid item>
            <DatePicker
              id="selectedFromDate"
              inputVariant="outlined"
              label={t('trades.from_date_utc')}
              format="YYYY-MM-DD"
              value={selectedFromDate}
              maxDate={selectedToDate}
              onChange={handleDateFromChange}
              autoOk={true}
              disableFuture={true}
              showTodayButton={true}
              style={{ marginRight: 16, width: 160 }}
            />

            <DatePicker
              id="selectedToDate"
              inputVariant="outlined"
              label={t('trades.to_date_utc')}
              format="YYYY-MM-DD"
              value={selectedToDate}
              minDate={selectedFromDate}
              onChange={handleDateToChange}
              autoOk={true}
              disableFuture={true}
              showTodayButton={true}
              style={{ width: 160 }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
          wrap="nowrap"
        >
          {/* Chart Legend */}
          <Grid item className={classes.bubbleLegend}>
            <Hidden only="xs">
              <Grid
                container
                direction="column"
                spacing={3}
                justify="flex-start"
                alignItems="stretch"
              >
                {/* Trade Type */}
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    spacing={1}
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <Caption>{t('trades.trade_type')}</Caption>
                    </Grid>

                    <Grid item>
                      <Grid container direction="row" alignItems="center">
                        <Grid item>
                          <div className={classes.colorScaleGreen} />
                        </Grid>
                        <Grid item>
                          <Caption>{t('trades.buy_token')}</Caption>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <div className={classes.colorScaleRed} />
                        </Grid>
                        <Grid item>
                          <Caption>{t('trades.sell_token')}</Caption>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Trade Size */}
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    spacing={1}
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <Caption>{t('trades.trade_size')}</Caption>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        justify="flex-start"
                        alignItems="baseline"
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            spacing={0}
                            justify="flex-start"
                            alignItems="center"
                          >
                            <Grid item>
                              <div className={classes.sizeScaleBig} />
                            </Grid>

                            <Grid item>
                              <Caption align="center">
                                {t('common.big')}
                              </Caption>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            spacing={0}
                            justify="flex-start"
                            alignItems="center"
                          >
                            <Grid item>
                              <div className={classes.sizeScaleSmall} />
                            </Grid>

                            <Grid item>
                              <Caption align="center">
                                {t('common.tiny')}
                              </Caption>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>

          {/* Trade Chart */}
          <Grid item style={{ flexGrow: 1 }}>
            <TradesChart
              exchange={exchange}
              fromDate={selectedFromDate.format('YYYY-MM-DD')}
              toDate={selectedToDate.format('YYYY-MM-DD')}
              dataSource={tradesDataSource}
              searchQuery={[
                { object: originationFilter, column: 'origination' },
                accountFilterQuery || {},
              ]}
              assetFilter={assetFilterAddress}
              chartOptions={{
                lang: { decimalPoint: '.', thousandsSep: ',' },
                chart: {
                  height: 300,
                  type: 'bubble',
                  zoomType: 'xy',
                  resetZoomButton: {
                    theme: {
                      fill: 'white',
                      stroke: 'rgba(0, 0, 0, 0.38)',
                      r: 2,
                    },
                  },
                  panning: true,
                  panKey: 'shift',
                  spacingTop: 28,
                  spacingRight: 18,
                },
                title: { text: null },
                legend: { enabled: false },
                xAxis: {
                  lineWidth: 2,
                  lineColor: '#d9d9d9',
                  tickColor: '#d9d9d9',
                  className: 'MuiTypography-root MuiTypography-caption',
                  type: 'datetime',
                  dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%b %e',
                    week: '%b %e',
                    month: "%b '%y",
                    year: '%Y',
                  },
                },
                yAxis: {
                  className: 'MuiTypography-root MuiTypography-caption',
                  lineColor: '#d9d9d9',
                  tickColor: '#d9d9d9',
                  title: {
                    text: `${t('labels.price')}`,
                    align: 'high',
                    rotation: 0,
                    offset: 0,
                    y: -16,
                    x: 7,
                  },
                  labels: {
                    x: 8,
                  },
                  opposite: true,
                },
                credits: {
                  text: 'Source: Pools.fyi',
                  position: { align: 'left', x: 8, y: -4 },
                  href: null,
                  className:
                    'MuiTypography-root MuiTypography-caption lowEmphasis',
                  style: {
                    cursor: 'default',
                  },
                },
                tooltip: {
                  useHTML: true,
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  footerFormat: '</table>',
                  formatter: function() {
                    const {
                      x,
                      point: {
                        action,
                        fromAmount,
                        fromSymbol,
                        toAmount,
                        toSymbol,
                        trader,
                      },
                    } = this

                    const date = moment(x).utc()
                    const header = `
                    <table>
                      <tr>
                        <td colspan="2"><b>${t(
                          `labels.${action.toLowerCase()}`,
                        ).toUpperCase()}</b></td>
                      </tr>
                    `
                    const body = `
                      <tr>
                        <td><b>${t('common.from')}:</b></td>
                        <td align='right'>${format(
                          fromAmount,
                          3,
                        )} ${fromSymbol}</td>
                      </tr>
                      <tr>
                        <td><b>${t('common.to')}:</b></td>
                        <td align='right'>${format(
                          toAmount,
                          3,
                        )} ${toSymbol}</td>
                      </tr>
                      <tr>
                        <td><b>${t('labels.price')}:</b></td>
                        <td align='right'>${format(this.y, 6)}</td>
                      </tr>
                      <tr>
                        <td><b>${t('labels.date')}:</b></td>
                        <td align='right'>${date.format('MMM D, YYYY')}</td>
                      </tr>
                      <tr>
                        <td><b>${t('labels.timestamp')}:</b></td>
                        <td align='right'>${x}</td>
                      </tr>
                      <tr>
                        <td><b>${t('labels.trader')}:</b></td>
                        <td align='right'>${slicedAddress(trader)}</td>
                      </tr>
                    `
                    return [header, body]
                  },
                },
                plotOptions: {
                  loading: {
                    labelStyle: {
                      cursor: 'default',
                      fontSize: '1rem',
                      color: 'rgba(0, 0, 0, 0.54)',
                      fontWeight: '400',
                    },
                    style: { backgroundColor: 'transparent' },
                  },
                  series: {
                    point: {
                      events: {
                        click: function(event) {
                          setHoverData.call(this, event)
                          this.series && this.series.chart.tooltip.hide()
                        },
                        // Not being called when leving the point
                        // mouseOut: function(event) {
                        //   this.series.chart.tooltip.hide()
                        // },
                      },
                    },
                  },
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container>
          <AssetSelector
            assets={tokenDetails && tokenDetails.assets}
            onChange={setAssetFilterAddress}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.firstSectionPadding}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={9}>
            <SectionHeader>{t('trades.trades_table')}</SectionHeader>
            <Subtitle gutterBottom>
              {filtersApplied}{' '}
              {showClearButton ? (
                <Button
                  onClick={clearAccountIdFilterData}
                  color="primary"
                  disableRipple
                  size="small"
                >
                  {t('common.clear')}
                </Button>
              ) : null}
            </Subtitle>
          </Grid>

          {isFilterDisplayed && (
            <Grid item xs={12} sm={3} style={{ position: 'relative' }}>
              <FormLabel
                className="MuiFormLabel-root MuiInputLabel-outlined MuiInputLabel-shrink MuiInputLabel-formControl"
                style={{
                  zIndex: 4,
                  backgroundColor: 'white',
                  padding: '0 5px',
                }}
              >
                {t('trades.origination_platform')}
              </FormLabel>
              <MultiSelectOriginationFilter
                handleOriginationFilterChange={handleOriginationFilterChange}
                originationFilter={originationFilter}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Table
          controlled
          dataSource={tradesDataSource}
          searchQuery={[
            { object: originationFilter, column: 'origination' },
            accountFilterQuery
              ? accountFilterQuery
              : { object: hoverDataId, column: 'id' },
          ]}
          dataFilter={tableFilter}
          columns={columns}
          defaultSorted={[{ id: 'id', desc: true }]}
        />
      </Grid>
    </Grid>
  )
}

export default Trades
