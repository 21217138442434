import React from 'react'

// Style
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Body2Text } from '../shared/Typography'

const useStyles = makeStyles(theme => {
  return {
    root: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        width: 580,
      },
      [theme.breakpoints.up('md')]: {
        width: 660,
      },

      display: 'grid',
      gridTemplateColumns: '25px auto',
      gridTemplateRows: '22px auto',
      gridTemplateAreas: `'emoji title' 'blank subtitle' 'blank button'`,
      alignItems: 'center',
      color: '#e91e63',
      padding: 5,
      borderRadius: 8 / 2,
      // cursor: 'pointer',
      backgroundColor: 'transparent',
      border: '1px solid #e91e63',
      textDecoration: 'none',
      // transition:
      //   'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      // '&:hover, &:focus': {
      //   backgroundColor: 'rgba(233, 30, 99, 0.08)',
      // },
    },
    headerWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 12,
      paddingRight: 12,
      wordWrap: 'break-word',
      whiteSpace: 'normal',
    },
  }
})

const Note = ({ emoji, title, Subtitle, href }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gridArea: 'emoji',
        }}
      >
        {emoji}
      </div>
      <div style={{ gridArea: 'title' }}>
        <Body2Text style={{ fontWeight: 500 }}>{title}</Body2Text>
      </div>
      <div style={{ gridArea: 'subtitle' }}>
        <Body2Text>{Subtitle}</Body2Text>
      </div>
      {href && (
        <div style={{ gridArea: 'button' }}>
          <Button color="primary" href={href} target="_blank">More info ↗</Button>
        </div>
      )}
    </div>
  )
}

export default Note
