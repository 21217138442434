import React from 'react'

// Components
import { LocalizedLink } from '../../../../contexts/LocaleContext'

// Material UI
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// Colors
import pink from '@material-ui/core/colors/pink'

// Icons
import BlockyCool from '../../../../images/blocklytics-cool.png'

// Styles
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  logoLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:focus': {
      outline: 'none',
    },
  },
  logo: {
    height: 60,
    // width: 60,
    // paddingRight: theme.spacing(1),
  },
  logoText: {
    fontFamily: 'Roboto',
    fontWeight: 900,
    fontSize: '3rem',
    color: 'rgba(0,0,0,1)',
    letterSpacing: '-0.05625rem',
  },
  betaTag: {
    // [theme.breakpoints.down('xs')]: {
    //   display: 'none',
    // },
    background: pink['500'],
    color: 'rgba(255, 255, 255, 0.87)',
    border: '1px solid rgba(255, 255, 255, 0.87)',
    borderRadius: 2,
    height: 20,
    marginTop: -22,
    marginLeft: -9,
    width: 30,
  },
}))

const BlockyLogo = ({ style, className }) => {
  const classes = useStyles()

  return (
    <Grid item className={className} style={style} xs={6} sm>
      <LocalizedLink to="/" className={classes.logoLink}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          wrap="nowrap"
        >
          <img src={BlockyCool} className={classes.logo} alt="Blocklytics" />
          <Typography className={classes.logoText}>Pools</Typography>
          <Chip label=".fyi" className={classes.betaTag} />
        </Grid>
      </LocalizedLink>
    </Grid>
  )
}

export default BlockyLogo
