import React, { useState, useEffect } from 'react'

// Components
import { CellText } from '../shared/Table'
import { Caption } from '../shared/Typography'

// Sparklines
import { Sparklines, SparklinesLine } from 'react-sparklines'

// Material UI
import Grid from '@material-ui/core/Grid'

// Helpers
import { getReturnsV1ForExchange } from '../../services/blocklytics'

const ReturnSparkLine = ({
  exchange,
  periodTabValue,
  returnTypeTabValue,
  linkProps,
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const controller = new AbortController()
    const signal = controller.signal

    getReturnsV1ForExchange(exchange, periodTabValue, '24H', {
      cache: 'default',
      signal,
    })
      .then(data => {
        setData(data['results'])
        setLoading(false)
      })
      .catch(err => {
        if (err.name !== 'AbortError')
          console.log(`ReturnSparkLine fetch error for: ${exchange}.`)
      })

    return () => controller.abort()
  }, [exchange, periodTabValue])

  var dataKey = 'roi'
  // if (returnTypeTabValue === 0) dataKey = `D${periodTabValue}_annualized`
  // else if (returnTypeTabValue === 1)
  // dataKey = `D${periodTabValue}_net_annualized`

  const dataLastValue =
    data && data.length > 0 && data[data.length - 1][dataKey]

  return loading ? (
    <Grid item xs={12}>
      <Caption align="center" style={{ lineHeight: '40px', width: '100%' }}>
        {' '}
      </Caption>
    </Grid>
  ) : (
    /* <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      style={{ flexWrap: 'nowrap' }}
    >
    <Grid item xs={9}> */
        <Sparklines
          data={data && data.map(e => e[dataKey])}
          svgWidth={160}
          svgHeight={36}
          // min={0}
          margin={3}
          style={{ width: '100%' }}
        >
          <SparklinesLine
            color="#e91e63"
            style={{ strokeWidth: 4, fill: 'none' }}
          />
        </Sparklines>
      /* </Grid>
      <Grid item xs={3}>
        <CellText align="right" style={{ lineHeight: '40px' }}>
          {dataLastValue ? `${format(dataLastValue, 4)}` : '-'}
        </CellText>
      </Grid>
    </Grid> */
  )
}

export default ReturnSparkLine
