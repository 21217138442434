import React from 'react'

// Components
import PoolOwnership from './PoolOwnership'

const Returns = ({
  exchangeData,
  ethPrice,
  ownershipDataSources,
  returnTypeTabValue,
  handleReturnTypeTabChange,
  addDefaultSrc,
  getStringForRowValue,
}) => {
  return (
    <React.Fragment>
      <PoolOwnership
        exchangeData={exchangeData}
        returnTypeTabValue={returnTypeTabValue}
        ownershipDataSources={ownershipDataSources}
        ethPrice={ethPrice}
        handleReturnTypeTabChange={handleReturnTypeTabChange}
        addDefaultSrc={addDefaultSrc}
        getStringForRowValue={getStringForRowValue}
      />
    </React.Fragment>
  )
}

export default Returns
