import React from 'react'

// Components
import AccountWidget from './AccountWidget'
import { Subtitle, SectionHeader } from './Typography'

// Material UI
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

// Styles
import { makeStyles } from '@material-ui/styles'
import InfoTooltip from './InfoTooltip'

export const dialogStyles = theme => ({
  dialogPaper: {
    backgroundColor: '#f9f9f9',
  },
  dialogContent: {
    margin: '0 auto',
    maxWidth: 600,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  stepIndicator: {
    color: '#9e9e9e',
  },
  activeStepIndicator: {
    fontWeight: 600,
  },
  stepWrapper: {
    margin: `${theme.spacing(8)}px 0`,
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(5)}px 0`,
    },
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(3)}px 0`,
    },
  },
  stepPaper: {
    margin: `${theme.spacing(3)}px 0`,
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    // padding: `6px ${theme.spacing(2)}px 12px`,
  },
  stepPaperNoPadding: {
    margin: `${theme.spacing(3)}px 0`,
  },
  actionGroup: {
    marginTop: theme.spacing(1),
  },
  actionButton: {
    padding: '12px 18px',
  },
  txDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1.5)}px`,
  },
  txRowDivider: {
    borderBottom: '1px solid #dedede',
  },
  txRowText: {
    margin: 0,
  },
  accountBar: {
    // display: 'flex',
    // maxWidth: 1024,
    width: '100%',
    height: 100,
    margin: '0 auto',
  },
  accountDetails: {
    marginLeft: 'auto',
    marginTop: 18,
    marginRight: 8,
  },
})

const useStyles = makeStyles(dialogStyles)

export const StepIndicator = ({ index, name, currentStep }) => {
  const classes = useStyles()
  const indicatorClassName =
    currentStep === index ? classes.activeStepIndicator : classes.stepIndicator

  return (
    <React.Fragment>
      <Subtitle className={indicatorClassName} align="center">
        {index + 1}.
      </Subtitle>
      <Subtitle className={indicatorClassName} gutterBottom align="center">
        {name}
      </Subtitle>
    </React.Fragment>
  )
}

export const Step = ({
  stepIndex,
  currentStep,
  header,
  subheader,
  children,
  actions,
  noPadding,
  customLayout,
}) => {
  const classes = useStyles()

  return stepIndex !== currentStep ? null : (
    <Grid
      className={classes.stepWrapper}
      role="tabpanel"
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      {/* Step Header */}
      <Grid item>
        <SectionHeader align="center">{header}</SectionHeader>
        <Subtitle gutterBottom align="center">
          {subheader}
        </Subtitle>
      </Grid>

      {/* Step Content */}
      {children &&
        (!customLayout ? (
          <Grid item style={{ width: '100%' }}>
            <Paper
              className={
                noPadding ? classes.stepPaperNoPadding : classes.stepPaper
              }
              elevation={4}
            >
              {children}
            </Paper>
          </Grid>
        ) : (
          children
        ))}

      {/* Step Actions */}
      <Grid className={classes.actionGroup} item>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          wrap="nowrap"
        >
          {actions}
        </Grid>
      </Grid>
    </Grid>
  )
}

const FullScreenDialog = ({ open, onClose, children, cancellable = true }) => {
  const classes = useStyles()

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} fullScreen open={open}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        wrap="nowrap"
      >
        <Grid item>
          <div className={classes.accountBar}>
            <div className={classes.accountDetails}>
              <AccountWidget onClose={onClose} cancellable={cancellable} />
            </div>
          </div>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Dialog>
  )
}

export const FullScreenDialogContent = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid
      className={classes.dialogContent}
      container
      direction="column"
      justify="center"
      wrap="nowrap"
    >
      {children}
    </Grid>
  )
}

export const TxDetailsWrapper = ({ children }) => {
  return <div>{children}</div>
}

export const TxDetailRow = ({
  description,
  infoText,
  value,
  strikeValue,
  divider,
}) => {
  const classes = useStyles()

  return (
    <div className={`${classes.txDetails} ${divider && classes.txRowDivider}`}>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Subtitle className="lowEmphasis" component="span">
            {description}
          </Subtitle>
          {infoText && <InfoTooltip text={infoText} />}
        </div>
      </div>
      <Subtitle align="right" component="div">
        {strikeValue ? (
          <span style={{ textDecoration: 'line-through' }}>{strikeValue}</span>
        ) : null}{' '}
        {value}
      </Subtitle>
    </div>
  )
}

export default FullScreenDialog
