import React from 'react'

// Contexts
import { AccountConsumer } from '../../contexts/AccountContext'

// Helpers
import { slicedAddress } from '../../helpers'

// Material UI
import Grid from '@material-ui/core/Grid'

// Components
import EthereumAvatar from './EthereumAvatar'
import { Subtitle, Caption } from './Typography'

// i18n
import { useTranslation } from 'react-i18next'

// Styles
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  accountLabel: {
    fontWeight: 500,
  },
  accountAction: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 500,
  },
}))

const AccountWidget = ({ onClose, cancellable }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <AccountConsumer>
      {({ selectedAddress, profile }) => (
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          wrap="nowrap"
          spacing={1}
        >
          <Grid item>
            <Grid container direction="column" alignItems="flex-end">
              <Grid item>
                <Subtitle className={classes.accountLabel} align="right">
                  {profile && profile.name
                    ? profile.name
                    : slicedAddress(selectedAddress)}
                </Subtitle>
              </Grid>
              <Grid item style={{ marginTop: -4 }}>
                <Caption
                  className={classes.accountAction}
                  align="right"
                  onClick={onClose}
                >
                  {cancellable
                    ? t('common.cancel_and_close')
                    : t('common.close')}
                </Caption>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <EthereumAvatar
              address={selectedAddress}
              size={56}
              profile={profile}
            />
          </Grid>
        </Grid>
      )}
    </AccountConsumer>
  )
}

export default AccountWidget
