import React, { Component } from 'react'
import moment from 'moment'

// Highcharts
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'

// i18n
import { withTranslation } from 'react-i18next'

// Styles
import { withStyles } from '@material-ui/core'
import { Subtitle } from '../pages/shared/Typography'

// Services
import { getReturnsV1ForExchange } from '../services/blocklytics'
import { roiFormat } from '../helpers'

HighchartsMore(Highcharts)

const styles = theme => ({
  chartContainer: {
    position: 'relative',
  },
  noticeContainer: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  notice: {
    margin: 'auto',
    marginTop: '120px',
  },
})

const defaultChartOptions = {
  lang: { decimalPoint: '.', thousandsSep: ',' },
  chart: {
    height: 300,
    type: 'line',
    resetZoomButton: {
      theme: {
        fill: 'white',
        stroke: 'rgba(0, 0, 0, 0.38)',
        r: 2,
      },
    },
  },
  title: { text: null },
  legend: {
    enabled: false,
    align: 'right',
    layout: 'proximate',
  },
  tooltip: {
    className: 'MuiTypography-root MuiTypography-caption',
    shared: true,
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    borderColor: 'rgba(0, 0, 0, 0.25)',
    borderWidth: 1,
    shadow: false,
    useHTML: true,
    footerFormat: '</table>',
  },
  plotOptions: {
    loading: {
      labelStyle: {
        cursor: 'default',
        fontSize: '1rem',
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: '400',
      },
      style: { backgroundColor: 'transparent' },
    },
    line: {
      lineWidth: 3,
      marker: {
        enabled: false,
        symbol: 'circle',
      },
    },
    states: {
      hover: {
        lineWidthPlus: undefined,
      },
    },
  },
  xAxis: {
    className: 'MuiTypography-root MuiTypography-caption',
    lineWidth: 2,
    lineColor: '#d9d9d9',
    tickColor: '#d9d9d9',
    type: 'datetime',
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S.%L',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%b %e',
      week: '%b %e',
      month: "%b '%y",
      year: '%Y',
    },
    crosshair: true,
  },
  yAxis: {
    className: 'MuiTypography-root MuiTypography-caption',
    lineColor: '#d9d9d9',
    tickColor: '#d9d9d9',
    title: { text: null },
    labels: {
      format: '{value}',
    },
    // min: 0,
  },
  credits: {
    text: 'Source: Pools.fyi',
    position: { align: 'left', x: 8, y: -4 },
    href: null,
    className: 'MuiTypography-root MuiTypography-caption lowEmphasis',
    style: {
      cursor: 'default',
    },
  },
  // series: [{ data: [] }],
}

class ExchangesChart extends Component {
  constructor(props) {
    super(props)
    this.chart = React.createRef()

    this.state = {
      // To avoid unnecessary update keep all options in the state.
      // period: props.period,
      chartOptions: defaultChartOptions,
      data: props.data || [],
      isLoading: false,
      emptyData: false,
    }
  }

  componentDidMount() {
    this.updateExchanges()
  }

  componentDidUpdate = prevProps => {
    const { exchanges, periods, returnType } = this.props

    if (JSON.stringify(exchanges) !== JSON.stringify(prevProps.exchanges)) {
      this.updateExchanges()
    } else if (periods !== prevProps.periods) {
      this.updateExchanges()
    } else if (returnType !== prevProps.returnType) {
      this.updateExchanges()
    }
  }

  processData(exchange) {
    const { data } = this.state
    const { periods, freq, returnType, annualized = true } = this.props

    const results = []
    let emptyData = true
    data.forEach(row => {
      const x = new Date(row.timestamp).getTime()
      const y = row['roi']
    //   const balances = row['balances']

      // Flag to know if empty data
      if (emptyData && y) emptyData = false

      results.push({x, y})
    //   results.push({x, y, balances})
    })

    var series = []
    if (
      this.state.chartOptions &&
      this.state.chartOptions.series &&
      this.props.exchanges.length > 1
    ) {
      series = this.state.chartOptions.series
    }

    var pos = this.props.exchanges.findIndex(
      i => i.address === exchange.address,
    )
    if (pos === -1) return

    const seriesColor = `#${exchange.address.slice(2, 8)}`

    series.push({
      data: results,
      name: exchange.poolName ? `${exchange.poolName}` : '',
      color: seriesColor,
    })

    this.setState({
      chartOptions: {
        series,
      },
      isLoading: false,
      emptyData,
    })
  }

  fetchData(exchange) {
    const { periods, freq } = this.props

    getReturnsV1ForExchange(exchange.address, periods, freq)
      .then(data => {
        if (data && data['results']) {
          this.setState({ data: data && data['results'] })
          this.processData(exchange)
        }
      })
      .catch(e => console.log(e))
  }

  updateExchanges() {
    const { t, exchanges, periods, returnType } = this.props
    const { isLoading } = this.state

    const returnTypeName = returnType === 0 ? t('labels.pool') : 'ETH'

    if (!isLoading && exchanges.length > 0) {
      this.setState({
        emptyData: false,
        isLoading: true,
        chartOptions: {
          series: null,
          tooltip: {
            formatter: function() {
              const startDate = moment(this.points[0].series.xData[0])
              const endDate = moment(this.x)

              const header = `<table>
                <tr><td colspan="2">
                ${startDate.format('MMM D')} to ${endDate.format('MMM D, YYYY')}
                </td></tr>`

              const points = this.points.map(
                point => {
                //   const balances = point.point.balances
                //   const balanceRows = balances.map(balance => `<tr><td></td><td>${balance.amount}</td></tr>`)
                //   console.log(balanceRows)
                  return `<tr>
                  <td><span style="color:${point.color}">\u25CF</span> ${t(
                    'exchanges.historical_returns.return_type_returns',
                    { returnType: returnTypeName },
                  )}</td>
                  <td style="text-align: right"><b>${roiFormat(point.y)}</b></td>
                  </tr>`
              })

              return [header, ...points]
            },
          },
        },
      })

      exchanges.forEach(exchange => {
        this.fetchData(exchange)
      })
    }
  }

  render() {
    const { t, classes, exchanges, period } = this.props
    const { chartOptions, isLoading, emptyData } = this.state

    // Loading logic
    if (this.chart && this.chart.current && this.chart.current.chart) {
      const chartObj = this.chart.current.chart
      if (isLoading) {
        chartObj.showLoading()
      } else {
        chartObj.hideLoading()
      }
    }

    return (
      <div className={classes.chartContainer}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          ref={this.chart}
        />
        {exchanges.length === 1 && emptyData && (
          <div className={classes.noticeContainer}>
            <Subtitle className={classes.notice} component="span">
              {t('trades.not_complete_day_periods', { days: period })}
            </Subtitle>
          </div>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(ExchangesChart))
