import React, { useMemo } from 'react'
import PoolAvatar from '../../shared/PoolAvatar'
import { SectionHeader } from '../../shared/Typography'
import CopyToClipboard from '../../shared/CopyToClipboard'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Etherscan from '../../../images/etherscan-logo-circle.png'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
// import RequirementsIcon from '@material-ui/icons/AssignmentLate'
// import TradingFeeIcon from '@material-ui/icons/SwapHoriz'

// Helpers
import {
  capitalize,
  srcForPlatform,
  mapPlatformSrc,
  slicedAddress,
} from '../../../helpers'

// Styles
import { makeStyles } from '@material-ui/styles'
import styles from '../../shared/styles'

const useStyles = makeStyles(styles)

const PoolInfo = ({ exchange, tokenDetails }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const infoTabPoolDetails = useMemo(() => {
    return [
      {
        category: t('labels.protocol'),
        value:
          tokenDetails &&
          tokenDetails.platform &&
          capitalize(tokenDetails.platform),
        src: tokenDetails && srcForPlatform(tokenDetails.platform),
        href:
          tokenDetails && `https://${mapPlatformSrc(tokenDetails.platform)}`,
      },
      // {
      //   category: t('labels.trading_fees'),
      //   value: '0.3%',
      //   icon: <TradingFeeIcon />,
      // },
      // {
      //   category: t('labels.requirements'),
      //   value:
      //     'Uniswap liquidity providers must add liquidity at a specific ratio of 50% token and 50% ETH, based on the last price.',
      //   icon: <RequirementsIcon />,
      // },
      {
        category: t('labels.pool_contract'),
        value: slicedAddress(exchange),
        src: Etherscan,
        href: `https://etherscan.io/address/${exchange}`,
      },
      {
        category: t('labels.ownership_token'),
        value: tokenDetails && slicedAddress(tokenDetails.ownershipToken),
        src: Etherscan,
        href:
          tokenDetails &&
          `https://etherscan.io/token/${tokenDetails.ownershipToken ||
            tokenDetails.exchange}`,
      },
    ]
  }, [t, exchange, tokenDetails])

  const poolAssets = useMemo(() => {
    return tokenDetails
      ? tokenDetails.assets.map(asset => ({
          category: asset.name,
          value: asset.symbol,
          token: asset.address,
          href: `https://etherscan.io/token/${asset.address}`,
        }))
      : [{ icon: 'A' }] // This is a placeholder when data is still loading
  }, [tokenDetails])

  return (
    <Grid
      container
      spacing={4}
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      className={classes.subsequentSectionPadding}
    >
      {/* Pool Details */}
      <Grid item xs={12} style={{ width: '100%' }}>
        <SectionHeader>{t('trades.pool_details')}</SectionHeader>
        <Paper>
          <List>
            {infoTabPoolDetails.map(
              (row, idx) =>
                row && (
                  <ListItem key={`list-item-${idx}`}>
                    <ListItemAvatar>
                      <PoolAvatar
                        src={row.src}
                        children={row.icon || row.category}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={row.category} />
                    {row.href && (
                      <ListItemSecondaryAction>
                        <Button color="primary" href={row.href} target="_blank">
                          {row.value} ↗︎
                        </Button>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ),
            )}
          </List>
        </Paper>
      </Grid>

      {/* Token Details */}
      <Grid item xs={12}>
        <SectionHeader>{t('trades.assets')}</SectionHeader>
        <Paper>
          <List>
            {poolAssets.map(
              (row, id) =>
                row && (
                  <ListItem key={id}>
                    <ListItemAvatar>
                      <PoolAvatar
                        token={row.token}
                        children={row.icon || row.category}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={row.category}
                      secondary={row.value}
                    />
                    {row.href && (
                      <ListItemSecondaryAction>
                        <Button color="primary" href={row.href} target="_blank">
                          {slicedAddress(row.token)} ↗︎
                        </Button>
                        <CopyToClipboard>
                          {({ copy }) => (
                            <IconButton
                              color="secondary"
                              size="small"
                              onClick={e => copy(row.token)}
                            >
                              <FileCopyOutlinedIcon fontSize="inherit" />
                            </IconButton>
                          )}
                        </CopyToClipboard>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ),
            )}
          </List>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default PoolInfo
