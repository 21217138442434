import React from 'react'

// Material UI
import MUISwitch from '@material-ui/core/Switch'
import { pink } from '@material-ui/core/colors'

// Style
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider, withStyles } from '@material-ui/styles'

const switchTheme = createMuiTheme(theme => ({
  palette: {
    secondary: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
    primary: {
      main: pink['500'],
      light: '#ffffff',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  overrides: {
    MuiIconButton: {
      label: {
        color: pink[500],
      },
    },
  },
}))

const SwitchBase = withStyles(theme => ({
  switchBase: {
    thumb: {
      color: 'white',
    },
    '&$checked': {
      color: pink[500],
    },
    '&$checked + $track': {
      backgroundColor: pink[500],
      opacity: 0.5,
    },
  },
  checked: {},
  track: {},
}))(MUISwitch)

const Switch = props => {
  return (
    <ThemeProvider theme={switchTheme}>
      <SwitchBase {...props} />
    </ThemeProvider>
  )
}

export default Switch
