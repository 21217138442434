import React, { useMemo } from 'react'

// Material UI
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'

// i18n
import { useTranslation } from 'react-i18next'

// Style
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => {
  return {
    tooltip: {
      cursor: 'pointer',
    },
    chip: {
      height: 22,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      background: '#fbfbfb',
      borderRadius: '4px',
      minWidth: '64px',
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    label: {
      padding: '1px 5px',
      textTransform: 'uppercase',
      fontSize: '0.6875rem',
      letterSpacing: '0.045rem',
    },
  }
})

const Tag = ({ tag, ...props }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { label, helperText } = useMemo(() => {
    const label = t(`tags.${tag}.label`)
    const helperText = t(`tags.${tag}.helperText`)

    return { label, helperText }
  }, [t, tag])

  const Wrapper = helperText ? Tooltip : React.Fragment

  return (
    <Wrapper
      {...(helperText && {
        placement: 'bottom',
        className: classes.tooltip,
        title: helperText,
      })}
    >
      <Chip
        label={label}
        {...props}
        classes={{ root: classes.chip, label: classes.label }}
        clickable={false}
      />
    </Wrapper>
  )
}

export default Tag
