import React, { useState, useCallback, useEffect, useMemo } from 'react'

// Components
import ExchangeShares from './components/ExchangeShares'
import ActionCard from '../../../shared/ActionCard'
import AddLiquidityDialog from './components/AddLiquidityDialog'
import RemoveLiquidityDialog from './components/RemoveLiquidityDialog'
import TradeTokenDialog from './components/TradeTokenDialog'
import { SectionHeader } from '../../../shared/Typography'

// i18n
import { useTranslation } from 'react-i18next'

// Material UI
import Grid from '@material-ui/core/Grid'

// Icons
import SwapVertIcon from '@material-ui/icons/SwapVert'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import ExternalIcon from '@material-ui/icons/CallMade'

// Styles
import { makeStyles } from '@material-ui/styles'
import styles from '../../../shared/styles'

// Helpers
import { mapPlatformSrc } from '../../../../helpers'
import {
  hideTradeWidget,
  hideRemoveLiquidityWidget,
} from '../../../../shared/FeatureFlags'
import { addLiquidityMap } from '../../../../shared/add-liquidity-map'
import { swapSupportedForToken } from '../../../../shared/swap-token-map'
import { AccountConsumer } from '../../../../contexts/AccountContext'

const useStyles = makeStyles(styles)

const YourShares = ({
  selectedAddress,
  exchange,
  exchangeData,
  ethPrice,
  tokenDetails,
  betaFeaturesEnabled,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [addLiquidityDialogOpen, setAddLiquidityDialogOpen] = useState(false)
  const [removeLiquidityDialogOpen, setRemoveLiquidityDialogOpen] = useState(
    false,
  )
  const [tradeTokenDialogOpen, setTradeTokenDialogOpen] = useState(false)

  // Open and Close Add Liquidity Dialog
  const openAddLiquidityDialog = useCallback(() => {
    setAddLiquidityDialogOpen(true)
  }, [setAddLiquidityDialogOpen])

  const closeAddLiquidityDialog = useCallback(() => {
    setAddLiquidityDialogOpen(false)
  }, [setAddLiquidityDialogOpen])

  // Open and Close Remove Liquidity Dialog
  const openRemoveLiquidityDialog = useCallback(() => {
    setRemoveLiquidityDialogOpen(true)
  }, [setRemoveLiquidityDialogOpen])

  const closeRemoveLiquidityDialog = useCallback(() => {
    setRemoveLiquidityDialogOpen(false)
  }, [setRemoveLiquidityDialogOpen])

  // Open and Close Trade Token Dialog
  const openTradeTokenDialog = useCallback(() => {
    setTradeTokenDialogOpen(true)
  }, [setTradeTokenDialogOpen])

  const closeTradeTokenDialog = useCallback(() => {
    setTradeTokenDialogOpen(false)
  }, [setTradeTokenDialogOpen])

  // Close dialogs if account is changed
  useEffect(() => {
    closeAddLiquidityDialog()
    closeRemoveLiquidityDialog()
    closeTradeTokenDialog()
  }, [
    selectedAddress,
    closeAddLiquidityDialog,
    closeRemoveLiquidityDialog,
    closeTradeTokenDialog,
  ])

  const exchangeSwapSupported = useMemo(() => {
    return !!(
      !hideTradeWidget &&
      tokenDetails &&
      tokenDetails.token &&
      swapSupportedForToken(tokenDetails.token)
    )
  }, [tokenDetails])

  const removeLiquiditySupported = useMemo(() => {
    return (
      !hideTradeWidget &&
      tokenDetails &&
      tokenDetails.platform &&
      tokenDetails.platform.toLowerCase() === 'uniswap'
    )
  }, [tokenDetails])

  const platformUrl = useMemo(() => {
    const platform = tokenDetails && tokenDetails.platform
    let src = mapPlatformSrc(platform)
    if (!src) return null
    let url = src && `https://${src}`
    return url
  }, [tokenDetails])

  return (
    <Grid container className={classes.subsequentSectionPadding} spacing={4}>
      <Grid item xs={12}>
        <SectionHeader>{t('labels.your_shares')}</SectionHeader>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
        >
          <Grid item style={{ width: '100%' }}>
            <Grid container direction="row" alignItems="flex-start">
              {/* Exchange Shares */}
              <Grid item>
                <ExchangeShares
                  exchange={exchange}
                  exchangeData={exchangeData}
                  ethPrice={ethPrice}
                />
              </Grid>

              {/* Add Liquidity */}
              <Grid item>
                {addLiquidityMap[exchange] ? (
                  <ActionCard
                    background="linear-gradient(135deg, #7def99, #45db5f)"
                    actionName={t('common.add_liquidity')}
                    actionSubtitle={t('common.via_defi_zap')}
                    onClick={() => openAddLiquidityDialog()}
                    IconComponent={<AddIcon style={{ fontSize: 72 }} />}
                  />
                ) : (
                  <ActionCard
                    background="linear-gradient(135deg, #7def99, #45db5f)"
                    actionName={t('common.add_liquidity')}
                    // actionSubtitle={t('common.via_defi_zap')}
                    component="a"
                    href={platformUrl}
                    IconComponent={<ExternalIcon style={{ fontSize: 72 }} />}
                  />
                )}
              </Grid>

              {/* Remove Liquidity */}
              <Grid item>
                {removeLiquiditySupported ? (
                  <ActionCard
                    background="linear-gradient(135deg, #f8aeac, #f67c8e)"
                    actionName={t('common.remove_liquidity')}
                    actionSubtitle={t('common.via_uniswap')}
                    onClick={() => openRemoveLiquidityDialog()}
                    IconComponent={<RemoveIcon style={{ fontSize: 72 }} />}
                    showBetaTag
                  />
                ) : (
                  <ActionCard
                    background="linear-gradient(135deg, #f8aeac, #f67c8e)"
                    actionName={t('common.remove_liquidity')}
                    // actionSubtitle={t('common.via_uniswap')}
                    component="a"
                    href={platformUrl}
                    IconComponent={<ExternalIcon style={{ fontSize: 72 }} />}
                    // showBetaTag
                  />
                )}
              </Grid>

              {/* Swap Tokens */}
              <Grid item>
                {exchangeSwapSupported ? (
                  <ActionCard
                    background="linear-gradient(135deg, #8adbff, #6abae1)"
                    actionName={t('common.swap_tokens')}
                    actionSubtitle={t('common.via_dex_ag')}
                    onClick={() => openTradeTokenDialog()}
                    IconComponent={<SwapVertIcon style={{ fontSize: 72 }} />}
                    showBetaTag
                  />
                ) : (
                  <ActionCard
                    background="linear-gradient(135deg, #8adbff, #6abae1)"
                    actionName={t('common.swap_tokens')}
                    component="a"
                    href={platformUrl}
                    IconComponent={<SwapVertIcon style={{ fontSize: 72 }} />}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {addLiquidityMap[exchange] && (
        <AddLiquidityDialog
          open={addLiquidityDialogOpen}
          onClose={closeAddLiquidityDialog}
          exchange={exchange}
        />
      )}

      {!hideRemoveLiquidityWidget && (
        <RemoveLiquidityDialog
          open={removeLiquidityDialogOpen}
          onClose={closeRemoveLiquidityDialog}
          exchange={exchange}
        />
      )}

      {exchangeSwapSupported && (
        <TradeTokenDialog
          open={tradeTokenDialogOpen}
          onClose={closeTradeTokenDialog}
          exchange={exchange}
          tokenDetails={tokenDetails}
        />
      )}
    </Grid>
  )
}

const WrappedYourShares = props => (
  <AccountConsumer>
    {({ betaFeaturesEnabled }) => (
      <YourShares {...props} betaFeaturesEnabled={betaFeaturesEnabled} />
    )}
  </AccountConsumer>
)

export default WrappedYourShares
