import React, { useMemo } from 'react'
import PlatformAvatar from './PlatformAvatar'
import { Subtitle, Caption } from './Typography'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import styles from './styles'
import { makeStyles } from '@material-ui/core/styles'
import { chooseAnAddressFor } from '../../helpers'

const useStyles = makeStyles(styles)

const PlatformWidget = ({
  name,
  base,
  baseName,
  platform,
  assets = [],
  loading = false,
  wrap = false,
}) => {
  const classes = useStyles()

  const assetsSubtitle = useMemo(() => {
    return assets && assets.map(asset => asset.symbol).join(', ')
  }, [assets])

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      wrap="nowrap"
      spacing={1}
      // {...(wrap && { style: { width: 'calc(100% - 70px)' } })}
    >
      <Grid item>
        <PlatformAvatar
          loading={loading}
          alt={name || baseName}
          platform={platform && platform.toLowerCase()}
        />
      </Grid>

      <Grid
        item
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          // width: wrap ? 'calc(100% - 70px)' : '100%',
        }}
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          wrap="nowrap"
        >
          <Grid item>
            {loading ? (
              <Skeleton
                height={14}
                width={120}
                style={{
                  margin: 0,
                  marginTop: -2,
                  display: 'inline-block',
                }}
              />
            ) : (
              <Subtitle className={classes.poolInfoTitle}>
                {/* <span style={{ textTransform: 'capitalize' }}>{platform}</span>{' '} */}
                {name || baseName}
              </Subtitle>
            )}
          </Grid>

          <Grid item style={{ marginTop: -4 }}>
            {loading ? (
              <Skeleton
                height={18}
                width={100}
                style={{
                  margin: 0,
                  marginTop: 2,
                  display: 'inline-block',
                }}
              />
            ) : (
              <Caption
                className={classes.poolInfoSubtitle}
                style={{
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {assetsSubtitle}
              </Caption>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PlatformWidget
