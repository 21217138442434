import React from 'react'

// Components
import Switch from '../../../../components/Switch'
import { SectionHeader } from '../../../../shared/Typography'

// Material UI
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'

// Styles
import { makeStyles } from '@material-ui/styles'

// i18n
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  subsequentSectionPadding: {
    marginTop: 60,
  },
  formControlLabel: {
    marginLeft: 0,
  },
}))

const BetaFeatures = ({ betaFeaturesEnabled, setBetaFeaturesEnabled }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Grid item className={classes.subsequentSectionPadding}>
        <SectionHeader>{t('labels.beta_features')}</SectionHeader>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <Switch
              checked={betaFeaturesEnabled}
              onChange={e => {
                setBetaFeaturesEnabled(e.target.checked)
              }}
              color="primary"
            />
          }
          label={t('account.enable_beta_features')}
          labelPlacement="start"
        />
      </Grid>
    </React.Fragment>
  )
}

export default BetaFeatures
