import i18n from 'i18next'
// import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en from './locales/en/translation.json'
import es from './locales/es/translation.json'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en,
  es,
}

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    // ns: ['common', 'exchanges'],
    // defaultNS: 'common',

    // debug: true,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
