// https://api.opensea.io/wyvern/v1/orders?asset_contract_address=0x15b4843d34ce5eea62b3993a6fb761fd04aa9f45&token_id=2
const baseUri = 'https://api.opensea.io'
const FYI_TOKEN_ADDRESS = '0x15b4843d34ce5eea62b3993a6fb761fd04aa9f45'
const FYI_TOKEN_ID = 2

export const getPrice = (fyiToken = FYI_TOKEN_ADDRESS, id = FYI_TOKEN_ID) => {
  const endpoint = `/wyvern/v1/orders`
  const params = `?asset_contract_address=${fyiToken}&token_id=${id}`
  const url = baseUri + endpoint + params
  return simpleFetch(url)
}

const simpleFetch = url => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(response => resolve(response.json()))
      .catch(err => {
        if (err.name !== 'AbortError') console.log('Fetch failed:', err)
        reject(err)
      })
  })
}

export const getLinkToOpenSea = (nftAddress, nftId) =>
  `https://opensea.io/assets/${nftAddress}/${nftId}`

export const getAsset = (nftAddress, nftId) => {
  const endpoint = `/api/v1/asset/${nftAddress}/${nftId}`
  const url = `${baseUri}${endpoint}`

  return new Promise((resolve, reject) => {
    const headers = new Headers()
    headers.append('X-API-KEY', '6e5b91432d3545b9b7968d4eecc9281b')
    fetch(url, { method: 'GET', headers })
      .then(resp => resp.json())
      .then(data => {
        resolve(data)
      })
      .catch(e => reject(e))
  })
}
