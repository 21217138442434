import React from 'react'

// Material UI
import Grid from '@material-ui/core/Grid'

// Components
import AssetCard from '../../../shared/AssetCard'
import { SectionHeader } from '../../../shared/Typography'

// i18n
import { useTranslation } from 'react-i18next'

// Styles
import { makeStyles } from '@material-ui/styles'
import styles from '../../../shared/styles'

const useStyles = makeStyles(styles)

const YourShares = ({
  exchange,
  exchangeData,
  ethPrice,
  selectedAddress,
  watchedAddresses,
  ownershipDataIsLoading,
  ownershipData,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.subsequentSectionPadding}>
      <SectionHeader>{t('labels.your_shares')}</SectionHeader>

      <Grid
        container
        direction="row"
        alignItems="stretch"
        style={{ marginTop: 18 }}
      >
        {!ownershipDataIsLoading ? (
          !ownershipData || ownershipData.length === 0 ? (
            <AssetCard empty />
          ) : (
            ownershipData.map((row, id) => (
              <AssetCard
                key={id}
                loading={ownershipDataIsLoading}
                ethPrice={ethPrice}
                exchangeData={exchangeData}
                {...row}
              />
            ))
          )
        ) : (
          <React.Fragment>
            <AssetCard loading />
            <AssetCard loading />
          </React.Fragment>
        )}
      </Grid>
    </div>
  )
}

export default YourShares
