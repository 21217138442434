import React, { Component } from 'react'

import { FooterHeader, FooterBody } from './Typography'

// Material UI
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Toolbar from '@material-ui/core/Toolbar'
import withStyles from '@material-ui/core/styles/withStyles'

// Images
import builtOnEth from '../../images/BuiltOnEth.png'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
  footer: {
    top: 'auto',
    bottom: 0,
    marginTop: 80,
    padding: '1rem 4rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 1rem',
    },
    borderTop: '5px #f0f0f0',
    backgroundColor: 'white',
    zIndex: 12,
    // color: 'rgba(0, 0, 0, 0.72)',
  },
  divider: {
    height: 1,
    backgroundColor: '#D8D8D8',
  },
  maxWidth: {
    padding: '20px 0',
  },
})

class Footer extends Component {
  render() {
    const { classes, t } = this.props

    return (
      <React.Fragment>
        <AppBar
          position="static"
          color="default"
          className={classes.footer}
          elevation={0}
        >
          <div className={classes.divider} />

          <Toolbar className={classes.maxWidth}>
            <Grid container spacing={10}>
              <Grid item xs={12} sm={5} md={4}>
                <FooterHeader gutterBottom>
                  {t('footer.not_investment_title')}
                </FooterHeader>
                <FooterBody paragraph>
                  {t('footer.not_investment_caption1')}
                </FooterBody>
                <FooterBody paragraph>
                  {t('footer.not_investment_caption2')}
                </FooterBody>
              </Grid>

              <Grid item xs={6} sm={5} md={4}>
                <FooterHeader gutterBottom>
                  {t('footer.pools_title')}
                </FooterHeader>
                <FooterBody paragraph>{t('footer.pools_caption')}</FooterBody>

                <FooterBody paragraph>
                  {t('footer.built_by_caption2')}{' '}
                  <Link
                    href="https://p.nomics.com/cryptocurrency-bitcoin-api"
                    target="_blank"
                  >
                    {t('footer.built_by_link2')}
                  </Link>
                </FooterBody>

                <FooterBody paragraph>
                  <img src={builtOnEth} alt="Built on Ethereum" width={150} />
                </FooterBody>
              </Grid>

              <Grid item xs={6} sm={2} md={4}>
                <FooterHeader gutterBottom>
                  {t('footer.built_by_title')}
                </FooterHeader>
                <FooterBody>
                  <ul>
                    <li>
                      <Link
                        href="https://opensea.io/assets/pools-fyi-promo?sortBy=assets_prod_main"
                        target="_blank"
                      >
                        {t('footer.links.advertise_a_pool')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://docs.blocklytics.org/apis/pools-api"
                        target="_blank"
                      >
                        {t('footer.links.api_docs')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://github.com/blocklytics"
                        target="_blank"
                      >
                        {t('footer.links.github')}
                      </Link>
                    </li>
                    <li>
                      <Link href="https://blocklytics.org" target="_blank">
                        {t('footer.links.website')}
                      </Link>
                    </li>
                  </ul>
                </FooterBody>

                <FooterHeader gutterBottom>
                  {t('footer.contact_us')}
                </FooterHeader>
                <FooterBody>
                  <ul>
                    <li>
                      <Link
                        href="https://discordapp.com/invite/GFxFN3K"
                        target="_blank"
                      >
                        {t('footer.links.discord')}
                      </Link>
                    </li>
                    <li>
                      <Link href="mailto:hello@blocklytics.org" target="_blank">
                        {t('footer.links.email')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://twitter.com/blocklytics"
                        target="_blank"
                      >
                        {t('footer.links.twitter')}
                      </Link>
                    </li>
                  </ul>
                </FooterBody>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    )
  }
}

export default withTranslation()(withStyles(styles)(Footer))
