import React from 'react'

// Material UI
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import FilterListIcon from '@material-ui/icons/FilterList'
import Grid from '@material-ui/core/Grid'

// Images
import Etherscan from '../images/etherscan-logo-circle.png'

// Components
import { Header } from '../pages/shared/Table'
import EthereumAvatar from '../pages/shared/EthereumAvatar'
import { Body2Text } from '../pages/shared/Typography.js'

// Helpers
import { format, slicedAddress, etherscanTxUrl } from '../helpers'
import { SELL_COLOR, BUY_COLOR } from '../pages/shared/styles'
import moment from 'moment'
import TraderCell from '../pages/shared/TraderCell'

const getPrice = (fromAmount, fromToken, toAmount, assetFilter) => {
  return fromToken === assetFilter
    ? parseFloat(fromAmount) / parseFloat(toAmount)
    : parseFloat(toAmount) / parseFloat(fromAmount)
}

export const getColumnsForPlatform = (
  platform,
  tokenDetails,
  assetFilter,
  handleTraderFilterChange,
  classes,
  t,
) => {
  if (platform === 'curve') {
    return CurveColumns(
      tokenDetails,
      assetFilter,
      handleTraderFilterChange,
      classes,
      t,
    )
  } else if (platform === 'bancor') {
    return BancorColumns(
      tokenDetails,
      assetFilter,
      handleTraderFilterChange,
      classes,
      t,
    )
  } else if (platform === 'uniswap') {
    return UniswapColumns(
      tokenDetails,
      assetFilter,
      handleTraderFilterChange,
      classes,
      t,
    )
  } else if (platform === 'uniswap-v2') {
    return UniswapColumns(
      tokenDetails,
      assetFilter,
      handleTraderFilterChange,
      classes,
      t,
    )
  } else if (platform === 'balancer') {
    return BancorColumns(
      tokenDetails,
      assetFilter,
      handleTraderFilterChange,
      classes,
      t,
    )
  } else {
    return []
  }
}

/*
// Disabled filter on "Trader"
*/

const UniswapColumns = (
  tokenDetails,
  assetFilter,
  handleTraderFilterChange,
  classes,
  t,
) => [
  {
    Header: <Header>{t('labels.trader')}</Header>,
    accessor: 'trader',
    minWidth: 200,
    sortable: false,
    Cell: row => {
      var origination = row.original.origination
      if (origination === null) {
        origination = t('labels.unknown_contract')
      } else if (origination === 'Uniswap') {
        origination = null
      }

      return (
        <TraderCell
          address={row.value}
          subtitle={origination ? `via ${origination}` : '\u00a0'}
          showCopy
        />
      )
    },
  },
  {
    Header: <Header>{t('labels.timestamp_utc')}</Header>,
    accessor: 'id',
    minWidth: 120,
    sortable: true,
    Cell: row => {
      const formattedTime = moment
        .utc(row.original.timestamp)
        .format('YYYY-MM-DD HH:mm:ss')
      return (
        <React.Fragment>
          <Body2Text align="right">{formattedTime}</Body2Text>
        </React.Fragment>
      )
    },
  },
  {
    Header: <Header>{t('common.from')}</Header>,
    accessor: 'fromAmount',
    Cell: row => {
      const symbol = row.original.fromSymbol

      return (
        <Body2Text align="right">
          {`${format(row.value, 4)} ${symbol}`}
        </Body2Text>
      )
    },
  },
  {
    Header: <Header>{t('common.to')}</Header>,
    accessor: 'toAmount',
    Cell: row => {
      const symbol = row.original.toSymbol

      return (
        <Body2Text align="right">
          {`${format(row.value, 4)} ${symbol}`}
        </Body2Text>
      )
    },
  },
  {
    Header: <Header>{t('labels.price')}</Header>,
    accessor: 'price',
    Cell: row => {
      const { fromAmount, fromToken, toAmount } = row.original
      return (
        <Body2Text align="right">
          {format(getPrice(fromAmount, fromToken, toAmount, assetFilter), 4)}
        </Body2Text>
      )
    },
  },
  {
    Header: <Header>↗︎</Header>,
    accessor: 'transaction',
    sortable: false,
    width: 28,
    Cell: row => {
      return (
        <a
          href={etherscanTxUrl(row.value)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={Etherscan}
            width={18}
            alt="Etherscan"
            style={{ filter: 'grayscale(1)' }}
          />
        </a>
      )
    },
  },
]

/* Differences to UniswapColumns:
//   - Remove column "Action", "Slippage"
//   - Rename columns "From", "To", "Underlying Price"
//   - Disable sort on "From" and "To"
//   - Disable filter on "Trader"
*/
const CurveColumns = (
  tokenDetails,
  assetFilter,
  handleTraderFilterChange,
  classes,
  t,
) => [
  {
    Header: <Header>{t('labels.trader')}</Header>,
    accessor: 'trader',
    minWidth: 200,
    sortable: false,
    Cell: row => {
      var origination = row.original.origination
      if (origination === null) {
        origination = t('labels.unknown_contract')
      } else if (origination === 'Uniswap') {
        origination = null
      }

      return (
        <TraderCell
          address={row.value}
          subtitle={origination ? `via ${origination}` : '\u00a0'}
          showCopy
        />
      )
    },
  },
  {
    Header: <Header>{t('labels.timestamp_utc')}</Header>,
    accessor: 'id',
    minWidth: 120,
    sortable: true,
    Cell: row => {
      const formattedTime = moment
        .utc(row.original.timestamp)
        .format('YYYY-MM-DD HH:mm:ss')
      return (
        <React.Fragment>
          <Body2Text align="right">{formattedTime}</Body2Text>
        </React.Fragment>
      )
    },
  },
  {
    Header: <Header>{t('common.from')}</Header>,
    accessor: 'quantity',
    sortable: false,
    Cell: row => {
      const symbol = row.original.fromSymbol || 'Quote'

      return (
        <Body2Text align="right">
          {`${format(row.original.fromAmount, 3)} ${symbol}`}
        </Body2Text>
      )
    },
  },
  {
    Header: <Header>{t('common.to')}</Header>,
    accessor: 'volume',
    sortable: false,
    Cell: row => {
      const symbol = row.original.toSymbol || 'Base'

      return (
        <Body2Text align="right">
          {`${format(row.original.toAmount, 3)} ${symbol}`}
        </Body2Text>
      )
    },
  },
  {
    Header: <Header>{t('labels.price')}</Header>,
    accessor: 'price',
    sortable: true,
    Cell: row => {
      const { fromAmount, fromToken, toAmount } = row.original
      return (
        <Body2Text align="right">
          {format(getPrice(fromAmount, fromToken, toAmount, assetFilter), 4)}
        </Body2Text>
      )
    },
  },
  {
    Header: <Header>↗︎</Header>,
    accessor: 'transaction',
    sortable: false,
    width: 28,
    Cell: row => {
      return (
        <a
          href={etherscanTxUrl(row.value)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={Etherscan}
            width={18}
            alt="Etherscan"
            style={{ filter: 'grayscale(1)' }}
          />
        </a>
      )
    },
  },
]

/* Differences to UniswapColumns:
//   - Disable sort on "Price", "Slippage"
//   - Disable filter on "Trader"
*/
const BancorColumns = (
  tokenDetails,
  assetFilter,
  handleTraderFilterChange,
  classes,
  t,
) => [
  {
    Header: <Header>{t('labels.trader')}</Header>,
    accessor: 'trader',
    minWidth: 200,
    sortable: false,
    Cell: row => {
      var origination = row.original.origination
      if (origination === null) {
        origination = t('labels.unknown_contract')
      } else if (origination === 'Uniswap') {
        origination = null
      }

      return (
        <TraderCell
          address={row.value}
          subtitle={origination ? `via ${origination}` : '\u00a0'}
          showCopy
        />
      )
    },
  },
  {
    Header: <Header>{t('labels.timestamp_utc')}</Header>,
    accessor: 'id',
    minWidth: 120,
    sortable: true,
    Cell: row => {
      const formattedTime = moment
        .utc(row.original.timestamp)
        .format('YYYY-MM-DD HH:mm:ss')
      return (
        <React.Fragment>
          <Body2Text align="right">{formattedTime}</Body2Text>
        </React.Fragment>
      )
    },
  },
  {
    Header: <Header>{t('common.from')}</Header>,
    accessor: 'fromAmount',
    sortable: true,
    Cell: row => {
      const symbol = row.original.fromSymbol

      return (
        <Body2Text align="right">
          {`${format(row.value, 4)} ${symbol}`}
        </Body2Text>
      )
    },
  },
  {
    Header: <Header>{t('common.to')}</Header>,
    accessor: 'toAmount',
    sortable: true,
    Cell: row => {
      const symbol = row.original.toSymbol

      return (
        <Body2Text align="right">
          {`${format(row.value, 4)} ${symbol}`}
        </Body2Text>
      )
    },
  },
  {
    Header: <Header>{t('labels.price')}</Header>,
    accessor: 'price',
    sortable: false,
    Cell: row => {
      const { fromAmount, fromToken, toAmount } = row.original
      return (
        <Body2Text align="right">
          {format(getPrice(fromAmount, fromToken, toAmount, assetFilter), 4)}
        </Body2Text>
      )
    },
  },
  {
    Header: <Header>↗︎</Header>,
    accessor: 'transaction',
    sortable: false,
    width: 28,
    Cell: row => {
      return (
        <a
          href={etherscanTxUrl(row.value)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={Etherscan}
            width={18}
            alt="Etherscan"
            style={{ filter: 'grayscale(1)' }}
          />
        </a>
      )
    },
  },
]
