import React from 'react'

// Components
import { LoginLink } from '../../shared/LoginButton'
import { Title, Subtitle } from '../../shared/Typography'

// Material UI
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

// i18n
import { useTranslation } from 'react-i18next'

// Styles
import styles from '../../shared/styles'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(styles)

const InfoBanner = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid item xs={12}>
      <Paper className={classes.paperInGrid}>
        <Title align="center" paragraph>
          {t('exchanges.find_pools')}
        </Title>
        <Subtitle align="center">
          <LoginLink />
        </Subtitle>
      </Paper>
    </Grid>
  )
}

export default InfoBanner
