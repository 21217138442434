import React, { useCallback, useState } from 'react'

import Chip from '@material-ui/core/Chip'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/styles'

import { useTranslation } from 'react-i18next'

import { slicedAddress, isValidAddress } from '../../../helpers'

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
    overflowY: 'hidden',
  },
  addressTextField: {
    marginTop: 0,
    width: 380,
  },
}))

const WatchChip = ({ addButton, address, onDelete, openAddDialog }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return addButton ? (
    <Chip
      className={classes.chip}
      icon={<AddIcon />}
      label={t('account.add_account')}
      clickable
      onClick={openAddDialog}
      variant="outlined"
    />
  ) : (
    <Chip
      className={classes.chip}
      label={slicedAddress(address)}
      onDelete={
        // Prevent deletion of selectedAddress
        onDelete &&
        (() => {
          onDelete(address)
        })
      }
      variant="outlined"
    />
  )
}

const AddAddressDialog = ({ open, onClose, addWatchedAddress }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [address, setAddress] = useState('')
  const [validAddress, setValidAddress] = useState(true)

  const setNewAddress = useCallback(
    address => {
      setAddress(address)
      setValidAddress(isValidAddress(address))
    },
    [setAddress, setValidAddress],
  )

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('account.add_account')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-description">
          {t('account.add_account_subtitle')}
        </DialogContentText>
        <TextField
          InputProps={{
            className: classes.addressTextField,
          }}
          label={t('account.add_account_placeholder')}
          value={address}
          onChange={e => setNewAddress(e.target.value)}
          helperText={!validAddress && t('account.invalid_address')}
          fullWidth
          error={!validAddress}
          autoFocus
          onKeyUp={e => {
            e.preventDefault()
            if (e.keyCode === 13 && validAddress) {
              addWatchedAddress(address)
              onClose()
              setAddress('')
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
            setAddress('')
          }}
          color="primary"
        >
          {t('common.cancel')}
        </Button>
        <Button
          onClick={() => {
            addWatchedAddress(address)
            onClose()
            setAddress('')
          }}
          color="primary"
          disabled={!address || !validAddress}
        >
          {t('common.add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const AddressWatcher = ({
  selectedAddress,
  watchedAddresses,
  setWatchedAddresses,
}) => {
  const [addAddressDialogOpen, setAddAddressDialogOpen] = useState(false)

  const removeWatchedAddress = useCallback(
    address => {
      setWatchedAddresses(
        watchedAddresses &&
          watchedAddresses.filter(watchedAddress => watchedAddress !== address),
      )
    },
    [watchedAddresses, setWatchedAddresses],
  )

  const addWatchedAddress = useCallback(
    address => {
      const alreadyWatched =
        watchedAddresses &&
        watchedAddresses.find(
          watchedAddress =>
            watchedAddress.toLowerCase() === address.toLowerCase(),
        )

      if (!alreadyWatched) setWatchedAddresses([...watchedAddresses, address])
    },
    [watchedAddresses, setWatchedAddresses],
  )

  return (
    <React.Fragment>
      {watchedAddresses &&
        watchedAddresses.map((address, idx) => (
          <WatchChip
            key={`chip-${idx}`}
            address={address}
            onDelete={address !== selectedAddress ? removeWatchedAddress : null}
          />
        ))}
      <WatchChip
        addButton
        openAddDialog={() => setAddAddressDialogOpen(true)}
      />
      <AddAddressDialog
        open={addAddressDialogOpen}
        onClose={() => setAddAddressDialogOpen(false)}
        addWatchedAddress={addWatchedAddress}
      />
    </React.Fragment>
  )
}

export default AddressWatcher
