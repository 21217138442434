import React, { useMemo, useRef, useEffect, useCallback, useState } from 'react'
import PlatformSelect from './PlatformSelect.js'
import Table, { Header, CellText, StickyRow } from '../../shared/Table'
import ReturnSparkLine from '../../components/ReturnSparkline'
import { LocalizedLink } from '../../../contexts/LocaleContext'
import {
  SectionHeader,
  Caption,
  Subtitle,
} from '../../shared/Typography'
import PoolInfoWidget from '../../shared/PoolInfoWidget'
import PlatformWidget from '../../shared/PlatformWidget'
import Chip from '@material-ui/core/Chip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import { pink } from '@material-ui/core/colors'
import Radio from '@material-ui/core/Radio'
import GavelIcon from '@material-ui/icons/Gavel'
import HuobiFull from '../../../images/huobi-2-full.jpeg'
import HuobiMobile from '../../../images/huobi-2-small.jpeg'
import { useTranslation } from 'react-i18next'
import { roiFormat, usdFormat, chooseAssetFor, tagsFor } from '../../../helpers'
import { sponsoredRow as sponsoredRowFlag } from '../../../shared/FeatureFlags'
import { getExchange } from '../../../services/blocklytics'
import { logAnalyticsEvent } from '../../../services/analytics'
import { makeStyles } from '@material-ui/styles'
import styles from '../../shared/styles'
import PoolAvatar from '../../shared/PoolAvatar'
import Tag from '../../shared/Tag.js'
import { getAsset, getLinkToOpenSea } from '../../../services/opensea.js'

const useStyles = makeStyles(styles)

const ASSET_ADDRESS = '0x15b4843d34ce5eea62b3993a6fb761fd04aa9f45'
const ASSET_TOKEN_ID = 15

const TAGS = ['any', 'stable', 'incentivized', 'goods', 'new']

const TagOption = ({ value, label, onClick, checked }) => (
  <Grid item style={{ marginRight: 4 }}>
    <FormControlLabel
      value={value}
      label={label}
      onClick={onClick}
      checked={checked}
      control={<Radio />}
    />
  </Grid>
)

const TagSelector = ({ onChange = () => {} }) => {
  const { t } = useTranslation()

  const [selectedTag, setSelectedTag] = useState('any')

  const onClick = useCallback(
    event => {
      const tag = event.target.value
      setSelectedTag(tag)
      onChange(tag)
    },
    [onChange],
  )

  return (
    <Grid container direction="row" alignItems="center">
      {/* <div style={{ marginRight: 12 }}>
        <H5Text>{t('labels.filter_by_tag')}:</H5Text>
      </div> */}

      {TAGS.map((tag, idx) => (
        <TagOption
          key={idx}
          value={tag}
          label={t(`tags.${tag}.label`)}
          onClick={onClick}
          checked={selectedTag === tag}
        />
      ))}
    </Grid>
  )
}

const SponsoredRow = ({
  biddingOpen,
  exchange,
  // exchangeData,
  // exchangeDataIsLoading,
  periodTabValue,
  returnTypeTabValue,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [filteredExchange, setFilteredExchange] = useState(null)

  useEffect(() => {
    getExchange(exchange).then(data => {
      if (data !== undefined) setFilteredExchange(data)
    })
  }, [exchange])

  const linkToOpenSea = getLinkToOpenSea(ASSET_ADDRESS, ASSET_TOKEN_ID)

  const linkProps = useMemo(() => {
    if (!exchange)
      return {
        href: linkToOpenSea,
        target: '_blank',
        rel: 'noopener noreferrer',
        onClick: logAnalyticsEvent(
          'promoted_pool',
          'opensea_ad_click',
          ASSET_TOKEN_ID,
        ),
        style: {
          display: 'contents',
          textDecoration: 'none',
          color: 'inherit',
        },
      }
    else return { 
      to: `/returns/${exchange}`,
      onClick: logAnalyticsEvent(
        'promoted_pool',
        'exchange_ad_click',
        exchange,
      ),
    }
  }, [exchange, linkToOpenSea])

  const [price, setPrice] = useState(null)
  useEffect(() => {
    getAsset(ASSET_ADDRESS, ASSET_TOKEN_ID).then(asset => {
      const orders = asset.orders
      const order = orders[0]
      // NOTE: This line displayed the lasst offering
      // const order = orders[orders.length - 1]
      order && setPrice(order.current_price)
    })
  }, [])

  const priceString = useMemo(() => {
    if (!price) return null

    const floatPrice = parseFloat(price) / 10 ** 18
    const number = 10 ** 4
    const numPrice = Math.ceil(floatPrice * number) / number
    return `${numPrice} ETH`
  }, [price])

  const tags = useMemo(() => {
    return filteredExchange && filteredExchange.assets
      ? tagsFor(filteredExchange)
      : []
  }, [filteredExchange])

  return (
    <div className={classes.sponsoredRow}>
      {biddingOpen ? (
        <a {...linkProps}>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ height: '100%' }}
          >
            <div
              className="rt-td"
              role="gridcell"
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '40 0 auto',
                width: 40,
                maxWidth: 40,
                alignItems: 'center',
              }}
            >
              <Chip label="AD" className={classes.adChip} />
            </div>

            <div
              className="rt-td"
              role="gridcell"
              style={{ flex: '400 0 auto', maxWidth: 800 }}
            >
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ flexWrap: 'nowrap' }}
                spacing={1}
              >
                <Grid item>
                  <PoolAvatar backgroundColor={pink[500]}>
                    <GavelIcon />
                  </PoolAvatar>
                </Grid>

                <Grid
                  item
                  style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}
                >
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                    wrap="nowrap"
                  >
                    <Grid item>
                      <Subtitle className={classes.poolInfoTitle}>
                        {t('labels.sponsored_listing_available')}
                      </Subtitle>
                    </Grid>

                    <Grid item style={{ marginTop: -4 }}>
                      <a
                        href={linkToOpenSea}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                          logAnalyticsEvent(
                            'promoted_pool',
                            'opensea_link_click',
                            'no_pool',
                          )
                        }
                        style={{
                          textDecoration: 'none',
                        }}
                      >
                        <Caption className={classes.poolInfoSubtitle}>
                          {t('labels.opensea_bid_now')}{' '}
                          {priceString
                            ? `${t('labels.for_price', {
                                price: priceString,
                              })} `
                            : ''}
                          ↗
                        </Caption>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <div
              className="rt-td"
              role="gridcell"
              style={{
                flex: '160 0 auto',
                width: 160,
                maxWidth: 160,
                margin: '-4px 17px -10px',
              }}
            >
              <a
                href={linkToOpenSea}
                title="Buy on OpenSea"
                target="_blank"
                onClick={() =>
                  logAnalyticsEvent(
                    'promoted_pool',
                    'opensea_banner_click',
                    'no_pool',
                  )
                }
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    height: 45,
                    borderRadius: 5,
                  }}
                  src="https://storage.googleapis.com/opensea-static/opensea-brand/buy-button-blue.png"
                  alt="Buy on OpenSea badge"
                />
              </a>
            </div>
          </Grid>
        </a>
      ) : (
        <LocalizedLink {...linkProps}>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ height: '100%' }}
          >
            <div
              className="rt-td"
              role="gridcell"
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '40 0 auto',
                width: 40,
                maxWidth: 40,
                alignItems: 'center',
              }}
            >
              <Chip label="AD" className={classes.adChip} />
            </div>

            <div
              className="rt-td"
              role="gridcell"
              style={{ flex: '200 0 auto', width: 200 }}
            >
              <PlatformWidget
                name={filteredExchange && filteredExchange.poolName}
                platform={filteredExchange && filteredExchange.platform}
                assets={filteredExchange && filteredExchange.assets}
              />
            </div>

            <div
              className="rt-td"
              role="gridcell"
              style={{
                flex: '750 0 auto',
                width: 175,
                maxWidth: 175,
              }}
            >
              <Grid container direction="row" wrap="wrap">
                {tags && tags.map((tag, idx) => <Tag key={idx} tag={tag} />)}
              </Grid>
            </div>

            <div
              className="rt-td"
              role="gridcell"
              style={{
                flex: '110 0 auto',
                width: 110,
                maxWidth: 110,
              }}
            >
              <CellText align="right" style={{ lineHeight: '40px' }}>
                {!filteredExchange || !filteredExchange.usdLiquidity
                  ? '-'
                  : `${usdFormat(filteredExchange.usdLiquidity)}`}
              </CellText>
            </div>

            <div
              className="rt-td"
              role="gridcell"
              style={{
                flex: '110 0 auto',
                width: 110,
                maxWidth: 110,
              }}
            >
              <CellText align="right" style={{ lineHeight: '40px' }}>
                {!filteredExchange || !filteredExchange.usdVolume
                  ? '-'
                  : `${usdFormat(filteredExchange.usdVolume)}`}
              </CellText>
            </div>

            <div
              className="rt-td"
              role="gridcell"
              style={{
                flex: '210 0 auto',
                width: 210,
                maxWidth: 210,
              }}
            >
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={8}>
                  <ReturnSparkLine
                    exchange={exchange}
                    periodTabValue={periodTabValue}
                    returnTypeTabValue={returnTypeTabValue}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CellText align="right" style={{ lineHeight: '40px' }}>
                    {!filteredExchange || !filteredExchange.roi
                      ? '-'
                      : `${roiFormat(filteredExchange.roi)}`}
                  </CellText>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </LocalizedLink>
      )}
    </div>
  )
}

const HuobiAd = ({}) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Chip label="AD" className={classes.adChip} />
      <a 
        href="https://support.hbfile.net/hc/en-us/articles/900002783686" //"https://www.huobi.com/en-us/topic/welcome-bonus/?inviter_id=11330240" 
        target="_blank"
        onClick={() =>
          logAnalyticsEvent(
            'promoted_pool',
            'ad_banner_click',
            'no_pool',
          )
        }
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <Grid item>
            <img 
              alt="Huobi Welcome Bonus"
              src={HuobiFull} 
            />
          </Grid>
        </Grid>
      </a>
  </React.Fragment>
  )
}

const PoolsTable = ({
  periodTabValue,
  returnTypeTabValue,
  includeEmptyPools,
  platform,
  exchangeData,
  exchangeDataIsLoading,
  tagFilter = null,
}) => {
  const { t } = useTranslation()

  const dataSource = useMemo(() => {
    let source =
      'https://api.blocklytics.org/pools/v1/exchanges?key=AIzaSyDn7LYCJuJK6fM37nEpwVTsXdQ5eok0wQk'
    if (!includeEmptyPools) source += '&excludeEmpty=true'
    
    if (platform) source += `&platform=${platform}`
    else source += `&platform=uniswap,uniswap-v2,curve,balancer,mooniswap,sushiswap`

    if (tagFilter && tagFilter !== 'any') source += `&tags=${tagFilter}`

    return source
  }, [includeEmptyPools, platform, tagFilter])

  const tableBodyRef = useRef()
  useEffect(() => {
    if (!sponsoredRowFlag) return
    tableBodyRef.current = document.querySelector('.pools-table .rt-tbody')
  }, [])

  return (
    <React.Fragment>
      <Table
        className="pools-table"
        dataSource={dataSource}
        rowHref={row => `/returns/${row.exchange}`}
        controlled
        columns={[
          {
            id: 'row',
            accessor: d => d.index,
            width: 40,
            sortable: false,
            Cell: ({ viewIndex, pageSize, page, ...row }) => {
              const rank = page * pageSize + viewIndex + 1

              return (
                <CellText align="center" style={{ lineHeight: '40px' }}>
                  {rank}
                </CellText>
              )
            },
          },
          {
            id: 'row0',
            Header: <Header>{t('labels.pool')}</Header>,
            accessor: 'tokenSymbol',
            minWidth: 200,
            sortable: false,
            Cell: row => {
              const token = chooseAssetFor(row.original.assets)

              return (
                <PlatformWidget
                  token={token.address}
                  name={row.original.poolName}
                  platform={row.original.platform}
                  assets={row.original.assets}
                />
              )
            },
          },
          {
            // Header: <Header>{t('labels.tags')}</Header>,
            width: 175,
            accessor: 'exchange',
            sortable: false,
            Cell: row => {
              const tags = tagsFor(row.original)

              return (
                <Grid container direction="row" wrap="wrap">
                  {tags && tags.map((tag, idx) => <Tag key={idx} tag={tag} />)}
                </Grid>
              )
            },
          },
          {
            Header: <Header>{t('labels.liquidity')}</Header>,
            accessor: 'usdLiquidity',
            width: 110,
            Cell: row => {
              const usdLiquidity = row.value

              return (
                <CellText align="right" style={{ lineHeight: '40px' }}>
                  {!usdLiquidity ? '-' : `${usdFormat(usdLiquidity)}`}
                </CellText>
              )
            },
          },
          {
            Header: <Header>{t('labels.24h_volume')}</Header>,
            accessor: 'usdVolume',
            width: 110,
            Cell: row => {
              const usdVolume = row.value

              return (
                <CellText align="right" style={{ lineHeight: '40px' }}>
                  {!usdVolume ? '-' : `${usdFormat(usdVolume)}`}
                </CellText>
              )
            },
          },
          {
            Header: <Header>{t('labels.pool_roi')}</Header>,
            accessor: 'roi',
            width: 210,
            Cell: row => {
              const roi = row.value

              return (
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={8}>
                    <ReturnSparkLine
                      exchange={row.original.exchange}
                      periodTabValue={periodTabValue}
                      returnTypeTabValue={returnTypeTabValue}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CellText align="right" style={{ lineHeight: '40px' }}>
                      {!roi ? '-' : `${roiFormat(roi)}`}
                    </CellText>
                  </Grid>
                </Grid>
              )
            },
          },
        ]}
        defaultSorted={[{ id: 'usdLiquidity', desc: true }]}
      />

      {sponsoredRowFlag && (
        <StickyRow tableBodyRef={tableBodyRef.current}>
          <SponsoredRow
            exchange="0x1c608235e6a946403f2a048a38550befe41e1b85"
            // biddingOpen
            exchangeData={exchangeData}
            exchangeDataIsLoading={exchangeDataIsLoading}
            periodTabValue={periodTabValue}
            returnTypeTabValue={returnTypeTabValue}
          />
        </StickyRow>
      )}
    </React.Fragment>
  )
}

const AllPools = ({
  returnTypeTabValue,
  handleReturnTypeTabChange,
  periodTabValue,
  handlePeriodTabChange,
  includeEmptyPools,
  exchangeData,
  exchangeDataIsLoading,
  setIncludeEmptyPools,
  platform,
  handlePlatformChange,
  tag: tagFilter,
  handleTagFilterChange: setTagFilter,
  // exchangeDataSearchQuery,
  // handleExchangeDataSearch,
  // exchangeDataFiltered,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const setPlatformHandler = useCallback(
    e => {
      handlePlatformChange(e.target.value)
    },
    [handlePlatformChange],
  )

  return (
    <React.Fragment>
      {/* Header */}
      <Grid item xs={12}>
        <Grid
          container
          className={classes.subsequentSectionPadding}
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm>
            <SectionHeader>
              {t('exchanges.all_pools_v2', { numberOfRows: 10 })}
            </SectionHeader>
          </Grid>

          <Grid item>
            <PlatformSelect
              value={platform || ''}
              onChange={setPlatformHandler}
            />
          </Grid>
        </Grid>

        <Grid item>
          <TagSelector onChange={setTagFilter} />
        </Grid>
      </Grid>

      {/* Ad */}
      {/* <Grid item xs={12}>
        <HuobiAd />
      </Grid> */}

      {/* Control Tabs */}
      {/* <Grid item xs={12}>
        <ControlTabs
          returnTypeTabValue={returnTypeTabValue}
          handleReturnTypeTabChange={handleReturnTypeTabChange}
          periodTabValue={periodTabValue}
          handlePeriodTabChange={handlePeriodTabChange}
        />
      </Grid> */}

      {/* Search Bar */}
      {/* <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="flex-start"
          wrap="nowrap"
        >
          <Grid item style={{ flexGrow: 1 }}>
            <Typography variant="caption" className="lowEmphasis">
              {t('common.filter')}
            </Typography>
            <form noValidate autoComplete="off">
              <TextField
                id="search-pools"
                placeholder={t('exchanges.search_pools')}
                variant="outlined"
                value={exchangeDataSearchQuery}
                onChange={handleExchangeDataSearch}
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              direction="column"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <Typography variant="caption" className="lowEmphasis">
                  {t('exchanges.show_empty_pools')}
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  className={classes.emptyPoolsSwitch}
                  checked={includeEmptyPools}
                  onChange={e => {
                    setIncludeEmptyPools(e.target.checked)
                  }}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}

      {/* Table */}
      <Grid item xs={12}>
        <PoolsTable
          periodTabValue={periodTabValue}
          returnTypeTabValue={returnTypeTabValue}
          includeEmptyPools={includeEmptyPools}
          exchangeData={exchangeData}
          exchangeDataIsLoading={exchangeDataIsLoading}
          platform={platform}
          tagFilter={tagFilter}
        />
      </Grid>
    </React.Fragment>
  )
}

export default AllPools
