// React
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Contexts
import { ExchangePriceConsumer } from '../../contexts/ExchangePriceContext'

// Components
import AllPools from './components/AllPools'
import InfoBanner from './components/InfoBanner'
import SubscribeBanner from './components/SubscribeBanner'
import ThreePanelPrimer from './components/ThreePanelPrimer'

// Helpers
import qs from 'query-string'

// Material UI
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'

// Styles
import styles from '../shared/styles'

// i18n
import { withTranslation } from 'react-i18next'

// Analytics
import { logAnalyticsPageview } from '../../services/analytics'

class ExchangesBase extends Component {
  constructor(props) {
    // Google Analytics pageview
    logAnalyticsPageview('/home', 'Home')

    super(props)

    const { platform, tag } = qs.parse(props.location.search)

    this.state = {
      exchangeDataSearchQuery: '',
      periodTabValue: 30,
      returnTypeTabValue: 1,
      strategyTabValue: 0,
      platform: platform || null,
      tag: tag || null,
    }
  }

  componentDidMount() {
    this.updateStateFromLocation()
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props

    if (prevProps.location.search !== location.search)
      this.updateStateFromLocation()
  }

  updateStateFromLocation = () => {
    const { exchangeDataSearchQuery } = this.state
    const { period, strategy, search, platform, tag } = qs.parse(
      this.props.location.search,
    )

    const validPeriod = ['7', '30', '90'].find(p => p === period)
      ? period
      : null

    this.setState({
      ...(validPeriod && { periodTabValue: parseInt(period) }),
      ...(strategy && { strategyTabValue: parseInt(strategy) }),
      ...(!exchangeDataSearchQuery && { exchangeDataSearchQuery: search }),
      ...{ platform },
      ...{ tag },
    })
  }

  pushNewSearchParam = (key, value) => {
    const { location, history } = this.props

    const urlState = {
      pathname: location.pathname,
      search: qs.stringify({
        ...qs.parse(location.search),
        [key]: value,
      }),
    }

    history.push(urlState)
  }

  handleExchangeDataSearch = event => {
    const searchTerm = event.target.value
    // Removed to avoid input lag
    // TODO: Implement using a timeout
    // this.pushNewSearchParam('search', searchTerm)
    this.setState({ exchangeDataSearchQuery: searchTerm })
  }

  handlePeriodTabChange = (event, newValue) => {
    this.pushNewSearchParam('period', newValue)
  }

  handleReturnTypeTabChange = (event, newValue) => {
    this.setState({
      returnTypeTabValue: newValue,
    })
  }

  handleStrategyTabChange = (newValue, oldValue) => {
    this.pushNewSearchParam('strategy', newValue)
  }

  handlePlatformChange = platform => {
    this.pushNewSearchParam('platform', platform)
  }

  handleTagFilterChange = tag => {
    this.pushNewSearchParam('tag', tag)
  }

  addDefaultSrc(ev) {
    ev.target.remove()
  }

  render() {
    const {
      classes,
      exchangeData,
      exchangeDataIsLoading,
      includeEmptyPools,
      setIncludeEmptyPools,
      t,
    } = this.props

    var {
      exchangeDataSearchQuery,
      periodTabValue,
      returnTypeTabValue,
      strategyTabValue,
      platform,
      tag,
    } = this.state

    var exchangeDataFiltered
    // Filter by search term
    if (exchangeDataSearchQuery && exchangeData) {
      exchangeDataFiltered = exchangeData.filter(row => {
        if (
          row.tokenSymbol === null ||
          row.tokenName === null ||
          row.token === null
        ) {
          return false
        }
        return (
          String(row.tokenSymbol)
            .toLowerCase()
            .includes(exchangeDataSearchQuery.toLowerCase()) ||
          String(row.tokenName)
            .toLowerCase()
            .includes(exchangeDataSearchQuery.toLowerCase()) ||
          String(row.token)
            .toLowerCase()
            .includes(exchangeDataSearchQuery.toLowerCase())
        )
      })
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Pools | {t('exchanges.find_pools')}</title>
          <meta
            property="og:title"
            content={`Pools | ${t('exchanges.find_pools')}`}
          />
          <meta
            name="twitter:title"
            content={`Pools | ${t('exchanges.find_pools')}`}
          />
        </Helmet>
        <Grid
          container
          spacing={4}
          className={classes.root}
        >
          {/* <InfoBanner /> */}
          <AllPools
            returnTypeTabValue={returnTypeTabValue}
            handleReturnTypeTabChange={this.handleReturnTypeTabChange}
            periodTabValue={periodTabValue}
            handlePeriodTabChange={this.handlePeriodTabChange}
            exchangeDataSearchQuery={exchangeDataSearchQuery}
            handleExchangeDataSearch={this.handleExchangeDataSearch}
            exchangeDataFiltered={exchangeDataFiltered}
            exchangeData={exchangeDataFiltered || exchangeData}
            exchangeDataIsLoading={exchangeDataIsLoading}
            includeEmptyPools={includeEmptyPools}
            setIncludeEmptyPools={setIncludeEmptyPools}
            platform={platform}
            handlePlatformChange={this.handlePlatformChange}
            tag={tag}
            handleTagFilterChange={this.handleTagFilterChange}
          />
          <ThreePanelPrimer />
          <SubscribeBanner />
        </Grid>
      </React.Fragment>
    )
  }
}

const Exchanges = props => (
  <ExchangePriceConsumer>
    {({
      exchangeData,
      exchangeDataLoading,
      includeEmptyPools,
      setIncludeEmptyPools,
    }) => (
      <ExchangesBase
        {...props}
        exchangeData={exchangeData}
        exchangeDataIsLoading={exchangeDataLoading}
        includeEmptyPools={includeEmptyPools}
        setIncludeEmptyPools={setIncludeEmptyPools}
      />
    )}
  </ExchangePriceConsumer>
)

export default withTranslation()(withRouter(withStyles(styles)(Exchanges)))
