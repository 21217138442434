// React
import React from 'react'

// Routes
import Routes from '../../routes'

// Components
import Drawer from '../shared/Drawer'
import Footer from '../shared/Footer'

// Material UI
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'

const MAX_WIDTH = 1024

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      maxWidth: MAX_WIDTH,
    },
    maxWidth: MAX_WIDTH,
    margin: '0 auto',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
  },
  toolbar: {
    minHeight: 92,
    [theme.breakpoints.down('sm')]: {
      minHeight: 64 * 2,
    },
  },
}))

const MainLayout = ({ children }) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <Drawer />

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Routes />
        </main>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default MainLayout
