export const logAnalyticsEvent = (
  eventCategory,
  eventAction,
  eventLabel,
  customDimensions = {},
  nonInteractive = false,
  eventValue = 0,
) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'event',
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    eventValue: eventValue,
    nonInteractive: nonInteractive,
    customDimensions: customDimensions,
  })
}

export const logAnalyticsPageview = (
  pagePath,
  pageTitle,
  customDimensions = {},
) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'pageview',
    pagePath: pagePath,
    pageTitle: pageTitle,
    customDimensions: customDimensions,
  })
}
