import React, { useMemo } from 'react'

// Material UI
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core'

// Components
import { Subtitle } from '../../shared/Typography'
import PoolAvatar from '../../shared/PoolAvatar'

import styles from '../../shared/styles'
import { formatPercentage } from '../../../helpers'

const useAppStyles = makeStyles(styles)

const useStyles = makeStyles(theme => ({
  poolTokenWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  poolTokensWrapper: {
    marginLeft: -3,
    marginTop: 2,
  },
}))

const PoolToken = ({ name, symbol, address, weight }) => {
  const classes = useAppStyles()
  const innerClasses = useStyles()

  return (
    <div className={innerClasses.poolTokenWrapper}>
      <PoolAvatar alt={name} token={address} size={24} />
      <Subtitle style={{ whiteSpace: 'nowrap', marginLeft: 4 }}>
        {address !== '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee' ? (
          <a
            className={classes.linkStyle}
            href={`https://etherscan.io/token/${address}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {symbol}
          </a>
        ) : (
          symbol
        )}
        {weight && ` (${formatPercentage(weight)})`}
      </Subtitle>
    </div>
  )
}

const PoolTokens = ({ token }) => {
  const classes = useStyles()
  const loading = !token

  const assets = useMemo(() => {
    return !token ? [] : token.assets
  }, [token])

  return !loading ? (
    <Grid className={classes.poolTokensWrapper} container>
      {assets.map((asset, idx) => (
        <PoolToken key={idx} {...asset} />
      ))}
    </Grid>
  ) : (
    <Grid className={classes.poolTokensWrapper} container>
      <Skeleton variant="circle" height={24} width={24} />
      <Skeleton height={20} width={45} style={{ margin: 4 }} />
      <Skeleton variant="circle" height={24} width={24} />
      <Skeleton height={20} width={45} style={{ margin: 4 }} />
    </Grid>
  )
}

export default PoolTokens
