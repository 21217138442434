import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { NotificationConsumer } from '../../../../../contexts/NotificationContext'
import { OnboardConsumer } from '../../../../../contexts/OnboardContext'
import { AccountConsumer } from '../../../../../contexts/AccountContext'

import DeFiZapArtifact from '../../../../../abi/defi-zap-abi.json'
import {
  estRedemptionValues,
  etherscanTxUrl,
  titleForPool,
  slicedAddress,
} from '../../../../../helpers'

// Components
import FullScreenDialog, {
  dialogStyles,
  TxDetailsWrapper,
  TxDetailRow,
  StepIndicator,
  Step,
  FullScreenDialogContent,
} from '../../../../shared/FullScreenDialog'
import CurrencyTextField from './shared/CurrencyTextField'
import { Subtitle } from '../../../../shared/Typography'

// Web3
import Web3 from 'web3'

// i18n
import { useTranslation } from 'react-i18next'

// Material UI
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

// Helpers
import { addLiquidityMap } from '../../../../../shared/add-liquidity-map'
import { getExchange } from '../../../../../services/blocklytics'

// Styles
import { makeStyles } from '@material-ui/styles'
import styles from '../../../../shared/styles'

// Analytics
import { logAnalyticsEvent } from '../../../../../services/analytics'

const useStyles = makeStyles(dialogStyles)
const useSharedStyles = makeStyles(styles)

const Steps = ({
  exchange,
  onClose,
  currentStep,
  setCurrentStep,
  depositAmount,
  setDepositAmount,
  web3,
  notify,
  balance,
  setOwnershipUpdateTimestamp,
  transactionHash,
  setTransactionHash,
}) => {
  const classes = useStyles()
  const sharedClasses = useSharedStyles()
  const { t } = useTranslation()

  const [validAmount, setValidAmount] = useState(false)
  const [exchangeData, setExchangeData] = useState(null)

  var BN = Web3.utils.BN

  const isValidAmount = useCallback(amount => {
    if (
      /^([0-9]+(?:[.][0-9]{0,18})?|\.[0-9]{0,18})$/.test(amount) &&
      parseFloat(amount) > 0
    )
      return true
    return false
  }, [])

  const onDepositAmountChange = useCallback(
    event => {
      const input = event.target.value

      // Update amount
      setDepositAmount(input)

      // Check for necessary items
      if (!balance) {
        setValidAmount(false)
        return
      }

      // Confirm input <= balance
      const cmpValue = parseFloat(input)

      let numerator = balance
      let denominator = new BN('10').pow(new BN('18'))
      const cmpBalance = parseFloat(numerator / denominator)

      setValidAmount(isValidAmount(input) && cmpValue <= cmpBalance)
    },
    [isValidAmount, setDepositAmount, setValidAmount, balance, BN],
  )

  const setMaxDepositAmount = useCallback(() => {
    // Check for necessary items
    if (!balance) {
      setValidAmount(false)
      return
    }

    // Update amount
    let numerator = balance
    let denominator = new BN('10').pow(new BN('18'))
    const cmpBalance = parseFloat(numerator / denominator)
    setDepositAmount(cmpBalance)

    // Confirm input <= balance
    setValidAmount(isValidAmount(cmpBalance))

    // console.log(`setMax ${balance}`)
  }, [setDepositAmount, balance, isValidAmount, BN])

  const handleDeposit = async amount => {
    /* Analytics Event
     * @param {string} eventCategory - 1st level of event heirarchy
     * @param {string} eventAction - 2nd level of event heirarchy
     * @param {string} eventLabel - 3rd level of event heirarchy
     * @param {string} [eventValue] - optional $ value of event
     */
    logAnalyticsEvent('add_liquidity', 'dialog_step_1', 'press_deposit_button')

    if (!web3) {
      /* Analytics Event
       * @param {string} eventCategory - 1st level of event heirarchy
       * @param {string} eventAction - 2nd level of event heirarchy
       * @param {string} eventLabel - 3rd level of event heirarchy
       * @param {object} customDimensions - key-value pairs of custom dimensions to send with request
       * @param {bool} [nonInteractive] - optional boolean flag to determine whether this event should impact bounce rate
       * @param {string} [eventValue] - optional $ value of event
       */
      logAnalyticsEvent('add_liquidity', 'failed', 'missing_web3')

      return
    }

    let liquidityHelper = addLiquidityMap[exchange]
    if (!liquidityHelper) {
      console.log(`No contract found for ${exchange}`)

      /* Analytics Event
       * @param {string} eventCategory - 1st level of event heirarchy
       * @param {string} eventAction - 2nd level of event heirarchy
       * @param {string} eventLabel - 3rd level of event heirarchy
       * @param {object} customDimensions - key-value pairs of custom dimensions to send with request
       * @param {bool} [nonInteractive] - optional boolean flag to determine whether this event should impact bounce rate
       * @param {string} [eventValue] - optional $ value of event
       */
      logAnalyticsEvent('add_liquidity', 'failed', 'missing_contract_details')

      return
    }

    let contract = new web3.eth.Contract(
      DeFiZapArtifact,
      liquidityHelper.contractAddress,
    )

    const txOptions = {
      from: (await web3.eth.getAccounts())[0],
      value: web3.utils.toWei(amount),
      gas: liquidityHelper.gasLimit,
      gasPrice: await web3.eth.getGasPrice(),
    }

    let response = contract.methods
      .LetsInvest()
      .send(txOptions)
      .on('transactionHash', hash => {
        notify.hash(hash)

        /* Analytics Event
         * @param {string} eventCategory - 1st level of event heirarchy
         * @param {string} eventAction - 2nd level of event heirarchy
         * @param {string} eventLabel - 3rd level of event heirarchy
         * @param {object} customDimensions - key-value pairs of custom dimensions to send with request
         * @param {bool} [nonInteractive] - optional boolean flag to determine whether this event should impact bounce rate
         * @param {string} [eventValue] - optional $ value of event
         */
        logAnalyticsEvent('add_liquidity', 'dialog_step_1', 'tx_created')

        const { emitter } = notify.hash(hash)

        emitter.on('txConfirmed', tx => {
          setOwnershipUpdateTimestamp(+Date())

          /* Analytics Event
           * @param {string} eventCategory - 1st level of event heirarchy
           * @param {string} eventAction - 2nd level of event heirarchy
           * @param {string} eventLabel - 3rd level of event heirarchy
           * @param {string} [eventValue] - optional $ value of event
           */
          logAnalyticsEvent('add_liquidity', 'success', 'tx_confirmed')
        })

        setTransactionHash(hash)
        setCurrentStep(2)
      })
  }

  useEffect(() => {
    async function updateExchangeData() {
      getExchange(exchange)
        .then(data => setExchangeData(data))
        .catch(err => console.log(err))
    }
    updateExchangeData()
  }, [exchange])

  const liquidityHelper = useMemo(() => {
    return addLiquidityMap[exchange]
  }, [exchange])

  return (
    <React.Fragment>
      {/* Step 1 */}
      <Step
        stepIndex={0}
        currentStep={currentStep}
        header={t('add_liquidity.deposit_to_add_liquidity_message')}
        subheader={
          exchangeData &&
          t('add_liquidity.depositing_eth_for_exchange_ownership', {
            exchangePool: titleForPool(exchangeData),
          })
        }
        actions={
          <React.Fragment>
            <Grid item>
              <Button
                className={classes.actionButton}
                variant="contained"
                size="large"
                onClick={() => onClose()}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  setCurrentStep(1)

                  /* Analytics Event
                   * @param {string} eventCategory - 1st level of event heirarchy
                   * @param {string} eventAction - 2nd level of event heirarchy
                   * @param {string} eventLabel - 3rd level of event heirarchy
                   * @param {string} [eventValue] - optional $ value of event
                   */
                  logAnalyticsEvent(
                    'add_liquidity',
                    'dialog_step_0',
                    'press_continue_button',
                  )
                }}
                disabled={!validAmount}
              >
                {t('common.continue')}
              </Button>
            </Grid>
          </React.Fragment>
        }
      >
        <Subtitle>{t('add_liquidity.how_much_deposit_message')}</Subtitle>
        <CurrencyTextField
          accountConnected
          tokenSymbol="ETH"
          value={depositAmount}
          balance={balance}
          balanceDecimals={new BN('18')}
          onChange={onDepositAmountChange}
          setMaxAmount={setMaxDepositAmount}
          onKeyUp={e => {
            e.preventDefault()
            if (e.keyCode === 13 && validAmount) {
              setCurrentStep(1)

              /* Analytics Event
               * @param {string} eventCategory - 1st level of event heirarchy
               * @param {string} eventAction - 2nd level of event heirarchy
               * @param {string} eventLabel - 3rd level of event heirarchy
               * @param {string} [eventValue] - optional $ value of event
               */
              logAnalyticsEvent(
                'add_liquidity',
                'dialog_step_0',
                'press_continue_button',
              )
            }
          }}
          showMax
          autoFocus
        />
      </Step>

      {/* Step 2 */}
      <Step
        stepIndex={1}
        currentStep={currentStep}
        header={t('add_liquidity.review_transaction_details')}
        subheader={
          exchangeData &&
          t('add_liquidity.depositing_eth_for_exchange_ownership', {
            exchangePool: titleForPool(exchangeData),
          })
        }
        actions={
          <React.Fragment>
            <Grid item>
              <Button
                className={classes.actionButton}
                variant="contained"
                size="large"
                onClick={() => setCurrentStep(0)}
              >
                {t('common.back')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleDeposit(depositAmount)}
              >
                {t('common.deposit')}
              </Button>
            </Grid>
          </React.Fragment>
        }
        noPadding
      >
        <TxDetailsWrapper>
          <TxDetailRow
            description={t('labels.deposit_amount')}
            value={`${depositAmount} ETH`}
            divider
          />
          <TxDetailRow
            description={t('labels.to_contract')}
            value={
              <a
                className={sharedClasses.linkStyle}
                href={`https://etherscan.io/address/${liquidityHelper.contractAddress}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {slicedAddress(liquidityHelper.contractAddress)} ↗︎
              </a>
            }
            divider
          />
          <TxDetailRow
            description={t('labels.pool')}
            value={exchangeData && titleForPool(exchangeData)}
            divider
          />
          <TxDetailRow
            description={t('labels.est_ownership')}
            infoText={t('add_liquidity.estimated_ownership_info_text')}
            value={
              exchangeData &&
              estRedemptionValues(exchangeData, depositAmount) && (
                <React.Fragment>
                  <p className={classes.txRowText}>{`${
                    estRedemptionValues(exchangeData, depositAmount)
                      .estBaseRedemptionValue
                  } ${exchangeData.baseSymbol}`}</p>
                  <p className={classes.txRowText}>{`+ ${
                    estRedemptionValues(exchangeData, depositAmount)
                      .estTokenRedemptionValue
                  } ${exchangeData.tokenSymbol}`}</p>
                </React.Fragment>
              )
            }
            divider
          />
          <TxDetailRow
            description={t('labels.convenience_fee')}
            value="0%"
            strikeValue="0.50%"
          />
        </TxDetailsWrapper>
      </Step>

      {/* Step 3 */}
      <Step
        stepIndex={2}
        currentStep={currentStep}
        header={t('labels.transaction_being_processed')}
        subheader={t('labels.transaction_estimated_time_message')}
        actions={
          <Grid
            role="tabpanel"
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={1}
            style={{ marginTop: 40, width: 160 }}
          >
            <Grid item>
              <Button
                className={classes.actionButton}
                variant="contained"
                size="large"
                fullWidth
                href={etherscanTxUrl(transactionHash)}
                target="_blank"
                disabled={!transactionHash}
              >
                {t('common.view_tx')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={() => onClose()}
              >
                {t('common.close')}
              </Button>
            </Grid>
          </Grid>
        }
      />
    </React.Fragment>
  )
}

const AddLiquidityDialog = ({
  exchange,
  open,
  onClose: onCloseProp,
  web3,
  notify,
  balance,
  setOwnershipUpdateTimestamp,
}) => {
  const { t } = useTranslation()

  const [currentStep, setCurrentStep] = useState(0)
  const [depositAmount, setDepositAmount] = useState('')
  const [transactionHash, setTransactionHash] = useState(null)

  const steps = useMemo(() => {
    return [
      t('labels.deposit_amount'),
      t('labels.review_transaction'),
      t('labels.confirmation'),
    ]
  }, [t])

  const onClose = useCallback(() => {
    onCloseProp()
    setTimeout(() => {
      setCurrentStep(0)
      setDepositAmount('')
      setTransactionHash(null)
    }, 200)
  }, [onCloseProp])

  return (
    <FullScreenDialog
      open={open}
      onClose={onClose}
      cancellable={currentStep < 2}
    >
      <FullScreenDialogContent>
        {/* Steps Wrapper */}
        <Grid item>
          <Grid container direction="row" justify="space-between" wrap="nowrap">
            {steps.map((step, idx) => {
              return (
                <Grid item key={idx}>
                  <StepIndicator
                    index={idx}
                    name={step}
                    currentStep={currentStep}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>

        {/* Steps Content */}
        <Grid item>
          <Steps
            exchange={exchange}
            onClose={onClose}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            depositAmount={depositAmount}
            setDepositAmount={setDepositAmount}
            web3={web3}
            notify={notify}
            balance={balance}
            setOwnershipUpdateTimestamp={setOwnershipUpdateTimestamp}
            transactionHash={transactionHash}
            setTransactionHash={setTransactionHash}
          />
        </Grid>
      </FullScreenDialogContent>
    </FullScreenDialog>
  )
}

const AddLiquidityDialogWrapper = props => (
  <AccountConsumer>
    {({ setOwnershipUpdateTimestamp }) => (
      <OnboardConsumer>
        {({ web3, balance }) => (
          <NotificationConsumer>
            {({ notify }) => (
              <AddLiquidityDialog
                {...props}
                notify={notify}
                web3={web3}
                balance={balance}
                setOwnershipUpdateTimestamp={setOwnershipUpdateTimestamp}
              />
            )}
          </NotificationConsumer>
        )}
      </OnboardConsumer>
    )}
  </AccountConsumer>
)

export default AddLiquidityDialogWrapper
