import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { NotificationConsumer } from '../../../../../contexts/NotificationContext.js'
import { OnboardConsumer } from '../../../../../contexts/OnboardContext.js'
import { AccountConsumer } from '../../../../../contexts/AccountContext.js'

import UniswapArtifact from '../../../../../abi/uniswap.json'
import {
  estUniswapRedemptionValues,
  etherscanTxUrl,
  format,
  titleForPool,
  slicedAddress,
} from '../../../../../helpers'

// Components
import FullScreenDialog, {
  dialogStyles,
  TxDetailsWrapper,
  TxDetailRow,
  StepIndicator,
  Step,
  FullScreenDialogContent,
} from '../../../../shared/FullScreenDialog'
import CurrencyTextField from './shared/CurrencyTextField'

// Web3
import Web3 from 'web3'

// i18n
import { useTranslation } from 'react-i18next'

// Material UI
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// Styles
import { makeStyles } from '@material-ui/styles'
import styles from '../../../../shared/styles.js'

// Analytics
import { logAnalyticsEvent } from '../../../../../services/analytics'
import { getExchange } from '../../../../../services/blocklytics'

const useStyles = makeStyles(dialogStyles)
const useSharedStyles = makeStyles(styles)

const Steps = ({
  exchange,
  onClose,
  currentStep,
  setCurrentStep,
  withdrawalAmount,
  setWithdrawalAmount,
  web3,
  notify,
  tokenBalanceOf,
  tokenDecimalsOf,
  tokenTotalSupplyOf,
  setOwnershipUpdateTimestamp,
  transactionHash,
  setTransactionHash,
}) => {
  const classes = useStyles()
  const sharedClasses = useSharedStyles()
  const { t } = useTranslation()

  const [validAmount, setValidAmount] = useState(false)
  const [exchangeData, setExchangeData] = useState(null)

  const [tokenBalance, setTokenBalance] = useState(null)
  const [tokenDecimals, setTokenDecimals] = useState(null)
  const [tokenTotalSupply, setTokenTotalSupply] = useState(null)

  const [estimatedRedemptionValues, setEstimatedRedemptionValues] = useState(
    null,
  )

  var BN = Web3.utils.BN

  const isValidAmount = useCallback(amount => {
    if (
      /^([0-9]+(?:[.][0-9]{0,18})?|\.[0-9]{0,18})$/.test(amount) &&
      parseFloat(amount) > 0
    )
      return true
    return false
  }, [])

  const onWithdrawalAmountChange = useCallback(
    event => {
      const input = event.target.value

      // Update amount
      setWithdrawalAmount(input)

      // Check for necessary items
      if (!tokenBalance || !tokenDecimals) {
        setValidAmount(false)
        return
      }

      // Confirm input <= balance
      const cmpValue = parseFloat(input)

      let numerator = tokenBalance
      let denominator = new BN('10').pow(tokenDecimals)
      const cmpBalance = parseFloat(numerator / denominator)

      setValidAmount(isValidAmount(input) && cmpValue <= cmpBalance)
    },
    [isValidAmount, setWithdrawalAmount, tokenBalance, tokenDecimals, BN],
  )

  const setMaxWithdrawalAmount = useCallback(() => {
    // Check for necessary items
    if (!tokenBalance || !tokenDecimals) {
      setValidAmount(false)
      return
    }

    // Update amount
    let numerator = tokenBalance
    let denominator = new BN('10').pow(tokenDecimals)
    const cmpBalance = parseFloat(numerator / denominator)
    // console.log("0", tokenBalance)
    // console.log("1", String(numerator))
    // console.log("2", cmpBalance)
    // console.log("3", String(cmpBalance))
    setWithdrawalAmount(cmpBalance)

    // Confirm input <= balance
    setValidAmount(isValidAmount(cmpBalance))

    // console.log(`setMax ${tokenBalance}`)
  }, [
    setWithdrawalAmount,
    setValidAmount,
    tokenBalance,
    tokenDecimals,
    isValidAmount,
    BN,
  ])

  const handleRemoveLiquidity = async amount => {
    /* Analytics Event
     * @param {string} eventCategory - 1st level of event heirarchy
     * @param {string} eventAction - 2nd level of event heirarchy
     * @param {string} eventLabel - 3rd level of event heirarchy
     * @param {string} [eventValue] - optional $ value of event
     */
    logAnalyticsEvent(
      'remove_liquidity',
      'dialog_step_1',
      'press_remove_button',
    )

    if (!web3) {
      /* Analytics Event
       * @param {string} eventCategory - 1st level of event heirarchy
       * @param {string} eventAction - 2nd level of event heirarchy
       * @param {string} eventLabel - 3rd level of event heirarchy
       * @param {object} customDimensions - key-value pairs of custom dimensions to send with request
       * @param {bool} [nonInteractive] - optional boolean flag to determine whether this event should impact bounce rate
       * @param {string} [eventValue] - optional $ value of event
       */
      logAnalyticsEvent('remove_liquidity', 'failed', 'missing_web3')

      return
    }

    // TODO - check that this is a uniswap exchange

    let contract = new web3.eth.Contract(UniswapArtifact, exchange)

    const txOptions = {
      from: (await web3.eth.getAccounts())[0],
      // gas: liquidityHelper.gasLimit,
      gasPrice: await web3.eth.getGasPrice(),
    }

    let withdrawalAmountWei = new BN(String(withdrawalAmount * 10 ** 18))
    let minEth = new BN(
      String(estimatedRedemptionValues.estBaseRedemptionValue * 10 ** 18),
    )
    let minTokens = new BN(
      String(estimatedRedemptionValues.estTokenRedemptionValue * 10 ** 18),
    )
    let deadline = new BN(String(Math.ceil(Date.now() / 1000) + 60 * 10))

    // TODO check for number formatting etc.
    console.debug('withdrawalAmountWei', String(withdrawalAmountWei))

    let response = contract.methods
      .removeLiquidity(
        String(withdrawalAmountWei),
        String(minEth),
        String(minTokens),
        String(deadline),
      )
      .send(txOptions)
      .on('transactionHash', hash => {
        notify.hash(hash)

        /* Analytics Event
         * @param {string} eventCategory - 1st level of event heirarchy
         * @param {string} eventAction - 2nd level of event heirarchy
         * @param {string} eventLabel - 3rd level of event heirarchy
         * @param {object} customDimensions - key-value pairs of custom dimensions to send with request
         * @param {bool} [nonInteractive] - optional boolean flag to determine whether this event should impact bounce rate
         * @param {string} [eventValue] - optional $ value of event
         */
        logAnalyticsEvent('remove_liquidity', 'dialog_step_1', 'tx_created')

        const { emitter } = notify.hash(hash)

        emitter.on('txConfirmed', tx => {
          setOwnershipUpdateTimestamp(+Date())

          /* Analytics Event
           * @param {string} eventCategory - 1st level of event heirarchy
           * @param {string} eventAction - 2nd level of event heirarchy
           * @param {string} eventLabel - 3rd level of event heirarchy
           * @param {string} [eventValue] - optional $ value of event
           */
          logAnalyticsEvent('remove_liquidity', 'success', 'tx_confirmed')
        })

        setTransactionHash(hash)
        setCurrentStep(2)
      })
  }

  useEffect(() => {
    async function updateExchangeData() {
      getExchange(exchange)
        .then(data => setExchangeData(data))
        .catch(err => console.log(err))
    }
    updateExchangeData()
  }, [exchange])

  useEffect(() => {
    async function updateTokenBalances() {
      setTokenBalance(await tokenBalanceOf(exchange))
      setTokenDecimals(await tokenDecimalsOf(exchange))
      setTokenTotalSupply(await tokenTotalSupplyOf(exchange))
    }

    updateTokenBalances()
  }, [exchange, tokenBalanceOf, tokenDecimalsOf, tokenTotalSupplyOf])

  useEffect(() => {
    async function updateEstimatedRedemptionValues() {
      tokenTotalSupply &&
        exchangeData &&
        estUniswapRedemptionValues(
          exchangeData,
          withdrawalAmount,
          tokenTotalSupply,
        ) &&
        setEstimatedRedemptionValues(
          estUniswapRedemptionValues(
            exchangeData,
            withdrawalAmount,
            tokenTotalSupply,
          ),
        )
    }

    updateEstimatedRedemptionValues()
  }, [exchangeData, withdrawalAmount, tokenTotalSupply])

  return (
    <React.Fragment>
      {/* Step 1 */}
      <Step
        stepIndex={0}
        currentStep={currentStep}
        header={t('common.remove_liquidity')}
        subheader={
          exchangeData &&
          t('remove_liquidity.redeeming_ownership_shares_message', {
            exchangePool: titleForPool(exchangeData),
          })
        }
        actions={
          <React.Fragment>
            <Grid item>
              <Button
                className={classes.actionButton}
                variant="contained"
                size="large"
                onClick={() => onClose()}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  setCurrentStep(1)

                  /* Analytics Event
                   * @param {string} eventCategory - 1st level of event heirarchy
                   * @param {string} eventAction - 2nd level of event heirarchy
                   * @param {string} eventLabel - 3rd level of event heirarchy
                   * @param {string} [eventValue] - optional $ value of event
                   */
                  logAnalyticsEvent(
                    'remove_liquidity',
                    'dialog_step_0',
                    'press_continue_button',
                  )
                }}
                disabled={!validAmount}
              >
                {t('common.continue')}
              </Button>
            </Grid>
          </React.Fragment>
        }
      >
        <Typography variant="body1">
          {t('remove_liquidity.how_many_tokens_to_remove_message')}
        </Typography>
        <CurrencyTextField
          accountConnected
          tokenSymbol="UNI"
          value={withdrawalAmount}
          balance={tokenBalance}
          balanceDecimals={tokenDecimals}
          onChange={onWithdrawalAmountChange}
          setMaxAmount={setMaxWithdrawalAmount}
          onKeyUp={e => {
            e.preventDefault()
            if (e.keyCode === 13 && validAmount) {
              setCurrentStep(1)

              /* Analytics Event
               * @param {string} eventCategory - 1st level of event heirarchy
               * @param {string} eventAction - 2nd level of event heirarchy
               * @param {string} eventLabel - 3rd level of event heirarchy
               * @param {string} [eventValue] - optional $ value of event
               */
              logAnalyticsEvent(
                'remove_liquidity',
                'dialog_step_0',
                'press_continue_button',
              )
            }
          }}
          showMax
          autoFocus
        />
      </Step>

      {/* Step 2 */}
      <Step
        stepIndex={1}
        currentStep={currentStep}
        header={t('add_liquidity.review_transaction_details')}
        subheader={
          exchangeData &&
          t('remove_liquidity.redeeming_ownership_shares_message', {
            exchangePool: titleForPool(exchangeData),
          })
        }
        actions={
          <React.Fragment>
            <Grid item>
              <Button
                className={classes.actionButton}
                variant="contained"
                size="large"
                onClick={() => setCurrentStep(0)}
              >
                {t('common.back')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleRemoveLiquidity(withdrawalAmount)}
              >
                {t('common.redeem')}
              </Button>
            </Grid>
          </React.Fragment>
        }
        noPadding
      >
        <TxDetailsWrapper>
          <TxDetailRow
            description={t('remove_liquidity.ownership_shares')}
            value={`${withdrawalAmount} UNI`}
            divider
          />
          <TxDetailRow
            description={t('labels.to_contract')}
            value={
              <a
                className={sharedClasses.linkStyle}
                href={`https://etherscan.io/address/${exchange}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {slicedAddress(exchange)} ↗︎
              </a>
            }
            divider
          />
          <TxDetailRow
            description={t('labels.pool')}
            value={exchangeData && titleForPool(exchangeData)}
            divider
          />
          <TxDetailRow
            description={t('remove_liquidity.redemption_value')}
            value={
              estimatedRedemptionValues && (
                <React.Fragment>
                  <p className={classes.txRowText}>{`${format(
                    estimatedRedemptionValues.estBaseRedemptionValue,
                  )} ${exchangeData.baseSymbol}`}</p>
                  <p className={classes.txRowText}>{`+ ${format(
                    estimatedRedemptionValues.estTokenRedemptionValue,
                  )} ${exchangeData.tokenSymbol}`}</p>
                </React.Fragment>
              )
            }
          />
        </TxDetailsWrapper>
      </Step>

      {/* Step 3 */}
      <Step
        stepIndex={2}
        currentStep={currentStep}
        header={t('labels.transaction_being_processed')}
        subheader={t('labels.transaction_estimated_time_message')}
        actions={
          <Grid
            role="tabpanel"
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={1}
            style={{ marginTop: 40, width: 160 }}
          >
            <Grid item>
              <Button
                className={classes.actionButton}
                variant="contained"
                size="large"
                fullWidth
                href={etherscanTxUrl(transactionHash)}
                target="_blank"
                disabled={!transactionHash}
              >
                {t('common.view_tx')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={() => onClose()}
              >
                {t('common.close')}
              </Button>
            </Grid>
          </Grid>
        }
      />
    </React.Fragment>
  )
}

const RemoveLiquidityDialog = ({
  exchange,
  open,
  onClose: onCloseProp,
  web3,
  notify,
  setOwnershipUpdateTimestamp,
  tokenBalanceOf,
  tokenDecimalsOf,
  tokenTotalSupplyOf,
}) => {
  const { t } = useTranslation()

  const [currentStep, setCurrentStep] = useState(0)
  const [withdrawalAmount, setWithdrawalAmount] = useState('')
  const [transactionHash, setTransactionHash] = useState(null)

  const steps = useMemo(() => {
    return [
      t('common.remove_liquidity'),
      t('labels.review_transaction'),
      t('labels.confirmation'),
    ]
  }, [t])

  const onClose = useCallback(() => {
    onCloseProp()
    setTimeout(() => {
      setCurrentStep(0)
      setWithdrawalAmount('')
      setTransactionHash(null)
    }, 200)
  }, [onCloseProp])

  return (
    <FullScreenDialog
      open={open}
      onClose={onClose}
      cancellable={currentStep < 2}
    >
      <FullScreenDialogContent>
        {/* Steps Wrapper */}
        <Grid item>
          <Grid container direction="row" justify="space-between" wrap="nowrap">
            {steps.map((step, idx) => {
              return (
                <Grid item key={idx}>
                  <StepIndicator
                    index={idx}
                    name={step}
                    currentStep={currentStep}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>

        {/* Steps Content */}
        <Grid item>
          <Steps
            exchange={exchange}
            onClose={onClose}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            withdrawalAmount={withdrawalAmount}
            setWithdrawalAmount={setWithdrawalAmount}
            web3={web3}
            notify={notify}
            tokenBalanceOf={tokenBalanceOf}
            tokenDecimalsOf={tokenDecimalsOf}
            tokenTotalSupplyOf={tokenTotalSupplyOf}
            setOwnershipUpdateTimestamp={setOwnershipUpdateTimestamp}
            transactionHash={transactionHash}
            setTransactionHash={setTransactionHash}
          />
        </Grid>
      </FullScreenDialogContent>
    </FullScreenDialog>
  )
}

const RemoveLiquidityDialogWrapper = props => (
  <AccountConsumer>
    {({ setOwnershipUpdateTimestamp }) => (
      <OnboardConsumer>
        {({ web3, tokenBalanceOf, tokenDecimalsOf, tokenTotalSupplyOf }) => (
          <NotificationConsumer>
            {({ notify }) => (
              <RemoveLiquidityDialog
                {...props}
                notify={notify}
                web3={web3}
                setOwnershipUpdateTimestamp={setOwnershipUpdateTimestamp}
                tokenBalanceOf={tokenBalanceOf}
                tokenDecimalsOf={tokenDecimalsOf}
                tokenTotalSupplyOf={tokenTotalSupplyOf}
              />
            )}
          </NotificationConsumer>
        )}
      </OnboardConsumer>
    )}
  </AccountConsumer>
)

export default RemoveLiquidityDialogWrapper
