import React from 'react'

// i18n
import { useTranslation } from 'react-i18next'

// Material UI
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { Caption } from './Typography'

// Styles
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  tab: {
    padding: '6px 12px',
    [theme.breakpoints.down('xs')]: {
      padding: '6px 6px',
    },
  },
}))

const ControlTabs = ({
  returnTypeTabValue,
  handleReturnTypeTabChange,
  periodTabValue,
  handlePeriodTabChange,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid container direction="row" justify="space-between">
      <Grid item>
        <Caption lowEmphasis>{t('labels.return_type')}</Caption>
        <Tabs
          value={returnTypeTabValue}
          onChange={handleReturnTypeTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            className={classes.tab}
            label={t('labels.pool_growth')}
            value={0}
            disableRipple
          />
          <Tab
            className={classes.tab}
            label={t('labels.eth_returns')}
            value={1}
            disableRipple
            disabled
          />
          <Tab
              label={t('labels.usd_returns')}
              value={2}
              disableRipple
              disabled
            />
        </Tabs>
      </Grid>
      <Grid item>
        <Caption lowEmphasis>{t('labels.period_length')}</Caption>
        <Tabs
          value={periodTabValue}
          onChange={handlePeriodTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            className={classes.tab}
            label={t('common.day_period_label', { days: 7 })}
            value={7}
            disableRipple
          />
          <Tab
            className={classes.tab}
            label={t('common.day_period_label', { days: 30 })}
            value={30}
            disableRipple
          />
          <Tab
            className={classes.tab}
            label={t('common.day_period_label', { days: 90 })}
            value={90}
            disableRipple
          />
        </Tabs>
      </Grid>
    </Grid>
  )
}

export default ControlTabs
