// Styles
export const SELL_COLOR = 'rgba(255, 34, 34, 0.5)'
export const BUY_COLOR = 'rgba(0, 204, 0, 0.5)'
export const SWAP_COLOR = 'rgba(255, 0, 106, 0.2)'

const styles = theme => ({
  root: {
    width: '100%',
    margin: 0,
  },
  firstSectionPadding: {
    marginTop: 96,
  },
  subsequentSectionPadding: {
    marginTop: 60,
  },
  paperInGrid: {
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
    background: '#f9f9f9',
  },
  banner: {
    background: '#e9e9e9',
    padding: theme.spacing(4),
    borderRadius: 8,
  },
  bannerImage: {
    width: 256,
    height: 256,
    [theme.breakpoints.down('sm')]: {
      width: 192,
      height: 192,
    },
    [theme.breakpoints.down('xs')]: {
      width: 128,
      height: 128,
    },
  },
  buttonDrawer: {
    paddingRight: theme.spacing(1),
    textAlign: 'right',
  },
  linkStyle: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:visited': { color: theme.palette.primary.main },
  },
  linkContainer: {
    '&:focus': {
      outline: 'none',
    },
  },
  accountWidgetContainer: {
    height: 60,
    [theme.breakpoints.up('md')]: {
      minWidth: 280,
    },
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    border: '1px solid rgba(0,0,0,0)',
    borderRadius: 4,
    '&:hover': {
      border: '1px solid rgba(0,0,0,0.12)',
      boxShadow: theme.shadows[1],
    },
    transition: 'all .3s',
  },
  accountLabel: {
    fontWeight: 900,
    fontSize: '1.125rem',
    color: 'rgba(0,0,0,0.87)',
    letterSpacing: '0.015rem',
  },
  accountBalance: {
    fontWeight: 500,
    fontSize: '0.875rem',
    color: 'rgba(0,0,0,0.55)',
    letterSpacing: '0.03rem',
  },
  poolInfoTitle: {
    fontWeight: 700,
    fontSize: '0.875rem',
    color: 'rgba(0,0,0,0.87)',
    letterSpacing: '0.024rem',
  },
  poolInfoSubtitle: {
    // fontWeight: 500,
    fontSize: '0.75rem',
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '0.85rem',
    // },
    color: 'rgba(0,0,0,0.55)',
    letterSpacing: '0.032rem',
  },
  tableHeader: {
    fontWeight: 500,
    fontSize: '0.75rem',
    // color: 'rgba(0,0,0,0.55)', // This value is set in index.css to handle sort color
    letterSpacing: '0.03rem',
  },
  cellText: {
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.875rem',
    },
  },
  accountChevron: {
    color: 'rgba(0,0,0,0.60)',
    fontSize: '4rem',
    marginTop: -12,
    marginLeft: -10,
    fontFamily: 'Roboto',
    fontWeight: 900,
    lineHeight: 1,
  },
  bubbleLegend: {
    minWidth: 150,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      padding: '0 !important',
    },
  },
  colorScaleGreen: {
    width: 16,
    height: 16,
    backgroundColor: BUY_COLOR,
    borderRadius: 999,
    marginRight: 4,
  },
  colorScalePink: {
    width: 16,
    height: 16,
    backgroundColor: SWAP_COLOR,
    borderRadius: 999,
    marginRight: 4,
  },
  colorScaleRed: {
    width: 16,
    height: 16,
    backgroundColor: SELL_COLOR,
    borderRadius: 999,
    marginRight: 4,
  },
  sizeScaleBig: {
    width: 70,
    height: 70,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    borderRadius: 999,
  },
  sizeScaleSmall: {
    width: 5,
    height: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    borderRadius: 999,
  },
  filterButton: {
    padding: '2px 4px',
    height: '25px',
    fontSize: '0.95em',
    marginLeft: theme.spacing(0.5),
  },
  grayBottomBorder: {
    borderBottom: '1px solid #d9d9d9',
  },
  inlineButton: {
    marginLeft: theme.spacing(2),
  },
  emptyPoolsSwitch: {
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
    },
  },
  marginRightSectionTitle: {
    marginRight: 12,
  },
  sponsoredRow: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFDF4',

    '&:hover': {
      backgroundColor: '#FFFBD4',
    },
  },
  adChip: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    background: '#FF006A',
    color: 'rgba(255,255,255,0.87)',
    // border: '1px solid rgba(255, 255, 255, 0.87)',
    borderRadius: 5,
    height: 18,
    width: 25,
    letterSpacing: '0.055em',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  poolStatWrapper: {
    minWidth: 260,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },
  poolStatText: {
    fontFamily: 'Roboto',
    fontWeight: 900,
  },
  poolTags: {
    width: 'unset',
    margin: '2px',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
})

export default styles
