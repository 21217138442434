import React, { useMemo } from 'react'

// Components
import EthereumAvatar from '../../shared/EthereumAvatar'
import PoolAvatar from '../../shared/PoolAvatar'
import Table, { Header, CellText } from '../../shared/Table'
import TokenBalance from '../../components/TokenBalance'
import TraderCell from '../../shared/TraderCell'
import { SectionHeader, Body2Text, Caption } from '../../shared/Typography'

// i18n
import { useTranslation } from 'react-i18next'

// Material UI
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

// Helpers
import { format, usdFormat, chooseAnAddressFor } from '../../../helpers'
import { dataSourceForLiquidity } from '../../../services/blocklytics'

// Styles
import { makeStyles } from '@material-ui/styles'
import styles from '../../shared/styles'

const useStyles = makeStyles(styles)

const Liquidity = ({ exchange, tokenDetails }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  // Creates an array containing pairs of assets
  const assets = useMemo(() => {
    return !tokenDetails ? [{}, {}] : tokenDetails.assets
  }, [tokenDetails])

  return (
    <Grid
      container
      spacing={4}
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      className={classes.subsequentSectionPadding}
    >
      <Grid item xs={12}>
        <SectionHeader>{t('labels.pool_balances')}</SectionHeader>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {assets.map((asset, idx) => (
            <Grid item xs={12} sm={6} md={3} spacing={2} key={idx}>
              <TokenBalance
                token={asset.address}
                balance={asset.balance}
                symbol={asset.symbol}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ width: '100%' }}>
        <Grid item style={{ marginBottom: 8 }}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            wrap="nowrap"
            className={classes.subsequentSectionPadding}
          >
            <Grid item>
              <Grid container direction="row">
                <SectionHeader>{t('labels.liquidity_providers')}</SectionHeader>
                <PoolAvatar
                  size={32}
                  styles={{
                    display: 'inline-block',
                  }}
                  token={
                    tokenDetails && chooseAnAddressFor(tokenDetails.assets)
                  }
                  platform={tokenDetails && tokenDetails.platform}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Table
          controlled
          dataSource={dataSourceForLiquidity(exchange)}
          columns={[
            {
              id: 'row',
              accessor: d => d.index,
              width: 40,
              sortable: false,
              Cell: ({ viewIndex, pageSize, page, ...row }) => {
                const rank = page * pageSize + viewIndex + 1

                return (
                  <CellText align="center" style={{ lineHeight: '40px' }}>
                    {rank}
                  </CellText>
                )
              },
            },
            {
              Header: <Header>{t('labels.liquidity_provider')}</Header>,
              accessor: 'account',
              minWidth: 200,
              sortable: false,
              Cell: row => {
                return <TraderCell address={row.value} showCopy />
              },
            },
            {
              Header: <Header>{t('labels.pool_ownership')}</Header>,
              accessor: 'ownership',
              sortable: false,
              Cell: row => {
                const ownership = row.value

                return (
                  <CellText align="right" style={{ lineHeight: '40px' }}>
                    {`${format(ownership, 2)}%`}
                  </CellText>
                )
              },
            },
            {
              Header: <Header>{t('labels.shares')}</Header>,
              accessor: 'ownership_tokens',
              Cell: row => {
                const ownership = row.value

                return (
                  <CellText align="right" style={{ lineHeight: '40px' }}>
                    {`${format(ownership, 4)}`}
                  </CellText>
                )
              },
            },
            {
              Header: <Header>{t('labels.share_value')}</Header>,
              accessor: 'ownership',
              sortable: false,
              Cell: row => {
                let usdBalance = tokenDetails ? tokenDetails.usdLiquidity : null
                usdBalance *= row.value / 100

                return (
                  <CellText align="right" style={{ lineHeight: '40px' }}>
                    {usdBalance ? (
                      `${usdFormat(usdBalance, 0)}`
                    ) : (
                      <Skeleton
                        height={20}
                        width={100}
                        style={{ margin: '4px 0px' }}
                        component="span"
                      />
                    )}
                  </CellText>
                )
              },
            },
          ]}
          defaultSorted={[{ id: 'ownership_tokens', desc: true }]}
        />
      </Grid>
    </Grid>
  )
}

export default Liquidity
