import React from 'react'

// Components
import YourShares from './YourShares'
// import PoolOwnership from './PoolOwnership'

const Shares = ({
  exchangeData,
  selectedAddress,
  ethPrice,
  watchedAddresses,
  ownershipData,
  ownershipDataIsLoading,
}) => {
  return (
    <React.Fragment>
      <YourShares
        exchangeData={exchangeData}
        ethPrice={ethPrice}
        selectedAddress={selectedAddress}
        watchedAddresses={watchedAddresses}
        ownershipData={ownershipData}
        ownershipDataIsLoading={ownershipDataIsLoading}
      />
    </React.Fragment>
  )
}

export default Shares
