import React from 'react'

// Material UI
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

// Styles
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  tabsRoot: {
    borderBottom: '1px solid #E9E9E9',
  },
  itemIndicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 4,
    '& > div': {
      width: '100%',
      maxWidth: 'calc(100% - 6px)',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100% - 30px)',
      },
      backgroundColor: '#FF006A',
    },
  },
  item: {
    fontFamily: 'Roboto',
    fontSize: '1.125rem',
    fontWeight: 600,
    textTransform: 'capitalize',
    color: 'rgba(0,0,0,0.38)',
    letterSpacing: '0.05rem',
    marginRight: '4rem',
    padding: '6px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '6px .8rem',
      marginRight: '.4rem',
    },
    '&:hover': {
      color: 'rgba(0,0,0,0.87)',
    },
  },
  selectedItem: {
    color: 'rgba(0,0,0,0.87)',
  },
}))

export const NavBarItem = props => {
  const classes = useStyles()

  return (
    <Tab
      classes={{ root: classes.item, selected: classes.selectedItem }}
      disableRipple
      {...props}
    />
  )
}

const NavBar = ({ value, onChange, children, ...props }) => {
  const classes = useStyles()

  return (
    <Tabs
      classes={{ root: classes.tabsRoot, indicator: classes.itemIndicator }}
      TabIndicatorProps={{ children: <div /> }}
      value={value}
      onChange={onChange}
      variant="scrollable"
      {...props}
    >
      {children}
    </Tabs>
  )
}

export default NavBar
