// React
import React, { Component, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { withTranslation, useTranslation } from 'react-i18next'

// Contexts
import { ExchangePriceConsumer } from '../../contexts/ExchangePriceContext'
import { LocaleConsumer } from '../../contexts/LocaleContext'
import { OnboardConsumer } from '../../contexts/OnboardContext'

// Components
import Liquidity from './components/Liquidity'
import NavBar, { NavBarItem } from '../shared/NavBar'
import PoolAvatar from '../shared/PoolAvatar'
import PoolInfo from './components/PoolInfo'
import Returns from './components/Returns'
import TradesTab from './components/Trades'
import YourShares from './components/YourShares'
import {
  Caption,
  Title,
  H5Text,
  SectionHeader,
  Subtitle,
} from '../shared/Typography'

// Material UI
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import withStyles from '@material-ui/core/styles/withStyles'

// Styles
import styles from '../shared/styles'

// Services
import { logAnalyticsPageview } from '../../services/analytics'

// Helpers
import {
  roiFormat,
  usdFormat,
  slicedAddress,
  chooseAnAddressFor,
  tagsFor,
} from '../../helpers'
import qs from 'query-string'
import { MESSAGES } from '../../shared/messages'
import Tag from '../shared/Tag'
import Note from '../components/Note'
import PoolTokens from './components/PoolTokens'
import { makeStyles } from '@material-ui/core'

// Moment
var moment = require('moment')

const LinkNavBarItem = props => (
  <NavBarItem
    component="a"
    onClick={event => {
      event.preventDefault()
    }}
    {...props}
  />
)

function TabPanel(props) {
  const { value, index, children } = props

  return (
    <Grid
      item
      xs={12}
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
    >
      {children}
    </Grid>
  )
}

const usePoolDataStyles = makeStyles(theme => ({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  },
  poolName: {
    justifyContent: 'flex-start',
    marginRight: 8,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      justifyContent: 'center',
      marginRight: 0,
    },
  },
  poolTagsWrapper: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}))

const PoolData = ({ classes, tokenDetails }) => {
  const { t } = useTranslation()
  const internalClasses = usePoolDataStyles()

  const tags = useMemo(() => {
    return tokenDetails && tokenDetails.assets ? tagsFor(tokenDetails) : []
  }, [tokenDetails])

  return (
    <Grid
      container
      direction="row"
      className={`subsequentSectionPadding ${internalClasses.container}`}
      spacing={4}
    >
      {/* Pool Avatar */}
      <Grid item>
        {tokenDetails && tokenDetails.platform ? (
          <PoolAvatar
            alt={tokenDetails.tokenSymbol || null}
            token={chooseAnAddressFor(tokenDetails.assets)}
            platform={tokenDetails.platform}
            size={128}
          />
        ) : (
          <Skeleton variant="circle" height={128} width={128} />
        )}
      </Grid>

      {/* Pool Details - Title, Stats, Note */}
      <Grid
        item
        xs="auto"
        container
        direction="column"
        alignItems="stretch"
        justify="flex-start"
        spacing={1}
      >
        {/* Title and Tokens */}
        <Grid item container direction="column" spacing={1}>
          {/* Title row (with tags) */}
          <Grid
            item
            className={internalClasses.poolTagsWrapper}
            xs={12}
            container
            direction="row"
            alignItems="flex-end"
            spacing={1}
          >
            {/* Title */}
            <Grid item xs={12}>
              <Title className={internalClasses.poolName}>
                {tokenDetails ? (
                  `${tokenDetails.poolName}`
                ) : (
                  <Skeleton
                    height={44}
                    width={340}
                    style={{ margin: '4px 0px' }}
                  />
                )}
              </Title>
            </Grid>

            {/* Tags */}
            <Grid
              item
              container
              className={classes.poolTags}
              direction="row"
              wrap="nowrap"
            >
              {tags && tags.map((tag, idx) => <Tag key={idx} tag={tag} />)}
            </Grid>
          </Grid>

          {/* Pool tokens */}
          <Grid item xs={12}>
            <PoolTokens token={tokenDetails} />
          </Grid>
        </Grid>

        {/* Pool stats */}
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justify="space-between"
            spacing={2}
            style={{ marginTop: 0 }}
          >
            <Grid item className={classes.poolStatWrapper}>
              <H5Text className={classes.poolStatText}>
                {tokenDetails && tokenDetails.usdLiquidity ? (
                  `${usdFormat(tokenDetails.usdLiquidity)}`
                ) : (
                  <Skeleton
                    height={31}
                    width={100}
                    style={{ margin: '4px 0px' }}
                  />
                )}
              </H5Text>
              <Caption>{t('labels.liquidity')}</Caption>
            </Grid>
            <Grid item className={classes.poolStatWrapper}>
              <H5Text className={classes.poolStatText}>
                {tokenDetails ? (
                  tokenDetails.usdVolume ? (
                    `${usdFormat(tokenDetails.usdVolume)}`
                  ) : (
                    `${usdFormat(tokenDetails.usdVolume, 2)}`
                  )
                ) : (
                  <Skeleton
                    height={31}
                    width={100}
                    style={{ margin: '4px 0px' }}
                  />
                )}
              </H5Text>
              <Caption>{t('labels.24h_volume')}</Caption>
            </Grid>
            <Grid item className={classes.poolStatWrapper}>
              <H5Text className={classes.poolStatText}>
                {tokenDetails && tokenDetails.roi ? (
                  `${roiFormat(tokenDetails.roi)}`
                ) : (
                  <Skeleton
                    height={31}
                    width={100}
                    style={{ margin: '4px 0px' }}
                  />
                )}
              </H5Text>
              <Caption>{t('labels.pool_roi')}</Caption>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const NavigationTabs = props => {
  const {
    navTabValue,
    handleNavTabChange,
    location: { search: searchParams },
  } = props

  // const classes = makeStyles(styles)()
  const { t } = useTranslation()

  return (
    <LocaleConsumer>
      {({ localePrefix }) => {
        return (
          <NavBar
            value={navTabValue}
            onChange={(event, value) =>
              handleNavTabChange(event, value, localePrefix, searchParams)
            }
          >
            <LinkNavBarItem
              label={t('labels.your_shares')}
              value={0}
              // href={`/#${localePrefix}/info/${exchange}`}
            />
            <LinkNavBarItem
              label={t('labels.returns')}
              value={1}
              // href={`/#${localePrefix}/returns/${exchange}`}
            />
            <LinkNavBarItem
              label={t('labels.trades')}
              value={2}
              // href={`/#${localePrefix}/trades/${exchange}`}
            />
            <LinkNavBarItem
              label={t('labels.liquidity')}
              value={3}
              // href={`/#${localePrefix}/liquidity/${exchange}`}
            />
            <LinkNavBarItem
              label={t('labels.pool_info')}
              value={4}
              // href={`/#${localePrefix}/info/${exchange}`}
            />
          </NavBar>
        )
      }}
    </LocaleConsumer>
  )
}

class TradesBase extends Component {
  constructor(props) {
    // Google Analytics pageview
    logAnalyticsPageview(
      '/exchange/' + props.exchange,
      'Exchange | ' + props.exchange,
    )

    super(props)

    let exchange = props.exchange
    props.setExchange(exchange)
    
    const { period } = qs.parse(props.location.search)

    this.state = {
      exchange,
      tokenDetails: props.getTokenDetails(exchange),
      hoverData: null,
      selectedFromDate: moment.utc().subtract(1, 'days'),
      selectedToDate: moment.utc(),
      originationFilter: null,
      periodTabValue: period || 30,
      returnTypeTabValue: 0,
      navTabValue: props.navTabValue,
      showLogo: true,
      accountFilter: null,
    }
  }

  componentDidMount() {
    this.updateStateFromLocation()
  }

  componentDidUpdate = prevProps => {
    if (this.props.exchange !== prevProps.exchange) {
      // reset state
      let exchange = this.props.exchange

      this.setState({
        exchange,
        tokenDetails: this.props.getTokenDetails(exchange),
        hoverData: null,
      })
    }

    const { location } = this.props

    if (prevProps.location.search !== location.search)
      this.updateStateFromLocation()
  }

  updateStateFromLocation = () => {
    const { period, from, to, id, account, origination } = qs.parse(
      this.props.location.search,
    )

    const validPeriod = ['7', '30', '90'].find(p => p === period)
      ? period
      : null
    this.setState({
      ...(validPeriod && { periodTabValue: parseInt(validPeriod) }),
      ...(from && { selectedFromDate: moment.utc(from) }),
      ...(to && { selectedToDate: moment.utc(to) }),
      ...(id && { hoverData: { x: id } }),
      ...(account && { accountFilter: account }),
      ...(origination && { originationFilter: JSON.parse(origination) }),
    })
  }

  pushNewSearchParam = (key, value) => {
    const { location, history } = this.props
    const pathname = window.location.hash.split('?')[0].slice(1) // pathname is not sync with react routers' location

    const urlState = {
      pathname,
      search: qs.stringify({
        ...qs.parse(location.search),
        [key]: value,
      }),
    }

    history.push(urlState)
  }

  handlereturnTypeTabChange = (event, newValue) => {
    this.setState({
      returnTypeTabValue: newValue,
    })
  }

  handlePeriodTabChange = (event, newValue) => {
    this.pushNewSearchParam('period', newValue)
  }

  handleDateFromChange = event => {
    this.pushNewSearchParam('from', event.format('YYYY-MM-DD'))
  }

  handleDateToChange = event => {
    this.pushNewSearchParam('to', event.format('YYYY-MM-DD'))
  }

  setHoverData = e => {
    this.pushNewSearchParam('id', e.point.id)
  }

  handleTraderFilterChange = account => {
    this.pushNewSearchParam('account', account)
  }

  handleOriginationFilterChange = options => {
    this.pushNewSearchParam(
      'origination',
      JSON.stringify(options ? options.map(option => option.value) : null),
    )
  }

  clearAccountIdFilterData = e => {
    const { location, history } = this.props
    const prevSearch = qs.parse(location.search)

    const { id, account, origination, ...search } = prevSearch
    const urlState = {
      pathname: location.pathname,
      search: qs.stringify(search),
    }

    history.push(urlState)

    this.setState({
      accountFilter: null,
      hoverData: {
        x: null,
      },
    })
  }

  handleNavTabChange = (event, newValue, localePrefix, searchParams) => {
    const { exchange } = this.state

    switch (newValue) {
      case 0:
        if (window.history.replaceState) {
          window.history.replaceState(
            {},
            '',
            `/#${localePrefix}/shares/${exchange}${searchParams}`,
          )
        }
        break
      case 1:
        if (window.history.replaceState) {
          window.history.replaceState(
            {},
            '',
            `/#${localePrefix}/returns/${exchange}${searchParams}`,
          )
        }
        break
      case 2:
        if (window.history.replaceState) {
          window.history.replaceState(
            {},
            '',
            `/#${localePrefix}/trades/${exchange}${searchParams}`,
          )
        }
        break
      case 3:
        if (window.history.replaceState) {
          window.history.replaceState(
            {},
            '',
            `/#${localePrefix}/liquidity/${exchange}${searchParams}`,
          )
        }
        break
      case 4:
        if (window.history.replaceState) {
          window.history.replaceState(
            {},
            '',
            `/#${localePrefix}/info/${exchange}${searchParams}`,
          )
        }
        break
      default:
        break
    }

    this.setState({
      navTabValue: newValue,
    })
  }

  render() {
    const {
      classes,
      exchangeData: propExchangeData,
      ethPrice,
      t,
      selectedAddress,
      getTokenDetails,
    } = this.props

    const { exchange } = this.state
    const {
      periodTabValue,
      returnTypeTabValue,
      navTabValue,
      selectedFromDate,
      selectedToDate,
      accountFilter,
    } = this.state
    const { originationFilter, hoverData } = this.state

    let tokenDetails = getTokenDetails(exchange)

    const dateFrom = moment(selectedFromDate).format('YYYY-MM-DD')
    const dateTo = moment(selectedToDate).format('YYYY-MM-DD')

    const baseUrl = 'https://api.blocklytics.org'
    const tradesDataSource = `${baseUrl}/pools/v1/trades/${exchange}?from=${dateFrom}&to=${dateTo}&key=AIzaSyDn7LYCJuJK6fM37nEpwVTsXdQ5eok0wQk`

    const hoverDataId = hoverData ? hoverData.x : null

    var filtersApplied = t('trades.showing_trades_in_dates', {
      fromDate: selectedFromDate.format('LL'),
      toDate: selectedToDate.format('LL'),
    })
    var showClearButton = false
    if (accountFilter) {
      filtersApplied = t('trades.showing_results_for_user', {
        userId: slicedAddress(accountFilter),
      })
      showClearButton = true
    } else if (hoverDataId) {
      filtersApplied = t('trades.showing_trade_hover', { hoverDataId })
      showClearButton = true
    }

    const exchangeToPlot = {
      address: exchange,
      symbol:
        tokenDetails && tokenDetails.tokenSymbol
          ? tokenDetails.tokenSymbol
          : null,
    }

    const accountFilterQuery = accountFilter && {
      object: accountFilter,
      column: 'trader',
    }

    return (
      <>
        {tokenDetails && tokenDetails.poolName ? (
          <Helmet>
            <title>{tokenDetails.poolName} | Pools.fyi</title>
            <meta
              property="og:title"
              content={`${tokenDetails.poolName} | Pools.fyi`}
            />
            <meta
              name="twitter:title"
              content={`${tokenDetails.poolName} | Pools.fyi`}
            />
          </Helmet>
        ) : null}
        <React.Fragment>
          <Grid
            container
            spacing={4}
            style={{ marginTop: 24 }}
            className={classes.root}
          >
            <Grid item xs={12}>
              <PoolData
                classes={classes}
                tokenDetails={tokenDetails}
                ethPrice={ethPrice}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {MESSAGES[exchange] ? (
                <Note
                  emoji="📌"
                  title="Editor's note"
                  Subtitle={`${t(`trades.messages.${exchange}`)}`}
                  href={MESSAGES[exchange]['href']}
                />
              ) : null}
            </Grid>

            {/* Tabs content */}
            <Grid container className={classes.firstSectionPadding}>
              <Grid item xs={12}>
                <NavigationTabs
                  exchange={exchange}
                  navTabValue={navTabValue}
                  handleNavTabChange={this.handleNavTabChange}
                  location={this.props.location}
                />
              </Grid>
            </Grid>

            {/* <TabPanel value={navTabValue} index={0}>
              {selectedAddress && exchange ? (
                <Grid item xs={12}>
                  <YourShares
                    selectedAddress={selectedAddress}
                    exchange={exchange}
                    exchangeData={propExchangeData}
                    ethPrice={ethPrice}
                    tokenDetails={tokenDetails}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} className={classes.subsequentSectionPadding}>
                  <SectionHeader>
                    {t('account.connect_account_header_v2')}
                  </SectionHeader>
                  <Subtitle>{t('account.connect_account_desc_v2')}</Subtitle>
                </Grid>
              )}
            </TabPanel> */}

            <TabPanel value={navTabValue} index={1}>
              <Returns
                tokenDetails={tokenDetails}
                periodTabValue={periodTabValue}
                exchangeToPlot={exchangeToPlot}
                returnTypeTabValue={returnTypeTabValue}
                handlereturnTypeTabChange={this.handlereturnTypeTabChange}
                handlePeriodTabChange={this.handlePeriodTabChange}
              />
            </TabPanel>

            <TabPanel value={navTabValue} index={2}>
              <TradesTab
                exchange={exchange}
                tokenDetails={tokenDetails}
                selectedFromDate={selectedFromDate}
                selectedToDate={selectedToDate}
                tradesDataSource={tradesDataSource}
                originationFilter={originationFilter}
                accountFilterQuery={accountFilterQuery}
                hoverDataId={hoverDataId}
                filtersApplied={filtersApplied}
                showClearButton={showClearButton}
                handleDateFromChange={this.handleDateFromChange}
                handleDateToChange={this.handleDateToChange}
                handleTraderFilterChange={this.handleTraderFilterChange}
                clearAccountIdFilterData={this.clearAccountIdFilterData}
                handleOriginationFilterChange={
                  this.handleOriginationFilterChange
                }
                setHoverData={this.setHoverData}
              />
            </TabPanel>

            <TabPanel value={navTabValue} index={3}>
              <Liquidity exchange={exchange} tokenDetails={tokenDetails} />
            </TabPanel>

            <TabPanel value={navTabValue} index={4}>
              <PoolInfo exchange={exchange} tokenDetails={tokenDetails} />
            </TabPanel>
          </Grid>
        </React.Fragment>
      </>
    )
  }
}

const Trades = props => (
  <OnboardConsumer>
    {({ address }) => (
      <ExchangePriceConsumer>
        {({ exchangeData, exchangeDataLoading, ethPrice, getTokenDetails, setExchange }) => (
          <TradesBase
            {...props}
            exchangeData={exchangeData}
            exchangeDataIsLoading={exchangeDataLoading}
            ethPrice={ethPrice}
            selectedAddress={address}
            getTokenDetails={getTokenDetails}
            setExchange={setExchange}
          />
        )}
      </ExchangePriceConsumer>
    )}
  </OnboardConsumer>
)

export default withTranslation()(withRouter(withStyles(styles)(Trades)))
