import React from 'react'

// Components
import AddressWatcher from '../../../../components/AddressWatcher'
import { SectionHeader, Subtitle } from '../../../../shared/Typography'

// Material UI
import Grid from '@material-ui/core/Grid'

// Styles
import { makeStyles } from '@material-ui/styles'

// i18n
import { useTranslation } from 'react-i18next'

// Feature flags
import { hide3Box } from '../../../../../shared/FeatureFlags'

const useStyles = makeStyles(theme => ({
  subsequentSectionPadding: {
    marginTop: 60,
  },
}))

const WatchAddresses = ({
  selectedAddress,
  watchedAddresses,
  setWatchedAddresses,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Grid className={classes.subsequentSectionPadding} item xs={12}>
        <SectionHeader>{t('account.watched_accounts')}</SectionHeader>
        <Subtitle style={{ marginBottom: 12 }}>
          {hide3Box
            ? t('account.watched_accounts_no_3box_desc')
            : t('account.watched_accounts_desc')}
        </Subtitle>
      </Grid>

      <Grid item xs={12}>
        <AddressWatcher
          selectedAddress={selectedAddress}
          watchedAddresses={watchedAddresses}
          setWatchedAddresses={setWatchedAddresses}
        />
      </Grid>
    </React.Fragment>
  )
}

export default WatchAddresses
