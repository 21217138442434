import React from 'react'

// Components
import PoolAvatar from '../shared/PoolAvatar'
import { Body2Text, H5Text, Subtitle } from '../shared/Typography'

// Material UI
import Skeleton from 'react-loading-skeleton'
import Grid from '@material-ui/core/Grid'

// Helpers
import { format } from '../../helpers'
import { isNil } from 'ramda'

const TokenBalance = ({ token, symbol, balance }) => (
  <Grid
    container
    direction="row"
    justify="flex-start"
    alignItems="center"
    spacing={1}
  >
    <Grid item>
      <PoolAvatar token={token} size={24} />
    </Grid>
    <Grid item>
      <H5Text>{!isNil(balance) ? format(balance) : <Skeleton />}</H5Text>
    </Grid>
    <Grid item>
      <Subtitle>{symbol}</Subtitle>
    </Grid>
  </Grid>
)

export default TokenBalance
