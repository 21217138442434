import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { addDefaultSrc, srcForPlatform } from '../../helpers'
import withStyles from '@material-ui/core/styles/withStyles'
import { H5Text } from './Typography.js'

const styles = theme => ({
  platformAvatar: {
    position: 'absolute',
    boxShadow: '0px 0px 8px rgba(0,0,0,0.08)',
    bottom: 0,
    zIndex: 1,
  },
})

const Avatar = ({
  src,
  size = 40,
  style,
  backgroundColor,
  ...props
}) => {
  return (
    <div
      style={{
        height: size,
        width: size,
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '50%',
        ...style,
      }}
    >
      <div
        {...props}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          position: 'absolute',
          zIndex: 1,
          backgroundImage: `url(${src})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }}
      />
    </div>
  )
}

const PlatformAvatar = ({
  classes,
  src,
  loading,
  platform,
  size,
  styles,
  ...props
}) => {
  const height = size || 40
  const width = size || 40

  const platformUrl = srcForPlatform(platform)

  const borderWidth = height / 20

  return (
    <div style={{ height, width, position: 'relative' }}>
    {loading ? (
      <Skeleton variant="circle" height={height} width={width} />
    ) : (
      <Avatar
        style={{
          height,
          width,
          border: `${borderWidth}px solid white`,
          boxShadow: '0px 0px 8px rgba(0,0,0,0.08)',
          backgroundColor: 'white',
        }}
        src={platformUrl}
        onError={addDefaultSrc}
        {...props}
      />
    )}
    </div>
  )
}

export default withStyles(styles)(PlatformAvatar)
