import React from 'react'

// Components
import PoolAvatar from '../../shared/PoolAvatar'
import ReturnsChart from '../../../charts/ReturnsChart'
import ReturnsChartV1 from '../../../charts/ReturnsChartV1'
import { SectionHeader, Subtitle } from '../../shared/Typography'

// i18n
import { useTranslation } from 'react-i18next'

// Material UI
import Grid from '@material-ui/core/Grid'

// Styles
import { makeStyles } from '@material-ui/styles'
import styles from '../../shared/styles'
import ControlTabs from '../../shared/ControlTabs'
import { chooseAnAddressFor } from '../../../helpers'

const useStyles = makeStyles(styles)

const Returns = ({
  tokenDetails,
  periodTabValue,
  exchangeToPlot,
  returnTypeTabValue,
  handlereturnTypeTabChange,
  handlePeriodTabChange,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid
      container
      spacing={4}
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      className={classes.subsequentSectionPadding}
    >
      <Grid item xs={12} style={{ width: '100%' }}>
        
        {/** Section Title */}
        <Grid container direction="column">
          <Grid item xs={12}>
            <Grid container direction="row">
              <SectionHeader>{t('common.roi_title')}</SectionHeader>
              <PoolAvatar
                size={32}
                styles={{
                  // boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)',
                  display: 'inline-block',
                }}
                token={tokenDetails && chooseAnAddressFor(tokenDetails.assets)}
                platform={tokenDetails && tokenDetails.platform}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Subtitle>
              {t('common.roi_desc', {
                periodTabValue,
              })}
            </Subtitle>
          </Grid>
        </Grid>
      </Grid>


      {/** Control Tabs */}
      <Grid item xs={12}>
        <ControlTabs
          returnTypeTabValue={returnTypeTabValue}
          handleReturnTypeTabChange={handlereturnTypeTabChange}
          periodTabValue={periodTabValue}
          handlePeriodTabChange={handlePeriodTabChange}
        />
      </Grid>

      {/** ROI Chart */}
      <Grid item xs={12}>
        <ReturnsChartV1
          exchanges={[exchangeToPlot]}
          periods={periodTabValue}
          freq="24H"
          returnType={returnTypeTabValue}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          spacing={4}
          className={classes.subsequentSectionPadding}
        >
          <Grid item xs={12} sm={4}>
            <SectionHeader>{t('trades.evaluating_returns')}</SectionHeader>
            <Subtitle paragraph>{t('trades.evaluating_returns_p1')}</Subtitle>
            <Subtitle paragraph>{t('trades.evaluating_returns_p2')}</Subtitle>
            <Subtitle paragraph>
              <a
                href="https://medium.com/@pintail/understanding-uniswap-returns-cc593f3499ef"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkStyle}
              >
                {t('exchanges.earn_fees_link')} ↗︎
              </a>
            </Subtitle>
          </Grid>

          <Grid item xs={12} sm={4}>
            <SectionHeader>{t('trades.trading_fees')}</SectionHeader>
            <Subtitle paragraph>{t('trades.trading_fees_p1_v2')}</Subtitle>
            <Subtitle paragraph>{t('trades.trading_fees_p2_v2')}</Subtitle>
          </Grid>

          <Grid item xs={12} sm={4}>
            <SectionHeader>{t('trades.impermanent_loss_v2')}</SectionHeader>
            <Subtitle paragraph>{t('trades.impermanent_loss_p1_v2')}</Subtitle>
            <Subtitle paragraph>
              {t('trades.impermanent_loss_p2_v2', { periodTabValue })}
            </Subtitle>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Returns
