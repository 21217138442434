import React from 'react'

// Web3
import Web3 from 'web3'

// Helpers
import { getDexAgTrade } from '../../../../../../../services/dexagTrade'
import ERC20Artifact from '../../../../../../../abi/erc20.json'

// Analytics
import { logAnalyticsEvent } from '../../../../../../../services/analytics'

const floorRound = value => Math.floor(value * 10000) / 10000
const BN = Web3.utils.BN

class TradeTokenLogicProvider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      token1Amount: null,
      token2Amount: null,
      token1Symbol: 'ETH',
      token2Symbol: null,
      token1Loading: false,
      token2Loading: false,
      rate: null,
      shouldUnlockFirst: false,
    }
  }

  componentDidMount() {
    this.abortController = new AbortController()

    const { tokenDetails } = this.props
    if (tokenDetails)
      this.setState({
        token2Symbol: tokenDetails.tokenSymbol,
        rate: tokenDetails.basePrice,
        token2Loading: !tokenDetails,
      })
  }

  componentDidUpdate(prevProps) {
    const { tokenDetails, tradeAction } = this.props
    const {
      tokenDetails: prevTokenDetails,
      tradeAction: prevTradeAction,
    } = prevProps
    // const { token1Amount, token2Amount } = this.state

    if (tokenDetails !== prevTokenDetails) {
      this.setState({
        token2Symbol: tokenDetails && tokenDetails.tokenSymbol,
        rate: tokenDetails && tokenDetails.basePrice,
        token2Loading: !tokenDetails,
        // ...(!token1Amount && { token1Amount: '' }),
        // ...(!token2Amount && { token2Amount: '' }),
      })
      return
    }

    if (tradeAction !== prevTradeAction) {
      if (tradeAction === 'Buy') this.fillToken2Amount()
      else this.fillToken1Amount()
      return
    }
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  cancelPendingFetch = () => {
    this.abortController.abort()
    this.abortController = new AbortController()
  }

  // This method also updates the `this.tx` object
  getTradePrice = (fromTokenSymbol, toTokenSymbol, amount, isFrom = true) => {
    return new Promise((resolve, reject) => {
      if (!fromTokenSymbol || !toTokenSymbol) resolve(0.0)

      const abortSignal = this.abortController.signal
      getDexAgTrade(fromTokenSymbol, toTokenSymbol, amount, isFrom, abortSignal)
        .then(tx => {
          this.tx = tx
          resolve(tx.metadata.source.price)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  sendTrade = async () => {
    const { setCurrentStep, setTransactionHash, notify, web3 } = this.props

    if (!this.tx.trade) {
      console.log('Transaction could not be formed.')
      let customDimensions = {}
      try {
        customDimensions.fromToken = this.tx.fromTokenSymbol
        customDimensions.toToken = this.tx.toTokenSymbol
      } finally {
      }
      /* Analytics Event
       * @param {string} eventCategory - 1st level of event heirarchy
       * @param {string} eventAction - 2nd level of event heirarchy
       * @param {string} eventLabel - 3rd level of event heirarchy
       * @param {object} customDimensions - key-value pairs of custom dimensions to send with request
       * @param {bool} [nonInteractive] - optional boolean flag to determine whether this event should impact bounce rate
       * @param {string} [eventValue] - optional $ value of event
       */
      logAnalyticsEvent(
        'trade_tokens',
        'failure',
        'tx_not_found',
        customDimensions,
      )
      return
    }

    const txOptions = {
      from: (await web3.eth.getAccounts())[0],
      ...this.tx.trade,
    }

    web3.eth.sendTransaction(txOptions).on('transactionHash', hash => {
      notify.hash(hash)
      let customDimensions = {}
      try {
        customDimensions.fromToken = this.tx.fromTokenSymbol
        customDimensions.toToken = this.tx.toTokenSymbol
      } catch {
        console.log(
          'Could not set from and to token symbol for tx_created event.',
        )
      }
      /* Analytics Event
       * @param {string} eventCategory - 1st level of event heirarchy
       * @param {string} eventAction - 2nd level of event heirarchy
       * @param {string} eventLabel - 3rd level of event heirarchy
       * @param {object} customDimensions - key-value pairs of custom dimensions to send with request
       * @param {bool} [nonInteractive] - optional boolean flag to determine whether this event should impact bounce rate
       * @param {string} [eventValue] - optional $ value of event
       */
      logAnalyticsEvent(
        'trade_tokens',
        'success',
        'tx_created',
        customDimensions,
      )
      const { emitter } = notify.hash(hash)

      emitter.on('txConfirmed', tx => {
        let customDimensions = {}
        try {
          customDimensions.fromToken = tx.fromTokenSymbol
          customDimensions.toToken = tx.toTokenSymbol
        } catch {
          console.log(
            'Could not set from and to token symbol for tx_confirmed event.',
          )
        }
        /* Analytics Event
         * @param {string} eventCategory - 1st level of event heirarchy
         * @param {string} eventAction - 2nd level of event heirarchy
         * @param {string} eventLabel - 3rd level of event heirarchy
         * @param {object} customDimensions - key-value pairs of custom dimensions to send with request
         * @param {bool} [nonInteractive] - optional boolean flag to determine whether this event should impact bounce rate
         * @param {string} [eventValue] - optional $ value of event
         */
        logAnalyticsEvent(
          'trade_tokens',
          'success',
          'tx_confirmed',
          customDimensions,
        )
      })

      setTransactionHash(hash)
      setCurrentStep(2)
    })
  }

  approveToken = async () => {
    const {
      tokenDetails,
      setCurrentStep,
      setTransactionHash,
      notify,
      web3,
    } = this.props

    var BN = web3.utils.BN

    // Token approvals are handled by calling approve for the Handler contract for the Proxy contract
    // From: https://docs.dex.ag/api/using-the-api-with-node.js
    let handlerAddress = '0x42dAFA7F65323b3992f447032aBD17f562A1E14C'

    // Check that tokenDetails are loaded
    const tokenAddress = tokenDetails && tokenDetails.token
    if (!tokenAddress) {
      /* Analytics Event
       * @param {string} eventCategory - 1st level of event heirarchy
       * @param {string} eventAction - 2nd level of event heirarchy
       * @param {string} eventLabel - 3rd level of event heirarchy
       * @param {object} customDimensions - key-value pairs of custom dimensions to send with request
       * @param {bool} [nonInteractive] - optional boolean flag to determine whether this event should impact bounce rate
       * @param {string} [eventValue] - optional $ value of event
       */
      logAnalyticsEvent('approve_tokens', 'failure', 'token_not_found')
      return
    }

    const contract = new web3.eth.Contract(ERC20Artifact, tokenAddress)

    const txOptions = {
      from: (await web3.eth.getAccounts())[0],
    }

    let maxUint256 = new BN('2').pow(new BN('256')).sub(new BN('1'))

    contract.methods
      .approve(handlerAddress, maxUint256.toString())
      .send(txOptions)
      .on('transactionHash', hash => {
        notify.hash(hash)
        /* Analytics Event
         * @param {string} eventCategory - 1st level of event heirarchy
         * @param {string} eventAction - 2nd level of event heirarchy
         * @param {string} eventLabel - 3rd level of event heirarchy
         * @param {object} customDimensions - key-value pairs of custom dimensions to send with request
         * @param {bool} [nonInteractive] - optional boolean flag to determine whether this event should impact bounce rate
         * @param {string} [eventValue] - optional $ value of event
         */
        logAnalyticsEvent('approve_tokens', 'approve', 'tx_created')

        const { emitter } = notify.hash(hash)

        emitter.on('txConfirmed', tx => {
          /* Analytics Event
           * @param {string} eventCategory - 1st level of event heirarchy
           * @param {string} eventAction - 2nd level of event heirarchy
           * @param {string} eventLabel - 3rd level of event heirarchy
           * @param {string} [eventValue] - optional $ value of event
           */
          logAnalyticsEvent('approve_tokens', 'success', 'tx_confirmed')
        })

        setTransactionHash(hash)
        setCurrentStep(2)
      })
  }

  getTokenObject = (first = true) => {
    const { token1Balance, token2Balance, token2Decimals } = this.props
    const {
      token1Amount,
      token2Amount,
      token1Symbol,
      token2Symbol,
      token1Loading,
      token2Loading,
    } = this.state

    return {
      amount: first ? token1Amount : token2Amount,
      decimals: first ? new BN('18') : token2Decimals,
      symbol: first ? token1Symbol : token2Symbol,
      balance: first ? token1Balance : token2Balance,
      loading: first ? token1Loading : token2Loading,
      setAmount: first ? this.setToken1Amount : this.setToken2Amount,
      setMaxAmount: first ? this.setToken1MaxAmount : this.setToken2MaxAmount,
    }
  }

  formValidityCheck = () => {
    const { token1Balance, token2Balance, tradeAction } = this.props
    const {
      token1Amount,
      token2Amount,
      token1Loading,
      token2Loading,
    } = this.state

    if (token1Loading || token2Loading) return false
    if (
      tradeAction === 'Buy' &&
      (!token1Amount || token1Amount > token1Balance)
    )
      return false
    if (
      tradeAction !== 'Buy' &&
      (!token2Amount || token2Amount > token2Balance)
    )
      return false

    return true
  }

  getTxRouting = () => {
    if (!this.tx) return null

    const {
      metadata: { source },
    } = this.tx

    if (source.dex !== 'ag') return [{ dex: source.dex, percentage: 100 }]
    else {
      const { liquidity } = source
      return Object.entries(liquidity).map(([key, value]) => ({
        dex: key,
        percentage: value,
      }))
    }
  }

  getRenderObject = () => {
    const { selectedAddress } = this.props
    const { rate, shouldUnlockFirst } = this.state

    const fromToken = this.getTokenObject(true)
    const toToken = this.getTokenObject(false)

    return {
      fromToken,
      toToken,
      disableTradeButton: !this.formValidityCheck(),
      approveToken: this.approveToken,
      sendTrade: this.sendTrade,
      rate: floorRound(rate),
      accountConnected: !!selectedAddress,
      shouldUnlockFirst,
      routing: this.getTxRouting(),
      contractAddress: this.tx && this.tx.trade.to,
    }
  }

  isValidInput = input => /^([0-9]*(?:[.][0-9]*)?)$/.test(input)

  isValidAmount = amount => {
    if (
      /^([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/.test(amount) &&
      parseFloat(amount) > 0
    )
      return true
    return false
  }

  checkTradeAmount = () => {
    const { approvedBalance, tradeAction, token2Decimals } = this.props
    const { token2Amount } = this.state

    var BN = Web3.utils.BN

    if (tradeAction !== 'Sell') {
      this.setState({ shouldUnlockFirst: false })
      return
    }

    const token2AmountWeiBN = new BN(
      (token2Amount * 10 ** parseFloat(token2Decimals)).toString(),
    )

    // console.log(approvedBalance)
    // console.log(token2Amount)
    // console.log(token2Decimals)

    if (!approvedBalance) return

    if (approvedBalance.lt(token2AmountWeiBN)) {
      this.setState({ shouldUnlockFirst: true })
    }
  }

  setToken1Amount = amount => {
    if (!this.isValidInput(amount)) return

    this.cancelPendingFetch()
    this.setState({ token1Amount: amount }, this.fillToken2Amount)
  }

  setToken1MaxAmount = () => {
    const { token1Balance } = this.props

    const denominator = new BN('10').pow(new BN('18'))
    const cmpToken1Balance = parseFloat(token1Balance) / parseFloat(denominator)

    this.cancelPendingFetch()
    this.setState({ token1Amount: cmpToken1Balance }, this.fillToken2Amount)
  }

  setToken2Amount = amount => {
    if (!this.isValidInput(amount)) return

    this.cancelPendingFetch()
    this.setState({ token2Amount: amount }, this.fillToken1Amount)
  }

  setToken2MaxAmount = () => {
    const { token2Balance, token2Decimals } = this.props

    const denominator = new BN('10').pow(new BN(token2Decimals))
    const cmpToken2Balance = parseFloat(token2Balance) / parseFloat(denominator)

    this.cancelPendingFetch()
    this.setState({ token2Amount: cmpToken2Balance }, this.fillToken1Amount)
  }

  fillToken1Amount = () => {
    const { tradeAction } = this.props
    const { token1Symbol, token2Symbol, token2Amount } = this.state

    this.setState({ token1Loading: true })

    if (!this.isValidAmount(token2Amount)) {
      this.setState({ token1Loading: false, token1Amount: '' })
    } else if (tradeAction === 'Buy') {
      this.getTradePrice(token1Symbol, token2Symbol, token2Amount, false)
        .then(price => {
          const amount = floorRound(parseFloat(token2Amount) * price)

          this.setState(
            {
              token1Amount: amount,
              token1Loading: false,
              rate: 1 / price,
            },
            this.checkTradeAmount,
          )
        })
        .catch(err => {})
    } else {
      this.getTradePrice(token2Symbol, token1Symbol, token2Amount, true)
        .then(price => {
          const amount = floorRound(parseFloat(token2Amount) * price)

          this.setState(
            {
              token1Amount: amount,
              token1Loading: false,
              rate: 1 / price,
            },
            this.checkTradeAmount,
          )
        })
        .catch(err => {})
    }
  }

  fillToken2Amount = () => {
    const { tradeAction } = this.props
    const { token1Symbol, token2Symbol, token1Amount } = this.state

    this.setState({ token2Loading: true })

    if (!this.isValidAmount(token1Amount)) {
      this.setState({ token2Loading: false, token2Amount: '' })
    } else if (tradeAction === 'Buy') {
      this.getTradePrice(token1Symbol, token2Symbol, token1Amount, true)
        .then(price => {
          const amount = floorRound(parseFloat(token1Amount) * price)

          this.setState(
            {
              token2Amount: amount,
              token2Loading: false,
              rate: price,
            },
            this.checkTradeAmount,
          )
        })
        .catch(err => {})
    } else {
      this.getTradePrice(token2Symbol, token1Symbol, token1Amount, false)
        .then(price => {
          const amount = floorRound(parseFloat(token1Amount) * price)

          this.setState(
            {
              token2Amount: amount,
              token2Loading: false,
              rate: price,
            },
            this.checkTradeAmount,
          )
        })
        .catch(err => {})
    }
  }

  render() {
    return this.props.children(this.getRenderObject())
  }
}

export default TradeTokenLogicProvider
