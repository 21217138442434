import { toChecksumAddress } from 'web3-utils'
import { MESSAGES } from '../shared/messages'
import BalancerIcon from '../images/platforms/balancer.png';
import BancorIcon from '../images/platforms/bancor.png';
import CurveIcon from '../images/platforms/curve.jpg';
import MooniswapIcon from '../images/platforms/mooniswap.jpg';
import SushiswapIcon from '../images/platforms/sushiswap.jpg';
import UniswapIcon from '../images/platforms/uniswap.png';
import UniswapV2Icon from '../images/platforms/uniswap-v2.jpg';

/**
 * Formatting for numbers
 * @param {number} number
 * @param {number} digits
 */
export function format(number, digits) {
  if (number === null) return null
  if (number === undefined) return null

  if (digits === undefined) {
    if (number === 0) {
      digits = 0
    } else if (Math.abs(number) >= 100) {
      digits = 0
    } else if (Math.abs(number) >= 10) {
      digits = 1
    } else if (Math.abs(number) >= 1) {
      digits = 2
    } else if (Math.abs(number) >= 0.1) {
      digits = 3
    } else if (Math.abs(number) >= 0.01) {
      digits = 4
    } else {
      digits = 5
    }
  }

  return number.toLocaleString([], {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })
}

/**
 * Formatting for ROI figures
 * @param {number} roi
 */
export function roiFormat(roi) {
  if (roi === null) return null
  if (roi === undefined) return null

  if (roi > 1) return `+${format(100 * (roi - 1), 2)}%`

  return `${format(100 * (roi - 1), 2)}%`
}

/**
 * Formatting for dollar figures
 * @dev User locale is overridden and number displayed in en-US
 * @dev This is mainly to avoid the Asset Card UI breaking for lengthy formatting
 * @param {number} number
 * @param {number} digits
 */
export function usdFormat(number, digits) {
  if (number === null) return null
  if (number === undefined) return null

  /* Round amounts over $100 to whole dollars */
  if (digits === undefined) {
    if (number === 0) {
      digits = 0
    } else if (Math.abs(number) >= 100) {
      digits = 0
    } else {
      digits = 2
    }
  }

  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })
}

export function addDefaultSrc(ev) {
  ev.target.remove()
}

export function colorForAddress(address) {
  return '#' + String(address).slice(3, 9)
}

/**
 * Shortens an Ethereum address
 * @param {string} address
 */
export function shortLabelForAddress(address) {
  return String(address).slice(0, 3)
}

/**
 * Shortens an Ethereum address
 * @param {string} address
 */
export function slicedAddress(address) {
  return String(address).slice(0, 6) + '...' + String(address).slice(-4)
}

export function mapAccountActions(action, t) {
  let value = ''

  if (action === 'PURCHASE') {
    value = t('helpers.map_account_actions.PURCHASE')
  } else if (action === 'REDEEM') {
    value = t('helpers.map_account_actions.REDEEM')
  } else if (action === 'TRANSFER') {
    value = t('helpers.map_account_actions.TRANSFER')
  }

  return value
}

export function mapPlatformSrc(platform) {
  if (platform === null) return null
  else if (platform === undefined) return null
  else platform = platform.toLowerCase()
  // Based on Trust Wallet assets
  let value = null

  if (platform === 'uniswap') value = 'uniswap.exchange'
  else if (platform === 'uniswap-v2') value = 'uniswap.exchange'
  else if (platform === 'bancor') value = 'bancor.network'
  else if (platform === 'kyber') value = 'kyberswap.com'
  else if (platform === 'pooltogether') value = 'pooltogether.us'
  else if (platform === 'curve') value = 'curve.fi'
  else if (platform === 'balancer') value = 'balancer.exchange'
  else if (platform === 'mooniswap') value = '1inch.exchange'

  return value
}

/**
 * Returns image src for a given token
 * @param {string} token
 */
export const srcForToken = token => {
  if (!token) return null

  if (token === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
    return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png`
  } else
    return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${toChecksumAddress(
      token,
    )}/logo.png`
}

/**
 * Returns image src for a given platform
 * @param {string} platform
 */
export const srcForPlatform = platform => {
  if (platform === 'balancer') return BalancerIcon
  if (platform === 'bancor') return BancorIcon
  if (platform === 'curve') return CurveIcon
  if (platform === 'mooniswap') return MooniswapIcon
  if (platform === 'sushiswap') return SushiswapIcon
  if (platform === 'uniswap-v2') return UniswapV2Icon
  if (platform === 'uniswap') return UniswapIcon

  return null
}

/**
 * Returns Etherscan url for a given tx hash
 * @param {string} tx
 */
export function etherscanTxUrl(tx) {
  return `https://etherscan.io/tx/${tx}`
}

export function capitalize(word) {
  if (!word) return null
  return word.slice(0, 1).toUpperCase() + word.slice(1)
}

export function titleForPool(data) {
  if (!data) return null
  const { poolName } = data
  return poolName
}

/**
 * Chooses the pool avatar image given an array of asset objects.
 * @param {string[]} assets Array of asset objects
 */
export function chooseAssetFor(assets) {
  if (!assets || !assets[0]) return null
  // Skip ETH
  if (assets[0]['address'] === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
    return assets && assets[1]
  // Skip WETH
  else if (
    assets[0]['address'] === '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
  )
    return assets && assets[1]
  // Skip BNT
  else if (
    assets[0]['address'] === '0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c'
  )
    return assets && assets[1]
  // Skip USDB
  else if (
    assets[0]['address'] === '0x309627af60f0926daa6041b8279484312f2bf060'
  )
    return assets && assets[1]
  return assets && assets[0]
}

export function chooseAnAddressFor(assets) {
  const asset = assets && chooseAssetFor(assets)
  return asset && asset['address']
}

export function estRedemptionValues(exchangeData, baseAmount) {
  // Redemption value for DeFi Zap in Base/Token
  // Given a deposit amount in Base
  if (!exchangeData || !baseAmount) return null

  baseAmount = parseFloat(baseAmount)
  const basePrice = exchangeData.basePrice // Tokens per Base
  const estOwnershipRatio =
    baseAmount / (2 * exchangeData.baseLiquidity + baseAmount)
  const estBaseRedemptionValue = format(
    estOwnershipRatio * (exchangeData.baseLiquidity + baseAmount),
  )
  const estTokenRedemptionValue = format(
    estOwnershipRatio * (exchangeData.tokenLiquidity + baseAmount * basePrice),
  )
  return {
    estBaseRedemptionValue,
    estTokenRedemptionValue,
  }
}

export function estUniswapRedemptionValues(exchangeData, uniAmount, uniSupply) {
  // Redemption value for Uniswap in Base/Token
  // Given a withdrawal amount in UNI and the UNI total supply
  if (!exchangeData || !uniAmount || !uniSupply) return null

  const uniAmountBN = uniAmount * 10 ** 18
  const estOwnershipRatio = parseFloat(uniAmountBN) / parseFloat(uniSupply)

  const MAX_SLIPPAGE_BIPS = 30
  const MIN_RETURN_RATIO = (10000 - MAX_SLIPPAGE_BIPS) / 10000

  const estBaseRedemptionValue =
    estOwnershipRatio * exchangeData.baseLiquidity * MIN_RETURN_RATIO
  const estTokenRedemptionValue =
    estOwnershipRatio * exchangeData.tokenLiquidity * MIN_RETURN_RATIO
  return {
    estBaseRedemptionValue,
    estTokenRedemptionValue,
  }
}

export function tagsFor(exchange) {
  let tags = exchange.tags || []
  const localMessage = MESSAGES[exchange.exchange]
  const localTags = localMessage ? localMessage.tags : []

  return tags.concat(localTags)
}

export function exchangeHasNote(exchange) {
  const localMessage = MESSAGES[exchange.exchange]
  const localTags = localMessage ? localMessage.tags : []

  return localTags.find(tag => tag === 'note')
}

/**
 * Determins if an address looks like a valid Ethereum address
 * @param {string} address
 */
export const isValidAddress = address => /^0x[A-Fa-f0-9]{40}$/.test(address)

export const getExchangeTradeData = (exchange, assetFilter) => {
  const id = exchange.id
  const date = new Date(exchange.timestamp)
  const action = exchange.fromToken === assetFilter ? 'SELL' : 'BUY'
  const fromSymbol = exchange.fromSymbol
  const fromAmount = parseFloat(exchange.fromAmount)
  const toSymbol = exchange.toSymbol
  const toAmount = parseFloat(exchange.toAmount)
  const price =
    exchange.fromToken === assetFilter
      ? fromAmount / toAmount
      : toAmount / fromAmount
  const volume = exchange.fromToken === assetFilter ? fromAmount : toAmount

  return {
    id,
    date,
    action,
    fromSymbol,
    fromAmount,
    toSymbol,
    toAmount,
    price,
    volume,
    trader: exchange.trader,
  }
}

export const formatPercentage = weight => `${Math.round(weight * 1000) / 10}%`
