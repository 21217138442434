// React
import React from 'react'
import { withRouter } from 'react-router-dom'

// Context
import { ExchangePriceConsumer } from '../../../contexts/ExchangePriceContext'

// Components
import BlockyLogo from './components/BlockyLogo'
import LoginButton from '../LoginButton'
import SearchBar from './components/SearchBar'

// Material UI
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  appBar: {
    margin: '0 auto',
    top: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    height: 60 + theme.spacing(2) * 2,
    [theme.breakpoints.down('sm')]: {
      minHeight: 92 + 60,
      position: 'absolute',
      boxShadow: 'none',
    },
    zIndex: 98,
  },
  toolbar: {
    // height: '100%',
    minHeight: 92,
    [theme.breakpoints.down('sm')]: {
      minHeight: 64 * 2,
    },
  },
  absoluteContent: {
    position: 'absolute',
    top: 0,
    height: 92,
  },
  logoDrawer: {
    position: 'absolute',
    left: theme.spacing(1),
    zIndex: 1,
  },
  accountWidget: {
    position: 'absolute',
    right: theme.spacing(1),
    zIndex: 1,
  },
  searchBar: {
    height: 60,
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      width: 400,
      left: 'calc(50% - 200px)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      left: 0,
    },
  },
}))

const ResponsiveDrawer = ({
  exchangeData,
  exchangeDataIsLoading,
  location,
  children,
}) => {
  const classes = useStyles()

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar} disableGutters>
        <Grid container direction="column">
          <Grid
            className={classes.absoluteContent}
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            style={{ position: 'relative' }}
          >
            <BlockyLogo className={classes.logoDrawer} />

            <Hidden smDown>
              <SearchBar
                className={classes.searchBar}
                exchangeData={exchangeData}
                exchangeDataIsLoading={exchangeDataIsLoading}
                location={location}
              />
            </Hidden>

            {/* <LoginButton className={classes.accountWidget} /> */}
          </Grid>

          <Hidden mdUp>
            <Grid item style={{ height: 60 }}>
              <SearchBar
                className={classes.searchBar}
                exchangeData={exchangeData}
                exchangeDataIsLoading={exchangeDataIsLoading}
                location={location}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

const DrawerContextWrapper = props => (
  <ExchangePriceConsumer>
    {({ exchangeData, exchangeDataLoading }) => (
      <ResponsiveDrawer
        {...props}
        exchangeData={exchangeData}
        exchangeDataIsLoading={exchangeDataLoading}
      />
    )}
  </ExchangePriceConsumer>
)

export default withRouter(DrawerContextWrapper)
