import React, { createContext, useState, useEffect } from 'react'
import { initOnboard } from '../services/onboard.js'
import { logAnalyticsEvent } from '../services/analytics'
import ERC20Artifact from '../abi/erc20.json'
import Web3 from 'web3'

const OnboardContext = createContext()

export const OnboardProvider = ({ children }) => {
  const [onboard, setOnboard] = useState(null)

  const [address, setAddress] = useState(null)
  const [network, setNetwork] = useState(null)
  const [balance, setBalance] = useState(null)

  const [provider, setProvider] = useState(null)
  const [web3, setWeb3] = useState(null)

  var BN = Web3.utils.BN

  useEffect(() => {
    setOnboard(
      initOnboard({
        address: address => {
          setAddress(address)
        },
        network: network => {
          setNetwork(network)
        },
        balance: balance => {
          setBalance(balance)
        },
        wallet: wallet => {
          if (wallet.provider) {
            setProvider(wallet.provider)
            setWeb3(new Web3(wallet.provider))
          }
        },
      }),
    )
  }, [])

  useEffect(() => {
    const previouslySelectedWallet = window.localStorage.getItem(
      'selectedWallet',
    )

    if (previouslySelectedWallet && onboard) {
      onboard.walletSelect(previouslySelectedWallet)

      logAnalyticsEvent(
        'connect_wallet',
        'local_storage_success',
        previouslySelectedWallet.name,
      )
    }
  }, [onboard])

  async function readyToTransact() {
    if (!provider) {
      const walletSelected = await onboard.walletSelect()
      if (!walletSelected) return false
    }

    const readyToTransact = await onboard.walletCheck()
    if (!readyToTransact) return false

    return true
  }

  async function tokenTotalSupplyOf(contractAddress) {
    if (!provider) {
      console.log('Missing provider')
      return
    }

    if (!contractAddress) {
      console.log('Missing contractAddress')
      return
    }

    let tokenContract = new web3.eth.Contract(ERC20Artifact, contractAddress)
    let resultForTotalSupply = await tokenContract.methods.totalSupply().call()

    return new BN(resultForTotalSupply)
  }

  async function tokenBalanceOf(contractAddress) {
    if (!provider) {
      console.log('Missing provider')
      return
    }

    if (!contractAddress) {
      console.log('Missing contractAddress')
      return
    }

    if (!address) {
      console.log('Missing address')
      return
    }

    let tokenContract = new web3.eth.Contract(ERC20Artifact, contractAddress)
    let resultForBalanceOf = await tokenContract.methods
      .balanceOf(address)
      .call()

    console.log('resultForBalanceOf', resultForBalanceOf)

    return new BN(resultForBalanceOf)
  }

  async function tokenDecimalsOf(contractAddress) {
    if (!provider) {
      console.log('Missing provider')
      return
    }

    if (!contractAddress) {
      console.log('Missing contractAddress')
      return
    }

    let tokenContract = new web3.eth.Contract(ERC20Artifact, contractAddress)
    let resultForDecimals = await tokenContract.methods.decimals().call()

    return new BN(resultForDecimals)
  }

  async function tokenApprovalOf(contractAddress, spender) {
    if (!provider) {
      console.log('Missing provider')
      return
    }

    if (!contractAddress) {
      console.log('Missing contractAddress')
      return
    }

    if (!address) {
      console.log('Missing address')
      return
    }

    let tokenContract = new web3.eth.Contract(ERC20Artifact, contractAddress)
    let resultForApproval = await tokenContract.methods
      .allowance(address, spender)
      .call()

    return new BN(resultForApproval)
  }

  return (
    <OnboardContext.Provider
      value={{
        onboard,
        address,
        network,
        balance,
        provider,
        web3,
        readyToTransact,
        tokenApprovalOf,
        tokenBalanceOf,
        tokenDecimalsOf,
        tokenTotalSupplyOf,
      }}
    >
      {children}
    </OnboardContext.Provider>
  )
}

export const OnboardConsumer = OnboardContext.Consumer
